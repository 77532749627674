import * as React from "react";
import SimpleDataTable from "components/SimpleDataTable";
import { ProjectUsage, ReleaseUsageEntry } from "client/resources/libraryVariableSetUsageResource";
import InternalLink from "components/Navigation/InternalLink";
import routeLinks from "routeLinks";
import Chip from "components/Chips/Chip";

export const VariableSetReleaseUsages: React.FC<{usage: ProjectUsage[]}> = ({usage}) => {
    return <SimpleDataTable<ProjectUsage>
        data={usage}
        headerColumns={["Project Name", "Release Version"]}
        onRow={usageEntry => [
            usageEntry.ProjectName,
            usageEntry.Releases.map((release: ReleaseUsageEntry) =>
                <InternalLink to={routeLinks.release(release.ReleaseId)} key={`${release.ReleaseId}-releaseSnapshotLink`}>
                    <Chip>{release.ReleaseVersion}</Chip>
                </InternalLink>)
        ]}/>;
};
