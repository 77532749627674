exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_box__WuJnm {\n  padding: 0.625rem 1rem;\n  align-self: stretch;\n  font-size: 0.875rem;\n  color: #333333;\n}\n.style_information__hNEcB {\n  background: rgba(8, 120, 209, 0.1);\n  padding: 0.625rem 1rem;\n  align-self: stretch;\n  font-size: 0.875rem;\n  color: #333333;\n}\n.style_information__hNEcB .style_title__3E4i5 {\n  color: #0878D1;\n}\n.style_success__2MJei {\n  background: rgba(72, 179, 80, 0.1);\n  padding: 0.625rem 1rem;\n  align-self: stretch;\n  font-size: 0.875rem;\n  color: #333333;\n}\n.style_success__2MJei .style_title__3E4i5 {\n  color: #48B350;\n}\n.style_warning__mS5OQ {\n  background: rgba(251, 140, 0, 0.1);\n  padding: 0.625rem 1rem;\n  align-self: stretch;\n  font-size: 0.875rem;\n  color: #333333;\n}\n.style_warning__mS5OQ .style_title__3E4i5 {\n  color: #fb8c00;\n}\n.style_danger__3pDp5 {\n  background: rgba(219, 68, 55, 0.1);\n  padding: 0.625rem 1rem;\n  align-self: stretch;\n  font-size: 0.875rem;\n  color: #333333;\n}\n.style_danger__3pDp5 .style_title__3E4i5 {\n  color: #db4437;\n}\n.style_none__3NoL2 {\n  padding: 0.625rem 1rem;\n  align-self: stretch;\n  font-size: 0.875rem;\n  color: #333333;\n}\n.style_title__3E4i5 + div {\n  margin-top: 0.25rem;\n}\n.style_title__3E4i5 > .style_note__1z2dl {\n  margin-bottom: 0;\n}\n", ""]);

// exports
exports.locals = {
	"box": "style_box__WuJnm",
	"information": "style_information__hNEcB",
	"title": "style_title__3E4i5",
	"success": "style_success__2MJei",
	"warning": "style_warning__mS5OQ",
	"danger": "style_danger__3pDp5",
	"none": "style_none__3NoL2",
	"note": "style_note__1z2dl"
};