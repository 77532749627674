exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .style_hide-sm__1Lr7W {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .style_visible-sm__1TUkE {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .style_hide-md__uOl_u {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .style_visible-md__1tM22 {\n    display: none !important;\n  }\n}\n.style_formExpander__1tFRA,\n.style_formNonExpander__2ih9i {\n  background: rgba(153, 153, 153, 0.08) !important;\n  width: 100%;\n  box-shadow: none!important;\n  margin: 0 0 -0.0625rem 0;\n  border-radius: 0!important;\n  border-style: solid;\n  border-width: 0.0625rem 0 0.0625rem 0;\n  border-color: #cccccc;\n}\n.style_formExpander__1tFRA > div:first-child,\n.style_formNonExpander__2ih9i > div:first-child {\n  padding: 0!important;\n}\n.style_formExpander__1tFRA > div:first-child > div:first-child,\n.style_formNonExpander__2ih9i > div:first-child > div:first-child {\n  padding: 0!important;\n}\n.style_formExpander__1tFRA > div:first-child > div:first-child {\n  cursor: pointer;\n}\n.style_cardMedia__3ed4z {\n  margin: 0;\n  padding: 0;\n}\n.style_cardTitleContainer__2aOB8 {\n  display: flex;\n  align-items: center;\n  word-wrap: break-word;\n  font-size: 1rem;\n  font-weight: bold;\n  background-color: rgba(153, 153, 153, 0.08) !important;\n  padding: 0.25rem 1rem !important;\n}\n@media (max-width: 37.4rem) {\n  .style_cardTitleContainer__2aOB8 {\n    display: block;\n  }\n}\n.style_overflowMenuActions__1O03T {\n  margin-left: auto;\n  align-items: center;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "style_hide-sm__1Lr7W",
	"hideSm": "style_hide-sm__1Lr7W",
	"visible-sm": "style_visible-sm__1TUkE",
	"visibleSm": "style_visible-sm__1TUkE",
	"hide-md": "style_hide-md__uOl_u",
	"hideMd": "style_hide-md__uOl_u",
	"visible-md": "style_visible-md__1tM22",
	"visibleMd": "style_visible-md__1tM22",
	"formExpander": "style_formExpander__1tFRA",
	"formNonExpander": "style_formNonExpander__2ih9i",
	"cardMedia": "style_cardMedia__3ed4z",
	"cardTitleContainer": "style_cardTitleContainer__2aOB8",
	"overflowMenuActions": "style_overflowMenuActions__1O03T"
};