exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles_metadataPackageTitle__2zpru {\n  font-size: 0.875rem !important;\n  font-weight: 500;\n}\n", ""]);

// exports
exports.locals = {
	"metadataPackageTitle": "styles_metadataPackageTitle__2zpru"
};