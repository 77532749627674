import * as React from "react";
import {ActionEditProps} from "components/Actions/pluginRegistry";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import {default as FormSectionHeading} from "components/form/Sections/FormSectionHeading";
import {ExpandableFormSection, Summary} from "components/form";
import {
    GetPrimaryPackageReference, InitialisePrimaryPackageReference, RemovePrimaryPackageReference,
    SetPrimaryPackageReference} from "client/resources";
import FeedResource from "client/resources/feedResource";
import {default as CodeEditor, TextFormat} from "components/CodeEditor/CodeEditor";
import CommonSummaryHelper from "utils/CommonSummaryHelper/CommonSummaryHelper";
import {CardFill} from "components/form/Sections/ExpandableFormSection";
import Note from "components/form/Note/Note";
import RadioButton from "components/form/RadioButton/RadioButton";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import PackageSelector from "components/PackageSelector/PackageSelector";
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import {repository} from "clientInstance";
import {DataContext, MetadataTypeCollection, TypeMetadata} from "client/resources/dynamicFormResources";
import DynamicForm from "components/DynamicForm/DynamicForm";
import {VariableLookupText} from "components/form/VariableLookupText";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import {BoundStringCheckbox} from "components/form/Checkbox/StringCheckbox";
import UnstructuredFormSection from "components/form/Sections/UnstructuredFormSection";
import SourceCodeDialog from "components/SourceCodeDialog/sourceCodeDialog";
import ActionProperties from "../../../client/resources/actionProperties";
import { Fragment } from "react";
import { AwsBoundAccountVariableSelect, AzureBoundAccountVariableSelect } from "components/form/AccountSelect/AccountVariableSelect";
import AwsLoginComponent, { AWSScriptProperties } from "components/Actions/aws/awsLoginComponent";

export interface TerraformProperties extends AWSScriptProperties {
    "Octopus.Action.Script.ScriptSource": string;
    "Octopus.Action.Terraform.Template": string;
    /*
     TemplateParameters is either the path to the template file, or a JSON structure holding
     the various strings entered into the dynamic form. On the server side in TerraformActionHandler
     some of these strings will be parsed as lists or maps. But the UI is ignorant of any particular
     formatting for these data structures.
      */
    "Octopus.Action.Terraform.TemplateParameters": string;
    "Octopus.Action.Terraform.ManagedAccount": string;
    "Octopus.Action.Terraform.AllowPluginDownloads": string;
    "Octopus.Action.Terraform.PluginsDirectory": string;
    "Octopus.Action.Terraform.TemplateDirectory": string;
    "Octopus.Action.Terraform.FileSubstitution": string;
    "Octopus.Action.Terraform.Workspace": string;
    "Octopus.Action.Terraform.VarFiles": string;
    "Octopus.Action.Terraform.AdditionalInitParams": string;
    "Octopus.Action.Terraform.AdditionalActionParams": string;
    "Octopus.Action.Terraform.AzureAccount": string;
    "Octopus.Action.AzureAccount.Variable": string;
}

export interface TerraformActionEditProps<T = ActionProperties> extends ActionEditProps<T> {
    actionName: string;
}

export interface TerraformActionEditState {
    feeds: FeedResource[];
    parameterTypes?: TypeMetadata[];
    parameterValues?: any;
}

export default class TerraformActionEdit extends BaseComponent<TerraformActionEditProps<TerraformProperties>, TerraformActionEditState> {
    parameterValues: {};
    parameters: {};
    source: any;

    constructor(props: TerraformActionEditProps<TerraformProperties>) {
        super(props);
        this.state = {
            feeds: [],
        };
        this.parameterValues = {};
        this.parameters = {};
        this.source = {octopus: "octopus"};
    }

    async componentDidMount() {
        await this.loadFeeds();

        if (!this.props.properties["Octopus.Action.Terraform.AzureAccount"]) {
            this.props.setProperties({["Octopus.Action.Terraform.AzureAccount"]: "False"}, true);
        }

        if (!this.props.properties["Octopus.Action.Terraform.ManagedAccount"]) {
            this.props.setProperties({["Octopus.Action.Terraform.ManagedAccount"]: "None"}, true);
        }

        if (!this.props.properties["Octopus.Action.Terraform.AllowPluginDownloads"]) {
            this.props.setProperties({["Octopus.Action.Terraform.AllowPluginDownloads"]: "True"}, true);
        }

        if (!this.props.properties["Octopus.Action.Script.ScriptSource"]) {
            this.props.setProperties({["Octopus.Action.Script.ScriptSource"]: "Inline"}, true);
        }
    }

    componentWillMount() {
        if (this.props.properties["Octopus.Action.Script.ScriptSource"] &&
            this.props.properties["Octopus.Action.Script.ScriptSource"] === "Inline") {

            this.refreshParametersFromTemplate(this.props.properties["Octopus.Action.Terraform.Template"]);
        }
    }

    templateSourceSummary() {
        const source = this.props.properties["Octopus.Action.Script.ScriptSource"];
        if (source === "Inline") {
            return Summary.summary("Source code");
        }
        if (source === "Package") {
            return Summary.summary("File inside a package");
        }
        return Summary.placeholder("Template source not specified");
    }

    onChangeTemplateSource(value: any) {
        this.props.setProperties({
            ["Octopus.Action.Script.ScriptSource"]: value,
            ["Octopus.Action.Terraform.Template"]: "",
            ["Octopus.Action.Terraform.TemplateParameters"]: "",
        });

        // If the inline option is selected, we clear out the package selection
        if (value === "Inline") {
            this.props.setPackages(RemovePrimaryPackageReference(this.props.packages));
        } else {
            this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.state.feeds));
        }
    }

    optionsSummary() {
        return Summary.summary(<span>
            {this.props.properties["Octopus.Action.Terraform.AllowPluginDownloads"] === "True" &&
            <span>Allowing additional plugins downloads</span>}
            {this.props.properties["Octopus.Action.Terraform.AllowPluginDownloads"] !== "True" &&
            <span>Preventing additional plugins downloads</span>}
            {this.props.properties["Octopus.Action.Terraform.PluginsDirectory"] &&
            this.props.properties["Octopus.Action.Terraform.PluginsDirectory"].trim() &&
            <span> to the custom plugin cache directory of <strong>{this.props.properties["Octopus.Action.Terraform.PluginsDirectory"].trim()}</strong></span>}
            {this.props.properties["Octopus.Action.Terraform.Workspace"] &&
            <span>, and using the <strong>{this.props.properties["Octopus.Action.Terraform.Workspace"]}</strong> workspace</span>}
        </span>);
    }

    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);

        return <div>
            <FormSectionHeading title="Managed Accounts"/>
            <UnstructuredFormSection>
                <p>
                    You can optionally prepare the environment that Terraform runs in using the details defined in accounts
                    managed by Octopus. If an account is selected then those credentials do not need to be included
                    in the Terraform template.
                </p>
            </UnstructuredFormSection>
            <ExpandableFormSection
                errorKey="Octopus.Action.Terraform.ManagedAccount"
                isExpandedByDefault={this.props.expandedByDefault}
                title="AWS Account"
                fillCardWidth={CardFill.FillRight}
                summary={AwsLoginComponent.summary(this.props.properties)}
                help={"Enable AWS account integration"}>
                <RadioButtonGroup
                    value={this.props.properties["Octopus.Action.Terraform.ManagedAccount"]}
                    onChange={(val: string) => this.props.setProperties({["Octopus.Action.Terraform.ManagedAccount"]: val})}
                    error={this.props.getFieldError("Octopus.Action.Terraform.ManagedAccount")}>
                    <RadioButton value={"None"}
                                 label="No"/>
                    <RadioButton value={"AWS"}
                                 label="Yes"/>
                </RadioButtonGroup>
                {this.props.properties["Octopus.Action.Terraform.ManagedAccount"] === "AWS" &&
                <Fragment>
                    <AwsLoginComponent.Fields
                        projectId={this.props.projectId}
                        properties={this.props.properties}
                        packages={this.props.packages}
                        plugin={this.props.plugin}
                        setProperties={this.props.setProperties}
                        setPackages={this.props.setPackages}
                        doBusyTask={this.props.doBusyTask}
                        busy={this.props.busy}
                        getFieldError={this.props.getFieldError}
                        errors={this.props.errors}
                        expandedByDefault={this.props.expandedByDefault}
                    />
                    <VariableLookupText localNames={this.props.localNames}
                                        projectId={this.props.projectId}
                                        value={this.props.properties["Octopus.Action.Aws.Region"]}
                                        label="Region"
                                        onChange={(val) => this.props.setProperties({["Octopus.Action.Aws.Region"]: val})}
                                        error={this.props.getFieldError("Octopus.Action.Aws.Region")}
                    />
                    <Note>
                        View the <ExternalLink href="AWSRegions">AWS Regions and Endpoints</ExternalLink> documentation
                        for a current list of the available region codes.
                    </Note>
                </Fragment>}
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="Octopus.Action.Terraform.AzureAccount"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Azure Account"
                fillCardWidth={CardFill.FillRight}
                summary={this.azureManagedAccountSummary()}
                help={"Enable Azure account integration"}>
                <RadioButtonGroup
                    value={this.props.properties["Octopus.Action.Terraform.AzureAccount"]}
                    onChange={(val: string) => this.props.setProperties({["Octopus.Action.Terraform.AzureAccount"]: val})}
                    error={this.props.getFieldError("Octopus.Action.Terraform.AzureAccount")}>
                    <RadioButton value="False"
                                 label="No"/>
                    <RadioButton value="True"
                                 label="Yes"/>
                </RadioButtonGroup>
                {this.props.properties["Octopus.Action.Terraform.AzureAccount"] === "True" &&
                <Fragment>
                    <AzureBoundAccountVariableSelect
                            projectId={this.props.projectId}
                            resetValue={""}
                            allowClear={true}
                            doBusyTask={this.props.doBusyTask}
                            value={this.props.properties["Octopus.Action.AzureAccount.Variable"] as string}
                            onChange={(val) => this.props.setProperties({["Octopus.Action.AzureAccount.Variable"]: val})}
                        />
                </Fragment>}
            </ExpandableFormSection>
            <FormSectionHeading title="Template"/>
            <ExpandableFormSection
                errorKey="Octopus.Action.Script.ScriptSource|Octopus.Action.Terraform.Template"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Template Source"
                fillCardWidth={CardFill.FillRight}
                summary={this.templateSourceSummary()}
                help={"Select the source of the template."}>
                <Note>Templates can be entered as source-code, or contained in a package.</Note>
                <RadioButtonGroup
                    value={this.props.properties["Octopus.Action.Script.ScriptSource"]}
                    onChange={(val: string) => this.onChangeTemplateSource(val)}
                    error={this.props.getFieldError("Octopus.Action.Script.ScriptSource")}>
                    <RadioButton value={"Inline"}
                                 label="Source code"/>
                    <RadioButton value={"Package"}
                                 label="File inside a package"/>
                </RadioButtonGroup>
                {this.props.properties["Octopus.Action.Script.ScriptSource"] === "Inline" && <div>
                    <br/>
                    {this.props.properties["Octopus.Action.Terraform.Template"] &&
                    <CodeEditor value={this.props.properties["Octopus.Action.Terraform.Template"]}
                                language={TextFormat.JSON}
                                allowFullScreen={false}
                                readOnly={true}
                                onChange={null}
                    />}
                    <div>
                        <OpenDialogButton
                            label={this.props.properties["Octopus.Action.Terraform.Template"] ? "Edit Source Code" : "Add Source Code"}
                            wideDialog={true}
                            renderDialog={(openProps) =>
                                <SourceCodeDialog
                                    open={openProps.open}
                                    close={openProps.closeDialog}
                                    template={this.props.properties["Octopus.Action.Terraform.Template"]}
                                    doBusyTask={this.props.doBusyTask}
                                    saveDone={(template, metadata) => {
                                        this.props.setProperties({["Octopus.Action.Terraform.Template"]: template});
                                        this.refreshParametersFromMetadata(metadata);
                                    }}
                                    textFormat={TextFormat.JSON}
                                    metadata="Terraform"/>}
                        />
                    </div>
                </div>}
            </ExpandableFormSection>

            {this.props.properties["Octopus.Action.Script.ScriptSource"] === "Package" && <div>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Package.FeedId|Octopus.Action.Package.PackageId"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Package"
                    summary={CommonSummaryHelper.packageSummary(pkg, this.state.feeds)}
                    help={"Choose the package that contains the template source."}>
                    <PackageSelector
                        packageId={pkg.PackageId}
                        feedId={pkg.FeedId}
                        onPackageIdChange={packageId => this.props.setPackages(SetPrimaryPackageReference({PackageId: packageId}, this.props.packages))}
                        onFeedIdChange={feedId => this.props.setPackages(SetPrimaryPackageReference({FeedId: feedId}, this.props.packages))}
                        packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                        feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                        projectId={this.props.projectId}
                        feeds={this.state.feeds}
                        localNames={this.props.localNames}
                        refreshFeeds={this.loadFeeds} />
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={this.props.properties["Octopus.Action.Terraform.TemplateDirectory"]}
                        onChange={(x) => this.props.setProperties({["Octopus.Action.Terraform.TemplateDirectory"]: x})}
                        label="Terraform template directory"
                        error={this.props.getFieldError("Octopus.Action.Terraform.TemplateDirectory")}/>
                    <Note>
                        Specify the optional directory in the package from which Terraform will be run.
                    </Note>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={this.props.properties["Octopus.Action.Terraform.FileSubstitution"]}
                        onChange={(x) => this.props.setProperties({["Octopus.Action.Terraform.FileSubstitution"]: x})}
                        label="Target files"
                        error={this.props.getFieldError("Octopus.Action.Terraform.FileSubstitution")}
                        multiLine={true}/>
                    <Note>
                        A newline-separated list of file names to transform (in addition to
                        all <em>*.tf</em>, <em>*.tfvars</em>, <em>*.tf.json</em> and <em>*.tfvars.json</em> files),
                        relative to the package contents. Extended wildcard syntax is supported.
                        E.g., <em>Notes.txt</em>, <em>Config\*.json</em>, <em>**\specific-folder\*.config.</em><br/>
                        This field supports extended template syntax. Conditional <code>if</code> and <code>unless</code>:
                        <pre>{`#{if MyVar}...#{/if}`}</pre>
                        Iteration over variable sets or comma-separated values with <code>each</code>:
                        <pre>{`#{each mv in MyVar}...#{mv}...#{/each}`}</pre>
                    </Note>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={this.props.properties["Octopus.Action.Terraform.VarFiles"]}
                        onChange={(x) => this.props.setProperties({["Octopus.Action.Terraform.VarFiles"]: x})}
                        label="Additional variable files"
                        error={this.props.getFieldError("Octopus.Action.Terraform.VarFiles")}
                        multiLine={true}/>
                    <Note>
                        An optional newline-separated list of files that are passed as <strong>-var-file</strong> parameters.
                        Note that files called <strong>terraform.tfvars</strong>, <strong>terraform.tfvars.json</strong>,
                        <strong>*.auto.tfvars</strong> and <strong>*.auto.tfvars.json</strong> are
                        automatically loaded by Terraform, and do not need to be listed here.
                        Each line in this text area is passed as an individual <strong>-var-file</strong> parameter.
                    </Note>
                </ExpandableFormSection>
            </div>}

            {this.props.properties["Octopus.Action.Script.ScriptSource"] === "Inline" && this.state.parameterTypes && <div>
                <FormSectionHeading title="Variables"/>
                <UnstructuredFormSection>
                    <Note>
                        Terraform variables are either strings, lists or maps.
                    </Note>
                    <Note>
                        Strings (including numbers and true/false) are supplied without quotes
                        e.g. <strong>my string</strong>, <strong>true</strong> or <strong>3.1415</strong>.
                        Lists and maps are supplied as raw HCL or JSON structures, depending on the format of the
                        template. For example, if the template is written in HCL, a list
                        variable would be provided as <strong>["item1", {"{"}item2="embedded map"{"}"}]</strong> and a map
                        variable would be provided as <strong>{"{"}item1="hi", item2="there"{"}"}</strong>. If the
                        template is written is JSON, a list variable
                        would be provided as <strong>["item1", {"{"}"item2": "embedded map" {"}"}]</strong> and a map
                        variable would be provided as <strong>{"{"}"item1": "hi", "item2": "there"{"}"}</strong>.
                    </Note>
                </UnstructuredFormSection>
                <DynamicForm
                    types={this.state.parameterTypes}
                    values={this.state.parameterValues}
                    isBindable={true}
                    onChange={(data) => this.updateParameters(data)}
                    getBoundFieldProps={() => ({projectId: this.props.projectId, localNames: this.props.localNames})}
                />
            </div>}
            <FormSectionHeading title="Advanced Options"/>
            <ExpandableFormSection
                errorKey="Octopus.Action.Terraform.PreventDownload|Octopus.Action.Terraform.PluginsDirectory"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Terraform Options"
                help="Specify the optional advanced options for Terraform"
                summary={this.optionsSummary()}>
                <VariableLookupText label="Terraform workspace"
                                    localNames={this.props.localNames}
                                    projectId={this.props.projectId}
                                    value={this.props.properties["Octopus.Action.Terraform.Workspace"]}
                                    onChange={(val) => this.props.setProperties({["Octopus.Action.Terraform.Workspace"]: val})}
                                    error={this.props.getFieldError("Octopus.Action.Terraform.Workspace")}
                />
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={this.props.properties["Octopus.Action.Terraform.PluginsDirectory"]}
                    onChange={(x) => this.props.setProperties({["Octopus.Action.Terraform.PluginsDirectory"]: x})}
                    label="Terraform plugin cache directory"
                    error={this.props.getFieldError("Octopus.Action.Terraform.PluginsDirectory")}/>
                <Note>
                    Specify the optional directory that holds the Terraform plugins. This is useful when you want
                    targets to share plugins instead of downloading their own local copy.
                </Note>
                <BoundStringCheckbox
                    variableLookup={{
                        localNames: this.props.localNames,
                        projectId: this.props.projectId
                    }}
                    resetValue={"False"}
                    value={this.props.properties["Octopus.Action.Terraform.AllowPluginDownloads"]}
                    onChange={(x) => this.props.setProperties({["Octopus.Action.Terraform.AllowPluginDownloads"]: x})}
                    label="Allow additional plugin downloads"
                    note={<span>Selecting this option allows Terraform to download plugins that are not found.</span>}/>
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={this.props.properties["Octopus.Action.Terraform.AdditionalInitParams"]}
                    onChange={(x) => this.props.setProperties({["Octopus.Action.Terraform.AdditionalInitParams"]: x})}
                    label="Custom terraform init parameters"
                    error={this.props.getFieldError("Octopus.Action.Terraform.AdditionalInitParams")}/>
                <Note>
                    An optional list of additional parameters to pass to the <strong>terraform init</strong> command.
                </Note>
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={this.props.properties["Octopus.Action.Terraform.AdditionalActionParams"]}
                    onChange={(x) => this.props.setProperties({["Octopus.Action.Terraform.AdditionalActionParams"]: x})}
                    label={<span>Custom terraform {this.props.actionName} parameters</span>}
                    error={this.props.getFieldError("Octopus.Action.Terraform.AdditionalActionParams")}/>
                <Note>
                    An optional list of additional parameters to pass to the <strong>terraform {this.props.actionName}</strong> command.
                </Note>
            </ExpandableFormSection>
        </div>;
    }

    private loadFeeds = () => {
        return this.props.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() });
        });
    }

    private awsManagedAccountSummary() {
        const properties = this.props.properties;

        if (properties["Octopus.Action.Terraform.ManagedAccount"] && properties["Octopus.Action.Terraform.ManagedAccount"] !== "None") {
            return Summary.summary(<span>Using an <strong>AWS</strong> account</span>);
        }

        return Summary.summary("Not using an account");
    }

    private azureManagedAccountSummary() {
        const properties = this.props.properties;

        if (properties["Octopus.Action.Terraform.AzureAccount"] && properties["Octopus.Action.Terraform.AzureAccount"] === "True") {
            return Summary.summary(<span>Using an <strong>Azure</strong> account</span>);
        }

        return Summary.summary("Not using an account");
    }

    private refreshParametersFromTemplate(template: string) {
        this.props.doBusyTask(async () => {
            const response = await repository.CloudTemplates.getMetadata(template, "Terraform");
            this.refreshParametersFromMetadata(response);
        });
    }

    private refreshParametersFromMetadata(metadataResponse: { Metadata: MetadataTypeCollection, Values: DataContext }) {
        this.props.doBusyTask(async () => {
            // merge stored parameter values from step data with default values from template
            const storedParameters: DataContext = this.flattenParameters();
            Object.keys(metadataResponse.Values).forEach(key => {
                if (typeof storedParameters[key] !== "undefined") {
                    metadataResponse.Values[key] = storedParameters[key];
                }
            });
            this.props.setProperties(
                {
                    ["Octopus.Action.Terraform.TemplateParameters"]: JSON.stringify(metadataResponse.Values)
                });
            this.setState({parameterTypes: metadataResponse.Metadata.Types, parameterValues: metadataResponse.Values});
        });
    }

    /**
     * Takes the JSON representation of the variables (i.e. the tool specific format, in this case terraform)
     * and returns the flat data structure used by the dynamic form.
     * @returns {DataContext} The variable information for the dynamic form
     */
    private flattenParameters(): DataContext {
        try {
            if (this.props.properties["Octopus.Action.Terraform.TemplateParameters"]) {
                return JSON.parse(this.props.properties["Octopus.Action.Terraform.TemplateParameters"]);
            }
        } catch (ex) {
            // this.props.properties["Octopus.Action.Terraform.TemplateParametersRaw"] probably isn't
            // valid JSON, so return the empty data context.
        }

        return {};
    }

    /**
     * Takes the dynamic form variable information, and saves it in the tool specific JSON representation.
     * @param {DataContext} data The dynamic form variable data
     */
    private updateParameters(data: DataContext) {
        this.props.setProperties({["Octopus.Action.Terraform.TemplateParameters"]: JSON.stringify(data)});
        this.setState({parameterTypes: this.state.parameterTypes, parameterValues: data});
    }
}
