import Onboarding from "./Onboarding";
import * as React from "react";
import { repository } from "clientInstance";
import ProjectDashboard from "../ProjectDashboard";
import DashboardDataSource from "areas/projects/components/DashboardDataSource";
import { ProjectResource } from "client/resources";
import OverviewFilters from "./OverviewFilters";
import { DashboardFilters, DimensionTypes } from "areas/projects/components/DashboardDataSource/DataCube";
import { filtersFromQueryString, queryStringFromFilters } from "./queryStringFilters";
import PaperLayout from "components/PaperLayout";
import TenantResource from "client/resources/tenantResource";
import { DashboardDataSourceState } from "../DashboardDataSource/DashboardDataSource";
import { TenantedDeploymentMode } from "../../../../client/resources/tenantedDeploymentMode";
import { RouteComponentProps } from "react-router";
import { ProjectRouteParams } from "../ProjectLayout/ProjectLayout";
import { Callout, CalloutType } from "components/Callout/Callout";
import Permission from "client/resources/permission";
import PermissionCheck, { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { DataBaseComponent, DataBaseComponentState } from "../../../../components/DataBaseComponent/DataBaseComponent";
import { NavigationButton, NavigationButtonType } from "components/Button";
import ActionList from "components/ActionList/ActionList";
import routeLinks from "routeLinks";
import { OverflowMenu } from "components/Menu";
import { PerformanceConfigurationResource, DashboardRenderMode } from "client/resources/performanceConfigurationResource";
import { OverflowMenuGenericItem } from "components/Menu/OverflowMenu";

interface OverviewState extends DataBaseComponentState {
    project: ProjectResource;
    filters: DashboardFilters;
    tenants: TenantResource[];
    hasSteps: boolean;
    failedChecks: Array<{ permission: Permission, isNotAllowed: boolean }>;
    dashboardRenderMode: DashboardRenderMode;
}

export default class ProjectOverview extends DataBaseComponent<RouteComponentProps<ProjectRouteParams>, OverviewState> {
    constructor(props: RouteComponentProps<ProjectRouteParams>) {
        super(props);
        this.state = ({
            hasSteps: false,
            project: null,
            tenants: [],
            filters: {},
            failedChecks: [],
            dashboardRenderMode: null
        });
    }

    async componentDidMount() {
        this.doBusyTask(async () => {
            const tenants = await repository.Tenants.all();
            const project = await repository.Projects.get(this.props.match.params.projectSlug);
            const performanceConfiguration = await repository.PerformanceConfiguration.get();

            const requiredPermissions = [
                { permission: Permission.ProjectView, project: project.Id, tenant: "*", projectGroup: project.ProjectGroupId },
                { permission: Permission.ReleaseView, project: project.Id, tenant: "*", projectGroup: project.ProjectGroupId },
                { permission: Permission.EnvironmentView, wildcard: true }
            ];

            const failedChecks = requiredPermissions
                .map(check => ({
                    permission: check.permission,
                    isNotAllowed: !isAllowed(check)
                }))
                .filter(check => check.isNotAllowed);

            if (failedChecks.length > 0) {
                this.setState({
                    project,
                    failedChecks
                });

                return;
            }

            const deploymentProcess = await
                repository.DeploymentProcesses.get(project.DeploymentProcessId);

            this.setState({
                project,
                tenants,
                filters: {
                    ...this.createDefaultFilter(project),
                    ...filtersFromQueryString(this.props.location.search),
                },
                hasSteps: deploymentProcess.Steps.length > 0,
                dashboardRenderMode: this.getDashboardRenderMode(project, performanceConfiguration)
            });
        });
    }

    shouldComponentUpdate(nextProps: RouteComponentProps<ProjectRouteParams>, nextState: OverviewState) {
        if (nextState.failedChecks !== this.state.failedChecks) {
            return true;
        }

        if (nextState.hasSteps !== this.state.hasSteps) {
            return true;
        }

        if (nextState.project !== this.state.project) {
            return true;
        }

        if (nextState.tenants !== this.state.tenants) {
            return true;
        }

        if (nextState.filters !== this.state.filters) {
            return true;
        }

        if (nextState.dashboardRenderMode !== this.state.dashboardRenderMode) {
            return true;
        }

        return false;
    }

    render() {
        if (!this.state.project) {
            return <PaperLayout title="Overview"
                busy={true}
                errors={this.state.errors}
                fullWidth={true} />;
        }

        if (this.state.failedChecks.length > 0) {
            return <PaperLayout title="Overview"
                busy={this.state.busy}
                errors={this.state.errors}
                fullWidth={true}>
                <Callout type={CalloutType.Information} title={"Permission required"}>
                    The {this.state.failedChecks[0].permission} permission is required to view project overview details
                </Callout>
            </PaperLayout>;
        }

        if (this.state.project && !this.state.hasSteps) {

            const actions: JSX.Element[] = [
                <PermissionCheck permission={Permission.EnvironmentCreate} environment="*">
                    <NavigationButton label="Define your deployment process"
                        href={routeLinks.project(this.state.project).process.root}
                        type={NavigationButtonType.Primary}
                    />
                </PermissionCheck>
            ];
            const actionSection = <ActionList actions={actions} />;

            return <PaperLayout title="Overview"
                busy={this.state.busy}
                errors={this.state.errors}
                fullWidth={true}
                sectionControl={actionSection}>
                <Onboarding project={this.state.project} />
            </PaperLayout>;
        }

        return <DashboardDataSource
            project={this.state.project}
            filters={this.state.filters}
            render={this.renderDashboard} />;
    }

    private showLoadingDashboard(dataSource: DashboardDataSourceState) {
        return <ProjectDashboard cube={dataSource.cube}
            filters={this.state.filters}
            allowDeployments={true}
            flatStyle={true}
            dashboardRenderMode={this.state.dashboardRenderMode}
        />;
    }

    private localStorageKeyForVirtualDashboard(project: ProjectResource): string {
        return "virtualDashboard" + project.Id;
    }

    private getDashboardRenderMode(project: ProjectResource, performanceConfiguration: PerformanceConfigurationResource): DashboardRenderMode {
        const localRenderMode = localStorage.getItem(this.localStorageKeyForVirtualDashboard(project));
        if (localRenderMode !== null) {
            const typedRenderModeString = localRenderMode as keyof typeof DashboardRenderMode;
            return DashboardRenderMode[typedRenderModeString];
        }

        return performanceConfiguration.DefaultDashboardRenderMode;
    }

    private setDashboardRenderMode(value: DashboardRenderMode) {
        localStorage.setItem(this.localStorageKeyForVirtualDashboard(this.state.project), value.toString());
        this.setState({dashboardRenderMode: value});
    }

    private filtersUpdated = (filters: DashboardFilters) => {
        const newQS = queryStringFromFilters(filters);
        this.setState({ filters }, () => {
            if (this.props.location.search !== newQS) {
                const location = { ...this.props.history, search: newQS };
                this.props.history.replace(location);
            }
        });
    }

    private renderDashboard = (dataSource: DashboardDataSourceState) => {
        const auditTrailButton = OverflowMenu.navItem("Audit Trail",
            routeLinks.configuration.eventsForProject(this.state.project.Id), null, {
            permission: Permission.EventView,
            wildcard: true
        });

        const overflowMenuItems = [this.getDashboardRenderModeOverflowMenuItem(), auditTrailButton];
        return <PaperLayout
            title="Overview"
            fullWidth={true}
            busy={Promise.all([this.state.busy, dataSource.busy])}
            errors={dataSource.errors}
            sectionControl={<OverflowMenu menuItems={overflowMenuItems} />}
            disableHeaderAnimations={true} // Disabled because of the way the ProjectOverview renders multiple PaperLayouts.
            >
            {dataSource.hasInitialLoaded && dataSource.cube && <OverviewFilters cube={dataSource.cube}
                tenants={this.state.tenants}
                filters={this.state.filters}
                doBusyTask={this.doBusyTask}
                defaultFilter={this.createDefaultFilter(this.state.project)}
                onFiltersUpdated={this.filtersUpdated}
                project={this.state.project}
                render={() => this.showLoadingDashboard(dataSource)}
            />}
        </PaperLayout>;
    }

    private getDashboardRenderModeOverflowMenuItem = (): OverflowMenuGenericItem => {
        const text = this.state.dashboardRenderMode === DashboardRenderMode.VirtualizeColumns
            ? "Switch to fast rendering"
            : "Switch to full rendering";

        const oppositeSetting = this.state.dashboardRenderMode === DashboardRenderMode.VirtualizeColumns
            ? DashboardRenderMode.VirtualizeRowsAndColumns
            : DashboardRenderMode.VirtualizeColumns;

        return OverflowMenu.item(text, () => this.setDashboardRenderMode(oppositeSetting));
    }

    private createDefaultFilter = (project: ProjectResource): DashboardFilters => {
        return {
            [DimensionTypes.Project]: {
                [project.Id]: true
            },
            columnDimension: DimensionTypes.Environment,
            groupBy: project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted ?
                DimensionTypes.Channel :
                DimensionTypes.None,
            rowDimension: project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted ?
                DimensionTypes.Release :
                DimensionTypes.Tenant,
        };
    }
}
