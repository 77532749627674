import * as React from "react";
import { TabItemProps } from "components/Tabs/TabItem";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ToolTip from "../ToolTip/index";
import * as cn from "classnames";
import { getQueryFromWindow as getQuery, getUpdatedWindowQuery as getUpdatedQuery, pushHistory } from "utils/UrlHelper";
import { keyBy, identity } from "lodash";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import { Theme } from "theme";

const styles = (theme: Theme) => createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary
    },
    warning: {
        color: theme.palette.status.danger,
        paddingLeft: "0.25rem"
    }
});

interface TabContainerProps extends WithStyles<typeof styles> {
    defaultValue: string; // The value of the tab you want selected by default.
}

const TabLabel: React.FC<{label: React.ReactNode}> = ({label}) => (
    <span>{label}</span>
);

interface WarningTabLabelProps extends WithStyles<typeof styles> {
    label: React.ReactNode;
    warning: string;
}

const WarningTabLabel: React.FC<WarningTabLabelProps> = ({label, warning, classes}) => (
    <div>
        <span>{label}</span>
        <ToolTip content={warning}><em className={cn("fa", "fa-warning", classes.warning)}/></ToolTip>&nbsp;
    </div>
);

const EnhancedWarningTabLabel = withStyles(styles)(WarningTabLabel);

const convertTab = (tab: React.ReactElement<any>, fallbackValue: string) => {
    const { label, warning, value = fallbackValue, children, onActive }: TabItemProps = tab.props;
    const tabLabel =  warning ? <EnhancedWarningTabLabel label={label} warning={warning}/> : <TabLabel label={label}/>;

    const control = <Tab
        label={tabLabel}
        key={value}
        value={value}
    />;

    return {
        content: children,
        value,
        control,
        onActive
    };
};

interface TabContainerState {
    activeTabValue: string;
    previousTabValue: string;
}

export class TabContainer extends React.Component<TabContainerProps, TabContainerState> {
    constructor(props: TabContainerProps) {
        super(props);
        this.state = {
            activeTabValue: props.defaultValue,
            previousTabValue: null
        };
    }

    render() {
        const activeTabValueFromUrl = getQuery("activeTab", identity);
        const activeTabValue = activeTabValueFromUrl ? activeTabValueFromUrl : this.state.activeTabValue;
        const validElements = React.Children.toArray(this.props.children).filter(React.isValidElement);
        const converted = validElements.map((tab, index) => convertTab(tab, index.toString()));
        const lookup = keyBy(converted, (t) => t.value);
        const tabs =  converted.map(x => x.control);
        const content = lookup[activeTabValue].content;
        const onActive = lookup[activeTabValue].onActive;

        if (this.state.previousTabValue !== activeTabValue) {
            this.setState({activeTabValue, previousTabValue: this.state.activeTabValue});
            if (onActive) {
                onActive();
            }
        }

        return (
            <React.Fragment>
                <Tabs className={this.props.classes.root} value={activeTabValue} onChange={this.handleTabChange}>
                    {tabs}
                </Tabs>
                {content}
            </React.Fragment>
        );
    }

    private handleTabChange = (event: React.ChangeEvent<{}>, activeTabValue: string) => {
        pushHistory(() => getUpdatedQuery("activeTab", activeTabValue));
        this.setState({activeTabValue});
    }
}

export default withStyles(styles)(TabContainer);
