import * as React from "react";
import {repository} from "clientInstance";
import {keyBy} from "lodash";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import PaperLayout from "components/PaperLayout/PaperLayout";
import {Section} from "components/Section/Section";
import {NavigationButton, NavigationButtonType} from "components/Button/NavigationButton";
import {RouteComponentProps} from "react-router";
import {PermissionTable} from "./PermissionTable";
import Permission from "client/resources/permission";
import PermissionCheck, { isAllowedForSpace } from "components/PermissionCheck/PermissionCheck";
import {EnvironmentResource, PermissionDescriptions, Permissions, ProjectedTeamReferenceDataItem, ProjectGroupResource, ProjectResource, SpaceResource, TeamResource, TenantResource, UserPermissionRestriction, UserResource} from "client/resources";
import Select from "components/form/Select/Select";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import routeLinks from "../../../../routeLinks";
import ProjectedTeamsList, {ProjectedTeamListItem} from "./ProjectedTeamsList";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import RadioButtonGroup from "../../../../components/form/RadioButton/RadioButtonGroup";
import RadioButton from "../../../../components/form/RadioButton/RadioButton";
import {SimpleList} from "components/List";
import Callout, { CalloutType } from "components/Callout";

interface TestPermissionState extends DataBaseComponentState {
    selectedUserId?: string;
    selectedUser?: UserResource;
    spacesAccessibleBySelectedUser?: SpaceResource[];
    projectedTeamReferenceDataItems?: ProjectedTeamReferenceDataItem[];
    spacePermissions?: Permissions;
    systemPermissions?: PermissionListItem[];
    isTeamsComplete?: boolean;
    isPermissionsComplete?: boolean;
    exportUrl?: string;
    redirectTo?: string;
    users?: UserResource[];
    projects?: {[ID: string]: ProjectResource};
    environments?: {[ID: string]: EnvironmentResource};
    tenants?: {[ID: string]: TenantResource};
    permissionDescriptions?: PermissionDescriptions;
    projectGroups?: {[ID: string]: ProjectGroupResource};
    permissionContext: PermissionContext;
    selectedSpaceId?: string;
}

interface PermissionListItem {
    Id: string;
    Name: string;
}

enum PermissionContext {System, Space}
class PermissionContextSelection extends RadioButtonGroup<PermissionContext> {}

class SystemPermissionList extends SimpleList<PermissionListItem> {}

export class TestPermissions extends DataBaseComponent<RouteComponentProps<{ userId: string }>, TestPermissionState> {
    constructor(props: RouteComponentProps<{ userId: string }>) {
        super(props);
        this.state = {
            permissionContext: PermissionContext.Space,
        };
    }

    async componentDidMount() {
        return await this.doBusyTask(async () => {

            const selectedUserId = this.props.match.params.userId;
            const users = repository.Users.all();

            this.setState({
                users: await users,
                selectedUserId,
                permissionDescriptions: await repository.PermissionDescriptions.all(),
                ...(selectedUserId ? await this.loadUserContext(selectedUserId, await users) : {})
            });
        });
    }

    async loadUserContext(selectedUserId: string, users: UserResource[]) {
        const selectedUserViaIdOrUserName = users.find(u => u.Id === selectedUserId || u.Username === selectedUserId);
        if (!selectedUserViaIdOrUserName) {
            return {};
        }

        const selectedUser = await repository.Users.get(selectedUserViaIdOrUserName.Id);
        if (!selectedUser) {
            return {};
        }

        const spacesAccessibleBySelectedUser = await repository.Users.getSpaces(selectedUser);
        const systemLevelRepository = await repository.forSpace(null);
        const userPermissions = await systemLevelRepository.UserPermissions.getPermissionsConfigurationForAllParitions(selectedUser, true);

        const selectedSpaceId = spacesAccessibleBySelectedUser.length === 1 ? spacesAccessibleBySelectedUser[0].Id : null;
        const newState =  selectedSpaceId ? await this.selectedSpaceChanged(selectedSpaceId, selectedUser) : {};
        return {
            ...newState,
            selectedUser,
            selectedUserId,
            spacesAccessibleBySelectedUser,
            exportUrl: repository.resolve(userPermissions.Links["Export"]),
            selectedSpaceId
        };
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} />;
        }

        const exportButton = <div>
            {this.state.exportUrl && <NavigationButton
                type={NavigationButtonType.Primary}
                label="Export User Permissions"
                external={true}
                href={this.state.exportUrl}/>}
        </div>;

        return <PaperLayout title="Test Permissions"
                            busy={this.state.busy}
                            errors={this.state.errors}
                            sectionControl={exportButton}>

            {this.state.users && <Section sectionHeader="User">
                <Select
                    label="Select a user"
                    allowFilter={true}
                    autoFocus={true}
                    items={this.state.users.map(u => ({value: u.Id, text: `${u.Username} (${u.DisplayName})`}))}
                    onChange={this.userChanged}
                    value={this.state.selectedUserId}/>
            </Section>}

            {this.state.selectedUser && <Section sectionHeader="Permissions context">

                <PermissionContextSelection
                    value={this.state.permissionContext}
                    onChange={permissionContext => this.permissionContextChanged(permissionContext)}>
                    <RadioButton value={PermissionContext.System} label="Show system permissions" />
                    <RadioButton value={PermissionContext.Space} label="Show permissions within a specific space" />
                </PermissionContextSelection>

                {this.state.permissionContext === PermissionContext.Space && <Select
                    label="Select a space"
                    allowFilter={true}
                    autoFocus={true}
                    items={this.state.spacesAccessibleBySelectedUser.map(s => ({value: s.Id, text: `${s.Name} Space`}))}
                    onChange={(spaceId) => this.doBusyTask(async () => this.setState(await this.selectedSpaceChanged(spaceId, this.state.selectedUser)))}
                    value={this.state.selectedSpaceId}/>}
            </Section>}

            {this.state.projectedTeamReferenceDataItems &&
                <Section sectionHeader="Teams">
                    The user <InternalLink to={routeLinks.configuration.user(this.state.selectedUser)}>{this.state.selectedUser.Username} </InternalLink>
                    is a member of the following teams:
                    {this.renderIncompleteTeamsInfo()}
                    <ProjectedTeamsList items={this.state.projectedTeamReferenceDataItems}
                        onRowRedirectUrl={(teamReference) => this.getRoot(teamReference.SpaceId).configuration.team(teamReference.Id)}
                        onRow={(team) => <ProjectedTeamListItem projectedTeam={team}/>}
                        match={this.props.match}
                    />
                </Section>}

            {this.state.spacePermissions && this.state.permissionContext === PermissionContext.Space &&
                <Section sectionHeader={Object.keys(this.state.spacePermissions).length === 0 ? "No permissions" : "Permissions"}>
                    {this.renderIncompletePermissionsInfo()}
                    <p>
                        <InternalLink to={routeLinks.configuration.user(this.state.selectedUser.Id)}>{this.state.selectedUser.Username || this.state.selectedUser.DisplayName}'s </InternalLink>
                        permissions are restricted to:
                    </p>
                    <PermissionTable
                        showRestrictions={this.state.permissionContext === PermissionContext.Space}
                        permissions={this.state.spacePermissions}
                        permissionDescriptions={this.state.permissionDescriptions}
                        projects={this.state.projects}
                        environments={this.state.environments}
                        tenants={this.state.tenants}
                        projectGroups={this.state.projectGroups}
                        />
                </Section>}
            {this.state.systemPermissions && this.state.permissionContext === PermissionContext.System &&
                <Section sectionHeader={this.state.systemPermissions.length === 0 ? "No permissions" : "Permissions"}>
                    {this.renderIncompletePermissionsInfo()}
                    {this.state.systemPermissions.length > 0 &&
                        <SystemPermissionList
                            items={this.state.systemPermissions}
                            onRow={(p: PermissionListItem) => <div>{p.Name}</div>}
                        />}
                </Section>}
        </PaperLayout>;
    }

    renderIncompleteTeamsInfo() {
        return !this.state.isTeamsComplete && <Callout type={CalloutType.Information} title={"Limited permissions"}>
            Your permissions do not allow you to see all of the teams that '{this.state.selectedUser.DisplayName}' is a member of
        </Callout>;
    }

    renderIncompletePermissionsInfo() {
        return !this.state.isPermissionsComplete && <Callout type={CalloutType.Information} title={"Limited permissions"}>
            Your permissions do not allow you to see all of the permissions granted to '{this.state.selectedUser.DisplayName}'
        </Callout>;
    }

    private userChanged = async (userId: string) => {
        this.setState({redirectTo: routeLinks.configuration.testPermission(userId)});
    }

    private getRoot(spaceId: string | null) {
        return spaceId === null ? routeLinks : routeLinks.forSpace(spaceId);
    }

    private permissionContextChanged = async (permissionContext: PermissionContext) => {
        if (permissionContext === PermissionContext.System) {
            await this.doBusyTask(async () => {
                const systemLevelRepository = await repository.forSpace(null);
                const userPermissions = await systemLevelRepository.UserPermissions.getPermissionsConfigurationForCurrentSpaceContext(this.state.selectedUser, true);
                this.setState({
                    permissionContext,
                    projectedTeamReferenceDataItems: userPermissions.Teams,
                    spacePermissions: userPermissions.SpacePermissions,
                    systemPermissions: userPermissions.SystemPermissions.map(p => ({Id: p, Name: p})),
                    isTeamsComplete: userPermissions.IsTeamsComplete,
                    isPermissionsComplete: userPermissions.IsPermissionsComplete,
                    projects: {},
                    environments: {},
                    projectGroups: {},
                    tenants: {},
                    selectedSpaceId: null
                });
            });

            return;
        }

        this.setState({permissionContext, spacePermissions: null, systemPermissions: null, projectedTeamReferenceDataItems: null, exportUrl: null});
    }

    private selectedSpaceChanged = async (spaceId: string, selectedUser: UserResource) => {
        const spaceLevelRepository = await repository.forSpace(spaceId);
        const userPermissions = await spaceLevelRepository.UserPermissions.getPermissionsConfigurationForCurrentSpaceContext(selectedUser, false);
        const tenants = isAllowedForSpace(spaceId, { permission: Permission.TenantView }) ? spaceLevelRepository.Tenants.all() : Promise.resolve([]);
        const projects =  isAllowedForSpace(spaceId, { permission: Permission.ProjectView }) ? spaceLevelRepository.Projects.all() : Promise.resolve([]);
        const environments =  isAllowedForSpace(spaceId, { permission: Permission.EnvironmentView }) ? spaceLevelRepository.Environments.all() : Promise.resolve([]);
        const projectGroups =  isAllowedForSpace(spaceId, { permission: Permission.ProjectGroupView }) ? spaceLevelRepository.ProjectGroups.all() : Promise.resolve([]);

        return {
            selectedSpaceId: spaceId,
            projectedTeamReferenceDataItems: userPermissions.Teams,
            spacePermissions: userPermissions.SpacePermissions,
            systemPermissions: userPermissions.SystemPermissions.map(p => ({Id: p, Name: p})),
            isTeamsComplete: userPermissions.IsTeamsComplete,
            isPermissionsComplete: userPermissions.IsPermissionsComplete,
            projects: keyBy(await projects, "Id"),
            environments: keyBy(await environments, "Id"),
            tenants: keyBy(await tenants, "Id"),
            projectGroups: keyBy(await projectGroups, "Id"),
            exportUrl: repository.resolve((await userPermissions).Links["Export"])
        };
    }
}

export default TestPermissions;
