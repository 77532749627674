exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .style_hide-sm__12oKq {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .style_visible-sm__2Rcor {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .style_hide-md__361fe {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .style_visible-md__2Sczv {\n    display: none !important;\n  }\n}\nh1 {\n  font-size: 1.625rem;\n  font-weight: 400;\n}\n@media (max-width: 37.4rem) {\n  h1 {\n    font-size: 1.4375rem;\n  }\n}\nh2 {\n  font-size: 1.25rem;\n  font-weight: 400;\n}\n@media (max-width: 37.4rem) {\n  h2 {\n    font-size: 1.0625rem;\n  }\n}\nh3 {\n  font-size: 1.125rem;\n  font-weight: 400;\n}\n@media (max-width: 37.4rem) {\n  h3 {\n    font-size: 1.0625rem;\n  }\n}\nh4 {\n  font-size: 1rem;\n  font-weight: 500;\n  margin: 1rem 0 0.5rem 0;\n}\nh5 {\n  font-size: 0.8125rem;\n  font-weight: 400;\n}\nhr {\n  display: block;\n  height: 0.0625rem;\n  border: 0;\n  border-top: solid 0.0625rem #cccccc;\n  margin: 1em 0;\n  padding: 0;\n}\na {\n  color: #2f93e0;\n}\na:hover {\n  color: #0A67B1;\n}\np {\n  margin: 0.75rem 0;\n  color: #333333;\n}\ninput:-webkit-autofill {\n  -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;\n}\ninput::-ms-clear {\n  width: 0;\n  height: 0;\n}\nsmall {\n  font-size: 0.875rem;\n  line-height: 1.1875rem;\n}\n.style_secondary__OlUSy {\n  color: #9e9e9e;\n}\n.style_section__2Jp-W {\n  flex-grow: 1;\n}\n.style_sectionHeader__1WJUP {\n  padding: 0 1rem;\n  margin: 0.5rem 0;\n}\n.style_sectionBody__2cABV {\n  padding: 0 1rem;\n  color: #666666;\n  font-size: 0.875rem;\n}\n.style_sectionBody__2cABV p {\n  color: #333333;\n}\n.style_sectionNoHeader__2GCHR {\n  margin: 0.5rem 0;\n  width: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "style_hide-sm__12oKq",
	"hideSm": "style_hide-sm__12oKq",
	"visible-sm": "style_visible-sm__2Rcor",
	"visibleSm": "style_visible-sm__2Rcor",
	"hide-md": "style_hide-md__361fe",
	"hideMd": "style_hide-md__361fe",
	"visible-md": "style_visible-md__2Sczv",
	"visibleMd": "style_visible-md__2Sczv",
	"secondary": "style_secondary__OlUSy",
	"section": "style_section__2Jp-W",
	"sectionHeader": "style_sectionHeader__1WJUP",
	"sectionBody": "style_sectionBody__2cABV",
	"sectionNoHeader": "style_sectionNoHeader__2GCHR"
};