import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import SpecialVariables from "client/specialVariables";
import { repository } from "clientInstance";
import Roles from "../Roles";
import { ActionSummaryProps } from "../actionSummaryProps";
import { ActionExecutionLocation } from "../../../client/resources/actionExecutionLocation";
import FeedResource, { FeedType } from "client/resources/feedResource";
import PackageSelector from "components/PackageSelector/PackageSelector";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import CommonSummaryHelper from "utils/CommonSummaryHelper/CommonSummaryHelper";
import ExpandableFormSection, { SummaryNode } from "components/form/Sections/ExpandableFormSection";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import AzurePowerShellScriptInfo from "components/Actions/azurePowerShell/AzurePowerShellScriptInfo";
import WarningFormSection from "components/form/Sections/WarningFormSection";
import AzureServiceFabricSdkRequiredInfo from "components/Actions/azureServiceFabricApp/AzureServiceFabricSdkRequiredInfo";
import { VariableLookupText } from "components/form/VariableLookupText";
import AzureServiceFabricAccountSelector, {
    AzureServiceFabricAccountSelectorProperties,
} from "components/Actions/azureServiceFabricApp/AzureServiceFabricAccountSelector";
import Note from "components/form/Note/Note";
import Summary from "components/form/Sections/Summary";
import { BoundStringCheckbox } from "components/form/Checkbox/StringCheckbox";
import { BoundStringRadioButtonGroup } from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";
import { getFeedName } from "../getFeedName";
import { TargetRoles } from "areas/projects/components/DeploymentProcess/ActionDetails";
import {
    AzureServiceFabricSecurityMode,
    AADCredentialType,
    GetPrimaryPackageReference,
    InitialisePrimaryPackageReference, SetPrimaryPackageReference
} from "client/resources";
import { CalloutType, default as Callout } from "components/Callout/Callout";

interface AzureServiceFabricAppActionSummaryState {
    feedName: string;
}

class AzureServiceFabricAppActionSummary extends BaseComponent<ActionSummaryProps, AzureServiceFabricAppActionSummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
        this.state = { feedName: null };
    }

    async componentDidMount() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        if (pkg) {
            this.setState({ feedName: await getFeedName(pkg.FeedId) });
        }
    }

    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        return pkg
            ? <div>
                Deploy Service Fabric Application <strong> {pkg.PackageId} </strong>
                from {this.state.feedName ? <strong>{this.state.feedName}</strong> : <em>{pkg.FeedId}</em>}
                {this.props.targetRolesAsCSV && <span> on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} /> </span>}
            </div>
            : <Callout type={CalloutType.Warning} title="Misconfigured step">
                Package was not selected or cannot be found. Please review this step and ensure a valid package is selected.
            </Callout>;
    }
}

enum UpgradeBehavior {
    None = "None",
    ForceUpgrade = "ForceUpgrade",
    VetoUpgrade = "VetoUpgrade"
}

enum OverwriteBehavior {
    Never = "Never",
    Always = "Always",
    SameAppTypeAndVersion = "SameAppTypeAndVersion"
}

interface AzureServiceFabricAppProperties extends AzureServiceFabricAccountSelectorProperties {
    "Octopus.Action.ServiceFabric.IsLegacyMode": string;
    "Octopus.Action.ServiceFabric.PublishProfileFile": string;
    "Octopus.Action.ServiceFabric.DeployOnly": string;
    "Octopus.Action.ServiceFabric.UnregisterUnusedApplicationVersionsAfterUpgrade": string;
    "Octopus.Action.ServiceFabric.OverrideUpgradeBehavior": string;
    "Octopus.Action.ServiceFabric.OverwriteBehavior": string;
    "Octopus.Action.ServiceFabric.SkipPackageValidation": string;
    "Octopus.Action.ServiceFabric.CopyPackageTimeoutSec": string;
    "Octopus.Action.ServiceFabric.RegisterApplicationTypeTimeoutSec": string;
}

interface AzureServiceFabricAppState {
    feeds: FeedResource[];
    resetValues: {
        ClientCertVariable: string;
    };
}

class AzureServiceFabricAppActionEdit extends BaseComponent<ActionEditProps<AzureServiceFabricAppProperties>, AzureServiceFabricAppState> {
    constructor(props: ActionEditProps<AzureServiceFabricAppProperties>) {
        super(props);

        this.state = {
            feeds: [],
            resetValues: {
                ClientCertVariable: props.properties["Octopus.Action.ServiceFabric.ClientCertVariable"]
            }
        };
    }

    async componentDidMount() {
        await this.loadFeeds((feeds) => this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, feeds)));
        this.props.doBusyTask(async () => {
            const newProperties: Partial<AzureServiceFabricAppProperties> = {};

            const isLegacyActionType = !!(this.props.properties["Octopus.Action.ServiceFabric.ConnectionEndpoint"]);
            if (isLegacyActionType) {
                if (!this.props.properties["Octopus.Action.ServiceFabric.SecurityMode"]) {
                    newProperties["Octopus.Action.ServiceFabric.SecurityMode"] = AzureServiceFabricSecurityMode.Unsecure;
                }
                if (!this.props.properties["Octopus.Action.ServiceFabric.AadCredentialType"]) {
                    newProperties["Octopus.Action.ServiceFabric.AadCredentialType"] = AADCredentialType.UserCredential;
                }
                newProperties["Octopus.Action.ServiceFabric.IsLegacyMode"] = "True";
            }
            if (!this.props.properties["Octopus.Action.ServiceFabric.OverrideUpgradeBehavior"]) {
                newProperties["Octopus.Action.ServiceFabric.OverrideUpgradeBehavior"] = UpgradeBehavior.None;
            }
            if (!this.props.properties["Octopus.Action.ServiceFabric.OverwriteBehavior"]) {
                newProperties["Octopus.Action.ServiceFabric.OverwriteBehavior"] = OverwriteBehavior.SameAppTypeAndVersion;
            }
            this.props.setProperties(newProperties, true);
        });
    }

    render() {
        // The package is initialized in componentDidMount, but render gets called before the update is propagated
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }

        const properties = this.props.properties;
        const pkg = GetPrimaryPackageReference(this.props.packages);
        const isLegacyActionType = this.isLegacyMode();

        return <div>
            <WarningFormSection>
                <AzureServiceFabricSdkRequiredInfo />
                <AzurePowerShellScriptInfo actionType={this.props.plugin.actionType} />
            </WarningFormSection>

            <FormSectionHeading title="Package" />

            <ExpandableFormSection
                errorKey="Octopus.Action.Package.PackageId|Octopus.Action.Package.FeedId"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Package"
                summary={CommonSummaryHelper.packageSummary(pkg, this.state.feeds)}
                help={<span>This step is used to deploy the contents of a Service Fabric package. The package that you wish to deploy should contain
                    your <em>ApplicationParameters</em> and <em>PublishProfiles</em> folders, as well as your application package folder(s). Please
                    see the <ExternalLink href="ServiceFabricPackaging">Service Fabric Packaging guide</ExternalLink> to learn
                    about what your package should contain, and how to create them.</span>}>
                <PackageSelector
                    packageId={pkg.PackageId}
                    feedId={pkg.FeedId}
                    onPackageIdChange={packageId => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))}
                    onFeedIdChange={feedId => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))}
                    packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                    feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                    projectId={this.props.projectId}
                    feeds={this.state.feeds}
                    localNames={this.props.localNames}
                    refreshFeeds={this.loadFeeds} />
            </ExpandableFormSection>

            {isLegacyActionType &&
                <div>
                    <FormSectionHeading title="Service Fabric Cluster" />
                    <Callout type={CalloutType.Warning} title={"Legacy mode"}>
                        <strong>
                            This step is referencing a Service Fabric cluster directly, instead of referencing a <ExternalLink href="AzureTargets">Service Fabric Cluster Target</ExternalLink> through Roles. Please
                        read <ExternalLink href="AzureTargets">our documentation</ExternalLink> to learn how to get started with Azure Targets.
                    </strong>
                    </Callout>
                    <AzureServiceFabricAccountSelector
                        projectId={this.props.projectId}
                        localNames={this.props.localNames}
                        properties={this.props.properties}
                        clientCertVariableResetValue={this.state.resetValues.ClientCertVariable}
                        setProperties={this.props.setProperties}
                        getFieldError={this.props.getFieldError}
                        doBusyTask={this.props.doBusyTask}
                        expandedByDefault={this.props.expandedByDefault}
                    />
                </div>}

            <FormSectionHeading title="Deployment" />
            <ExpandableFormSection
                errorKey="Octopus.Action.ServiceFabric.PublishProfileFile"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Publish Profile File"
                summary={properties["Octopus.Action.ServiceFabric.PublishProfileFile"]
                    ? Summary.summary(properties["Octopus.Action.ServiceFabric.PublishProfileFile"])
                    : Summary.placeholder("Publish profile file not provided")}
                help={"Select the path to your publish profile file."}>
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={properties["Octopus.Action.ServiceFabric.PublishProfileFile"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.PublishProfileFile"]: x })}
                    error={this.props.getFieldError("Octopus.Action.ServiceFabric.PublishProfileFile")}
                    hintText="eg. PublishProfiles\\Cloud.xml"
                    label="Publish profile file" />
                <Note>Octopus will use the ApplicationParameters file referenced in this publish profile. This value is typically <code>PublishProfiles\Cloud.xml</code></Note>
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="Octopus.Action.ServiceFabric.DeployOnly"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Deploy Only"
                summary={properties["Octopus.Action.ServiceFabric.DeployOnly"] && properties["Octopus.Action.ServiceFabric.DeployOnly"] !== "False"
                    ? Summary.summary(<span>The application will <strong>not</strong> but created or updated</span>)
                    : Summary.default("The application will be created or updated")}
                help="If selected, the Service Fabric application will not be created or upgraded after registering the application type.">
                <BoundStringCheckbox
                    variableLookup={{
                        localNames: this.props.localNames,
                        projectId: this.props.projectId
                    }}
                    resetValue={"False"}
                    value={properties["Octopus.Action.ServiceFabric.DeployOnly"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.DeployOnly"]: x })}
                    label="Deploy only" />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="Octopus.Action.ServiceFabric.UnregisterUnusedApplicationVersionsAfterUpgrade"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Unregister Unused Versions"
                summary={properties["Octopus.Action.ServiceFabric.UnregisterUnusedApplicationVersionsAfterUpgrade"]
                    && properties["Octopus.Action.ServiceFabric.UnregisterUnusedApplicationVersionsAfterUpgrade"] !== "False"
                    ? Summary.summary(<span>Unused versions <strong>will</strong> be unregistered</span>)
                    : Summary.default("Unused versions will not be unregistered")}
                help="Select to unregister any unused application versions that exist after an upgrade is finished.">
                <BoundStringCheckbox
                    variableLookup={{
                        localNames: this.props.localNames,
                        projectId: this.props.projectId
                    }}
                    resetValue={"False"}
                    value={properties["Octopus.Action.ServiceFabric.UnregisterUnusedApplicationVersionsAfterUpgrade"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.UnregisterUnusedApplicationVersionsAfterUpgrade"]: x })}
                    label="Unregister" />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="Octopus.Action.ServiceFabric.OverrideUpgradeBehavior"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Override Upgrade Behavior"
                summary={this.upgradeBehaviorSummary()}
                help="Indicates the behavior used to override the upgrade settings specified by the publish profile.">
                <BoundStringRadioButtonGroup
                    variableLookup={{
                        localNames: this.props.localNames,
                        projectId: this.props.projectId
                    }}
                    resetValue={UpgradeBehavior.None}
                    value={properties["Octopus.Action.ServiceFabric.OverrideUpgradeBehavior"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.OverrideUpgradeBehavior"]: x })}
                    label="Upgrade behavior">
                    <RadioButton value={UpgradeBehavior.None} label="None" isDefault />
                    <RadioButton value={UpgradeBehavior.ForceUpgrade} label="Force upgrade" />
                    <RadioButton value={UpgradeBehavior.VetoUpgrade} label="Veto upgrade" />
                </BoundStringRadioButtonGroup>
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="Octopus.Action.ServiceFabric.OverwriteBehavior"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Overwrite Behavior"
                summary={this.overwriteBehaviorSummary()}
                help="Overwrite behavior if an application exists in the cluster with the same name. This setting is not applicable when upgrading an application.">
                <BoundStringRadioButtonGroup
                    variableLookup={{
                        localNames: this.props.localNames,
                        projectId: this.props.projectId
                    }}
                    resetValue={OverwriteBehavior.SameAppTypeAndVersion}
                    value={properties["Octopus.Action.ServiceFabric.OverwriteBehavior"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.OverwriteBehavior"]: x })}
                    label="Overwrite behavior">
                    <RadioButton value={OverwriteBehavior.Never} label="Never" />
                    <RadioButton value={OverwriteBehavior.Always} label="Always" />
                    <RadioButton value={OverwriteBehavior.SameAppTypeAndVersion} label="Same app type and version" isDefault />
                </BoundStringRadioButtonGroup>
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="Octopus.Action.ServiceFabric.SkipPackageValidation"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Skip Package Validation"
                summary={properties["Octopus.Action.ServiceFabric.SkipPackageValidation"]
                    && properties["Octopus.Action.ServiceFabric.SkipPackageValidation"] !== "False"
                    ? Summary.summary(<span>Tell Service Fabric <strong>not</strong> to perform package validation</span>)
                    : Summary.default("Service Fabric will perform package validation")}
                help="Select to tell Service Fabric not to validate the package before deployment.">
                <BoundStringCheckbox
                    variableLookup={{
                        localNames: this.props.localNames,
                        projectId: this.props.projectId
                    }}
                    resetValue={"False"}
                    value={properties["Octopus.Action.ServiceFabric.SkipPackageValidation"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.SkipPackageValidation"]: x })}
                    label="Skip validation" />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="Octopus.Action.ServiceFabric.CopyPackageTimeoutSec"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Copy Package Timeout"
                summary={properties["Octopus.Action.ServiceFabric.CopyPackageTimeoutSec"]
                    ? Summary.summary(<span>{properties["Octopus.Action.ServiceFabric.CopyPackageTimeoutSec"]} seconds</span>)
                    : Summary.default("Unchanged")}
                help={"Enter a value in seconds to override the timeout for copying an application package to the image store."}>
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={properties["Octopus.Action.ServiceFabric.CopyPackageTimeoutSec"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.CopyPackageTimeoutSec"]: x })}
                    error={this.props.getFieldError("Octopus.Action.ServiceFabric.CopyPackageTimeoutSec")}
                    label="Copy package timeout (seconds)" />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="Octopus.Action.ServiceFabric.RegisterApplicationTypeTimeoutSec"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Register Application Type Timeout"
                summary={properties["Octopus.Action.ServiceFabric.RegisterApplicationTypeTimeoutSec"]
                    ? Summary.summary(<span>{properties["Octopus.Action.ServiceFabric.RegisterApplicationTypeTimeoutSec"]} seconds</span>)
                    : Summary.default("Unchanged")}
                help={"Enter a value in seconds to override the timeout for registering application type."}>
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={properties["Octopus.Action.ServiceFabric.RegisterApplicationTypeTimeoutSec"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.RegisterApplicationTypeTimeoutSec"]: x })}
                    error={this.props.getFieldError("Octopus.Action.ServiceFabric.RegisterApplicationTypeTimeoutSec")}
                    label="Register Application Type Timeout (seconds)" />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="Octopus.Action.ServiceFabric.IsLegacyMode"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Enable Legacy Mode"
                summary={properties["Octopus.Action.ServiceFabric.IsLegacyMode"] === "True"
                    ? Summary.summary(<span><strong>Enabled</strong>: Connection-related properties are configured on this step</span>)
                    : Summary.default(<span>Not enabled: Connection-related properties are configured on the Deployment Target</span>)}
                help={"Select legacy mode if you wish to configure connection-related properties on the step and not through Azure Targets."}>
                <BoundStringCheckbox
                    variableLookup={{
                        localNames: this.props.localNames,
                        projectId: this.props.projectId
                    }}
                    resetValue={""}
                    value={properties["Octopus.Action.ServiceFabric.IsLegacyMode"]}
                    onChange={(x) => {
                        if (x === "True") {
                            this.props.setProperties({
                                ["Octopus.Action.ServiceFabric.IsLegacyMode"]: "True",
                                ["Octopus.Action.ServiceFabric.ConnectionEndpoint"]: "", // Default
                                ["Octopus.Action.ServiceFabric.SecurityMode"]: AzureServiceFabricSecurityMode.Unsecure, // Default
                                ["Octopus.Action.ServiceFabric.AadCredentialType"]: AADCredentialType.UserCredential, // Default
                            });
                        } else {
                            this.clearLegacyModeProps();
                        }
                    }}
                    label="Enable Legacy Mode" />
                <Callout type={CalloutType.Warning} title={"Not recommended"}>
                    Toggling this <strong>on</strong> will allow connection-related properties on the step <strong>(not recommended)</strong>.<br />
                    Toggling this <strong>off</strong> will clear the connection-related properties on the step and allow these to be configured from your Deployment Targets.
                </Callout>
            </ExpandableFormSection>
        </div>;
    }

    private upgradeBehaviorSummary(): SummaryNode {
        const behavior = this.props.properties["Octopus.Action.ServiceFabric.OverrideUpgradeBehavior"];
        if (behavior === UpgradeBehavior.None) {
            return Summary.default("None");
        }
        if (behavior === UpgradeBehavior.ForceUpgrade) {
            return Summary.summary("Force upgrade");
        }
        if (behavior === UpgradeBehavior.VetoUpgrade) {
            return Summary.summary("Veto upgrade");
        }
        return Summary.summary("Bound to a project variable");
    }

    private overwriteBehaviorSummary(): SummaryNode {
        const behavior = this.props.properties["Octopus.Action.ServiceFabric.OverwriteBehavior"];
        if (behavior === OverwriteBehavior.Never) {
            return Summary.summary("Never");
        }
        if (behavior === OverwriteBehavior.Always) {
            return Summary.summary("Always");
        }
        if (behavior === OverwriteBehavior.SameAppTypeAndVersion) {
            return Summary.default("Same app type and version");
        }
        return Summary.summary("Bound to a project variable");
    }

    private loadFeeds = (callback?: (feeds: FeedResource[]) => void) => {
        return this.props.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() }, () => callback && callback(this.state.feeds));
        });
    }

    private isLegacyMode(): boolean {
        const properties = this.props.properties;
        const isLegacyActionType = !!properties &&
            !!(properties["Octopus.Action.ServiceFabric.ConnectionEndpoint"] || properties["Octopus.Action.ServiceFabric.IsLegacyMode"] === "True");
        return isLegacyActionType;
    }

    private clearLegacyModeProps = () => {
        this.props.setProperties({
            ["Octopus.Action.ServiceFabric.IsLegacyMode"]: null,
            ["Octopus.Action.ServiceFabric.ConnectionEndpoint"]: null,
            ["Octopus.Action.ServiceFabric.SecurityMode"]: null,
            ["Octopus.Action.ServiceFabric.ServerCertThumbprint"]: null,
            ["Octopus.Action.ServiceFabric.ClientCertVariable"]: null,
            ["Octopus.Action.ServiceFabric.AadCredentialType"]: null,
            ["Octopus.Action.ServiceFabric.AadClientCredentialSecret"]: null,
            ["Octopus.Action.ServiceFabric.AadUserCredentialUsername"]: null,
            ["Octopus.Action.ServiceFabric.AadUserCredentialPassword"]: null,
        }, false, this.props.refreshRunOn);
    }
}

pluginRegistry.registerDeploymentAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.AzureServiceFabricApp",
    summary: (properties, targetRolesAsCSV, packages) => <AzureServiceFabricAppActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} packages={packages} />,
    edit: AzureServiceFabricAppActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => {
        // Azure steps (pre 2018.5) allowed you to select connection endpoint directly on the step, making target selection optional.
        const isLegacyActionType = !!(action
            ? (action.Properties["Octopus.Action.ServiceFabric.ConnectionEndpoint"] || action.Properties["Octopus.Action.ServiceFabric.IsLegacyMode"] === "True")
            : null);
        return isLegacyActionType ? TargetRoles.Optional : TargetRoles.Required;
    },
    hasPackages: (action) => true,
    features: {
        optional: [
            "Octopus.Features.ConfigurationTransforms",
            "Octopus.Features.ConfigurationVariables",
            "Octopus.Features.CustomScripts",
            "Octopus.Features.JsonConfigurationVariables",
            "Octopus.Features.SubstituteInFiles"
        ]
    }
});
