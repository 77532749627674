exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .style_hide-sm__ACvPL {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .style_visible-sm__3wf5g {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .style_hide-md__UCeoI {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .style_visible-md__2Wiqg {\n    display: none !important;\n  }\n}\n.style_tagSetContainer__28xAy {\n  margin-top: 1rem;\n  margin-bottom: 1rem;\n}\n.style_connectButtonContainer__zFZB- {\n  width: 100%;\n  text-align: center;\n}\n.style_linkedProject__r8bPO {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  font-weight: 500;\n  flex-grow: 1;\n}\n.style_linkedProject__r8bPO .style_header__nxe5w {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  width: 100%;\n  align-items: center;\n}\n.style_linkedProject__r8bPO .style_header__nxe5w .style_projectName__1oV6I {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.style_linkedProject__r8bPO .style_header__nxe5w .style_projectName__1oV6I a {\n  margin-left: 0.25rem;\n  font-size: 1.063rem;\n  font-weight: 500;\n}\n.style_details__1CxBt {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n}\n.style_noEnvironmentsWarning__-wWIp {\n  color: #fb8c00;\n  padding-top: 0.5rem;\n}\n.style_noEnvironmentsWarningIcon__3Wddc {\n  padding-right: 0.5rem;\n}\n.style_noProjectAccess__1bMDW {\n  margin-top: 1rem;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "style_hide-sm__ACvPL",
	"hideSm": "style_hide-sm__ACvPL",
	"visible-sm": "style_visible-sm__3wf5g",
	"visibleSm": "style_visible-sm__3wf5g",
	"hide-md": "style_hide-md__UCeoI",
	"hideMd": "style_hide-md__UCeoI",
	"visible-md": "style_visible-md__2Wiqg",
	"visibleMd": "style_visible-md__2Wiqg",
	"tagSetContainer": "style_tagSetContainer__28xAy",
	"connectButtonContainer": "style_connectButtonContainer__zFZB-",
	"linkedProject": "style_linkedProject__r8bPO",
	"header": "style_header__nxe5w",
	"projectName": "style_projectName__1oV6I",
	"details": "style_details__1CxBt",
	"noEnvironmentsWarning": "style_noEnvironmentsWarning__-wWIp",
	"noEnvironmentsWarningIcon": "style_noEnvironmentsWarningIcon__3Wddc",
	"noProjectAccess": "style_noProjectAccess__1bMDW"
};