import * as React from "react";
import Text from "../form/Text/Text";
import {
    Select,
    StringRadioButtonGroup as RadioButtonGroup,
    ExpandableFormSection,
    Summary,
    FormSectionHeading,
    UnstructuredFormSection
} from "components/form";
import RadioButton from "../form/RadioButton/RadioButton";
import FormPaperLayout from "components/FormPaperLayout";
import {DeleteMachinesBehavior} from "client/resources";
import TimeSpanSelector from "components/form/TimeSpanSelector/TimeSpanSelector";
import {
    CertificateDataFormat,
    TenantedDeploymentMode,
} from "client/resources";
import CertificateSelect from "components/form/CertificateSelect/CertificateSelect";
import AccountSelect from "components/form/AccountSelect/AccountSelect";
import {AccountType} from "client/resources/accountResource";
import Sensitive from "../form/Sensitive/Sensitive";
import FileUpload from "../form/FileUpload/FileUpload";
import SensitiveFileUpload from "../form/SensitiveFileUpload/SensitiveFileUpload";
import Note from "components/form/Note/Note";
import {
    FormBaseComponent,
    FormBaseComponentState
} from "components/FormBaseComponent/FormBaseComponent";
import ColorPicker from "components/form/ColorPicker/ColorPicker";
import TypedRadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import {SensitiveValue} from "client/resources/propertyValueResource";
import {repository} from "clientInstance";
import EnvironmentResource from "client/resources/environmentResource";
import {ResourcesById} from "client/repositories/basicRepository";
import {cloneDeep} from "lodash";
import StringKeyValueEditList from "../EditList/KeyValueEditList";
import { KeyValueEditList, KeyValuePair } from "../EditList/KeyValueEditList";
import StringEditList from "../EditList/StringEditList";
import FormPage from "components/FormPage/FormPage";
import {default as Callout, CalloutType} from "../Callout";

interface Model {
    text: string;
    radioButtonGroup: string;
    typedRadioButtonGroup: DeleteMachinesBehavior;
    timeSpanText: string;
    select: string;
    environmentsById: {};
    colorPicker?: any;
    formError?: string;
    certificateId?: string;
    accountId?: string;
    superSecret?: SensitiveValue;
    fileUpload?: File;
    sensitiveFileUpload?: SensitiveValue;
    commaDelimitedList: string;
    objectHash: string;
    keyValuePairs: KeyValuePair[];
}

// Sample of a typed radio button group.
class DeleteMachinesBehaviorRadioButtonGroup extends TypedRadioButtonGroup<DeleteMachinesBehavior> {
}

interface PersonFilter {
    name: string;
    age?: number;
    favoriteTvShow: string;
}

const title = "Sample";

const SampleFormPageInner = FormPage<ResourcesById<EnvironmentResource>>();

const SampleFormPage: React.SFC = () => {
    return <SampleFormPageInner
        title={title}
        load={() => repository.Environments.allById()}
        render={environmentsById => {
            return <SampleForm environmentsById={environmentsById} />;
        }}
    />;
};

interface SampleFormProps {
    environmentsById: ResourcesById<EnvironmentResource>;
}

class SampleForm extends FormBaseComponent<SampleFormProps, FormBaseComponentState<Model>, Model> {
    constructor(props: SampleFormProps) {
        super(props);

        this.state = {
            model: this.createDefaultModel(),
            cleanModel: this.createDefaultModel()
        };
    }

    render() {
        return <FormPaperLayout
            title={title}
            busy={false}
            model={this.state.model}
            cleanModel={this.state.cleanModel}
            errors={this.state.errors}
            onSaveClick={this.saveWithValidationError}
            saveButtonLabel="Click me to see validation errors">
            <ExpandableFormSection
                errorKey="text"
                title="Text"
                summary={this.state.model.text ? Summary.summary(this.state.model.text) :
                    Summary.placeholder("Placeholder text when no value has been entered")}
                help="Enter text">
                <Text
                    label="Text"
                    value={this.state.model.text}
                    onChange={text => this.setModelState({text})}
                />
                <Note>This is sample note</Note>
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="multiline"
                title="Multiline"
                summary={Summary.placeholder("Placeholder multiline text when no value has been entered")}
                help="Enter text">
                <Text
                    label="Text"
                    value=""
                    multiLine={true}
                    onChange={x => undefined}
                />
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="select"
                title="Select"
                summary={Summary.summary("Summary text when a non-default value has been selected or you have content in a text box etc")}
                help="Select select">
                <Select
                    error={this.state.model.formError === "Error" ? "There is an error" : null}
                    warning={this.state.model.formError === "Warning" ? "I must warn you" : null}
                    label="Select" value="Option2"
                    items={["Option1", "Option2", "Option3"].map(op => ({value: op, text: op}))}
                    onChange={x => undefined}
                />
                <CertificateSelect
                    error={this.state.model.formError === "Error" ? "There is an error" : null}
                    warning={this.state.model.formError === "Warning" ? "I must warn you" : null}
                    allowClear={true}
                    value={this.state.model.certificateId}
                    onChange={certificateId => this.setModelState({certificateId})}
                    doBusyTask={this.doBusyTask}
                />
                <AccountSelect
                    onRequestRefresh={Promise.resolve}
                    type={[AccountType.AzureSubscription, AccountType.SshKeyPair, AccountType.UsernamePassword]}
                    value={this.state.model.accountId}
                    error={this.state.model.formError === "Error" ? "There is an error" : null}
                    warning={this.state.model.formError === "Warning" ? "I must warn you" : null}
                    allowClear={true}
                    onChange={accountId => this.setModelState({accountId})}
                    items={[
                        {
                            Id: "ABC",
                            Name: "An Azure Account",
                            TenantIds: [],
                            EnvironmentIds: [],
                            TenantTags: [],
                            Description: "Desc",
                            Links: {},
                            AccountType: AccountType.AzureSubscription,
                            TenantedDeploymentParticipation: TenantedDeploymentMode.Tenanted
                        },
                        {
                            Id: "ABC2",
                            Name: "A Ssh Account",
                            TenantIds: [],
                            EnvironmentIds: [],
                            TenantTags: [],
                            Description: "Desc",
                            Links: {},
                            AccountType: AccountType.SshKeyPair,
                            TenantedDeploymentParticipation: TenantedDeploymentMode.Tenanted
                        },
                        {
                            Id: "ABC3",
                            Name: "A Username Account",
                            TenantIds: [],
                            EnvironmentIds: [],
                            TenantTags: [],
                            Description: "Desc",
                            Links: {},
                            AccountType: AccountType.UsernamePassword,
                            TenantedDeploymentParticipation: TenantedDeploymentMode.Tenanted
                        }]}/>
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="radioButtonGroup"
                title="RadioButtonGroup"
                summary={this.state.model.formError ?
                    Summary.summary(<span>Sample summary as a sentence with selected value <strong>{this.state.model.formError}</strong> highlighted.</span>)
                    :
                    Summary.default("Summary text when a non-default value has been selected")}
                help="RadioButtonGroup">
                <RadioButtonGroup
                    label="This is a label"
                    value={this.state.model.formError || "None"}
                    onChange={formError => this.setModelState({formError})}>
                    <RadioButton value="None" label="None" isDefault={true}/>
                    <Note>Note for None</Note>
                    <RadioButton value="Error" label="Error"/>
                    <Note>Note for Error</Note>
                    <RadioButton value="Warning" label="Warning"/>
                    <Note>Note for Warning</Note>
                </RadioButtonGroup>
            </ExpandableFormSection>
            <FormSectionHeading title="Section Heading 1"/>
            <ExpandableFormSection
                errorKey="typedRadioButtonGroup"
                title="Typed Radio Button Group"
                summary={Summary.summary("Sample of a typed radio button group")}
                help="TypedRadioButtonGroup">
                <DeleteMachinesBehaviorRadioButtonGroup
                    value={this.state.model.typedRadioButtonGroup}
                    onChange={(x) => this.setState(prevState =>
                        prevState.model.typedRadioButtonGroup = x)}
                >
                    <RadioButton value="DoNotDelete" label="Do not delete deployment targets automatically"
                                 isDefault={true}/>
                    <RadioButton value="DeleteUnavailableMachines"
                                 label="Automatically delete unavailable deployment targets"/>
                </DeleteMachinesBehaviorRadioButtonGroup>
                {
                    this.state.model.typedRadioButtonGroup === "DeleteUnavailableMachines"
                        ? <div>
                            <TimeSpanSelector
                                value={this.state.model.timeSpanText}
                                onChange={(x) => this.setState(prevState => prevState.model.timeSpanText = x)}
                            />
                        </div>
                        : null
                }
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="colorSelector"
                title="Color Picker"
                summary={Summary.summary("Samplecolor selector")}
                help="Color selector">
                <ColorPicker value={this.state.model.colorPicker}
                             defaultColor="#ff0000"
                             label="Color"
                             onChange={(color) => this.setState(prevState => prevState.model.colorPicker = color)}/>
            </ExpandableFormSection>
            <UnstructuredFormSection>
                <Callout type={CalloutType.Warning} title={"Unstructured Form Section Component"}>
                    <div style={{textAlign: "center"}}>
                    Here is a non-expanding information panel that may apply to a whole section, rather than an
                    individual Summary. You can also use it to explain the current state of the UI. E.g. why certain parts of the UI are not visible.
                    </div>
                </Callout>
            </UnstructuredFormSection>
            <FormSectionHeading title="Section Heading 2"/>
            <ExpandableFormSection
                errorKey="comma seperated list"
                title="Comma Delimited String List"
                summary={Summary.summary("String is internally split by ',' and recombined before passing back")}
                help="TypedRadioButtonGroup">
                <StringEditList name="Animal"
                                label="I want a...."
                                items={this.state.model.commaDelimitedList}
                                onChange={x => this.setState(prevState => prevState.model.commaDelimitedList = x)} />
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="Parsed Object Hash"
                title="Parsed Object Hash"
                summary={Summary.summary("Value is a stringified object hash that is internally parsed and passed back as a string")}
                help="TypedRadioButtonGroup">
                <StringKeyValueEditList separator="="
                                  name="Details"
                                  keyLabel="Property"
                                  valueLabel="Value"
                                  items={this.state.model.objectHash}
                                  onChange={x => this.setState(prevState => prevState.model.objectHash = x)} />
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="Key value array"
                title="Key value array"
                summary={Summary.summary("Array of key value pairs")}
                help="TypedRadioButtonGroup">
                <KeyValueEditList separator="="
                                  name="Details"
                                  keyLabel="Property"
                                  valueLabel="Value"
                                  items={() => this.state.model.keyValuePairs}
                                  onChange={x => this.setState((prevState) => prevState.model.keyValuePairs = x)} />
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="typedRadioButtonGroup"
                title="Typed Radio Button Group"
                summary={Summary.summary("Sample of a typed radio button group")}
                help="TypedRadioButtonGroup">
                <DeleteMachinesBehaviorRadioButtonGroup
                    value={this.state.model.typedRadioButtonGroup}
                    onChange={(x) => this.setState(prevState =>
                        prevState.model.typedRadioButtonGroup = x)}
                >
                    <RadioButton value="DoNotDelete" label="Do not delete machines automatically"
                                 isDefault={true}/>
                    <RadioButton value="DeleteUnavailableMachines"
                                 label="Automatically delete unavailable machines"/>
                </DeleteMachinesBehaviorRadioButtonGroup>
                {
                    this.state.model.typedRadioButtonGroup === "DeleteUnavailableMachines"
                        ? <div>
                            <TimeSpanSelector
                                value={this.state.model.timeSpanText}
                                onChange={(x) => this.setState(prevState => prevState.model.timeSpanText = x)}
                            />
                        </div>
                        : null
                }
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="sensitive"
                                   title="Sensitive Password"
                                   summary={Summary.summary("Sample of sensitive input")}
                                   help="Enter a password and see the result..">
                <Sensitive label="Super secret" value={this.state.model.superSecret}
                           onChange={value => this.setModelState({superSecret: value})}/>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="file"
                                   title="File Uploading"
                                   summary={Summary.summary("Sample of file uploading")}
                                   help="Pretend to upload the file">
                <FileUpload label="File upload" value={this.state.model.fileUpload}
                            onChange={fileUpload => this.setModelState({fileUpload})}/>
                <SensitiveFileUpload label="Secret file upload" value={this.state.model.sensitiveFileUpload}
                                     onChange={sensitiveFileUpload => this.setModelState({sensitiveFileUpload})}/>
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="timeSpanSelector"
                title="Time Span"
                summary={Summary.summary("Sample of a timespan selector")}
                help="TimeSpanSelector">
                <TimeSpanSelector
                    value={this.state.model.timeSpanText}
                    onChange={(x) => this.setState(prevState => prevState.model.timeSpanText = x)}
                    error="with an error"
                    />
            </ExpandableFormSection>
            <ExpandableFormSection
                isExpandedByDefault={true}
                errorKey="expandedByDefault"
                title="An Expander That Expanded by Default"
                summary={Summary.summary("Sample expander that expanded by default")}
                help="This expander is expanded by default, it lines up with the Redux store's state so Expand all and Collapse all buttons should work.">
                <div>It is about the expander</div>
            </ExpandableFormSection>
        </FormPaperLayout>;
    }

    private createDefaultModel() {
        return {
            isLoaded: false,
            text: null as string,
            radioButtonGroup: "Value2",
            typedRadioButtonGroup: "DeleteUnavailableMachines" as DeleteMachinesBehavior,
            timeSpanText: "1.00:05:00",
            select: "Option2",
            emptyProgression: {NextDeployments: [] as string[]},
            singleEnvironmentProgression: {NextDeployments: ["Environments-1"]},
            multipleEnvironmentProgression: {NextDeployments: ["Environments-1", "Environments-2"]},
            environmentsById: {
                "Environments-1": {Id: "Environments-1", Name: "Environment 1"},
                "Environments-2": {Id: "Environments-2", Name: "Environment 2"}
            },
            commaDelimitedList: "cat,dog,monkey",
            objectHash: JSON.stringify({ name: "bob", phone: "555-callme" }),
            keyValuePairs: [{key: "bob", value: "555-callme"}]
        };
    }

    private saveWithValidationError = async () => {
        this.setError("Sample validation error");
        return false;
    }
}

export default SampleFormPage;
