import * as React from "react";
import { Card, CardMedia, CardTitle } from "material-ui/Card";
const styles = require("./style.less");
import Expandable, { ExpandableProps } from "components/Expandable/Expandable";
import { Collapse } from "@material-ui/core";

interface SimpleExpanderProps {
    error?: string;
    title: React.ReactNode;
    onDidExpand?(expanded: boolean): void;
}

type Props = SimpleExpanderProps & ExpandableProps;

export class SimpleExpanderInternal extends React.Component<Props> {

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.isExpanded !== nextProps.isExpanded) {
            if (nextProps.onDidExpand) {
                nextProps.onDidExpand(nextProps.isExpanded);
            }
        }
    }

    // we can't just use actAsExpander on card Title because it eats the click
    // and we can't add links to the title in help text or our show more info link
    handleExpand = (e: any) => {
        e.preventDefault();
        const isExpanded = !this.props.isExpanded;
        this.props.onExpandedChanged(isExpanded);
    }

    renderChild = (child: any): any => {
        if (!child) {
            return child;
        }
        const children = child.props && child.props.children
            ? { children: this.renderChildren(child.props.children) }
            : null;

        const props = children ? { ...child.props, ...children } : child.props;
        return React.isValidElement(child) ? React.cloneElement(child, props) : child;
    }

    renderChildren = (children: any) => {
        return React.Children.map(children, this.renderChild);
    }

    render() {
        return <Card
            className={styles.formExpander}
            expanded={this.props.isExpanded}>
            <CardTitle
                title={this.props.title}
                onClick={(e: any) => this.handleExpand(e)}
                showExpandableButton={true}
            />
            <Collapse in={this.props.isExpanded} timeout="auto" unmountOnExit={true}>
                <CardMedia
                    className={styles.cardMedia}
                    expandable={true}>
                    {/*this div prevents material-ui from logging a warning https://github.com/callemall/material-ui/issues/4239#issuecomment-268054415*/}
                    <div>
                        {this.renderChildren(this.props.children)}
                    </div>
                </CardMedia>
            </Collapse>
        </Card >;
    }
}

const SimpleExpander = Expandable<SimpleExpanderProps>(SimpleExpanderInternal);
export default SimpleExpander;