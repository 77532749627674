exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_markdownEditorContainer__1G5rk {\n  position: relative;\n  width: 100%;\n}\n.style_markdownControlsContainer__1RkW2 {\n  display: flex;\n  flex-direction: row;\n  align-items: baseline;\n}\n.style_restrictedHeight__kHLAa {\n  max-height: 15rem;\n}\n.style_iconButton___5HNj {\n  width: 2rem !important;\n  min-width: 2rem !important;\n}\n.style_iconButton___5HNj:hover span {\n  opacity: 1;\n}\n.style_iconButton___5HNj span {\n  padding-left: 0rem !important;\n  padding-right: 0rem !important;\n  opacity: 0.6;\n}\n.style_markdownSupported__2F-aV {\n  padding-right: 0.25rem;\n}\n.style_iconButtonContainer__1pHWS {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  background: rgba(153, 153, 153, 0.08);\n  border: 0.0625rem solid #cccccc;\n  border-bottom: 0;\n}\n.style_textarea__vr5fk {\n  max-width: inherit !important;\n}\n.style_markdownControlsLink__EAOSB {\n  font-size: 0.75rem;\n  font-weight: 300;\n}\n", ""]);

// exports
exports.locals = {
	"markdownEditorContainer": "style_markdownEditorContainer__1G5rk",
	"markdownControlsContainer": "style_markdownControlsContainer__1RkW2",
	"restrictedHeight": "style_restrictedHeight__kHLAa",
	"iconButton": "style_iconButton___5HNj",
	"markdownSupported": "style_markdownSupported__2F-aV",
	"iconButtonContainer": "style_iconButtonContainer__1pHWS",
	"textarea": "style_textarea__vr5fk",
	"markdownControlsLink": "style_markdownControlsLink__EAOSB"
};