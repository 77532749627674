import {
    LibraryVariableSetResource,
    VariableSetContentType
} from "../../../../client/resources/libraryVariableSetResource";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
import * as React from "react";
import PaperLayout from "../../../../components/PaperLayout/PaperLayout";
import { repository } from "clientInstance";
import { NavigationButton, NavigationButtonType } from "../../../../components/Button/NavigationButton";
import { ResourceCollection } from "../../../../client/resources/resourceCollection";
import { List } from "../../../../components/List/List";
import ListTitle from "components/ListTitle/ListTitle";
import { RouteComponentProps } from "react-router";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import { Permission } from "client/resources";
import MarkdownDescription from "components/MarkdownDescription";
import SectionNote from "components/SectionNote/SectionNote";

interface ScriptModulesListState extends DataBaseComponentState {
    scriptModulesResponse: ResourceCollection<LibraryVariableSetResource>;
}

class LibraryVariableSetResourceList extends List<LibraryVariableSetResource> { }

export class ScriptModulesList extends DataBaseComponent<RouteComponentProps<{}>, ScriptModulesListState> {

    constructor(props: RouteComponentProps<{}>) {
        super(props);
        this.state = {
            scriptModulesResponse: null,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const getScriptModules = repository.LibraryVariableSets.list({ contentType: VariableSetContentType.ScriptModule });
            const scriptModulesResponse = (await getScriptModules);
            this.setState({ scriptModulesResponse });
        });
    }

    render() {
        const addButton = <PermissionCheck permission={Permission.LibraryVariableSetCreate}>
            <NavigationButton
                type={NavigationButtonType.Primary}
                label="Add Script Module"
                href={`${this.props.match.url}/create`} />
        </PermissionCheck>;
        return <LibraryLayout {...this.props}>
            <PaperLayout title="Script Modules"
                sectionControl={addButton}
                busy={this.state.busy}
                errors={this.state.errors}>
                <SectionNote>
                    Script modules are collections of language specific functions that can be included in multiple projects
                </SectionNote>
                {this.state.scriptModulesResponse &&
                    <LibraryVariableSetResourceList initialData={this.state.scriptModulesResponse}
                        onRow={(module: any) => this.buildRow(module)}
                        onFilter={this.filterThings}
                        filterSearchEnabled={true}
                        apiSearchParams={["partialName"]}
                        match={this.props.match}
                        filterHintText="Filter by name..."
                        additionalRequestParams={new Map([["contentType", ["ScriptModule"]]])}
                    />}
            </PaperLayout>
        </LibraryLayout>;
    }

    private buildRow = (scriptModule: LibraryVariableSetResource) => {
        return [
            <div key={scriptModule.Id}>
                <ListTitle>{scriptModule.Name}</ListTitle>
                <MarkdownDescription markup={scriptModule.Description} />
            </div>
        ];
    }

    private filterThings(filter: string, resource: LibraryVariableSetResource) {
        return !filter || filter.length === 0 || !resource
            || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }
}

export default ScriptModulesList;
