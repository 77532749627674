import * as React from "react";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import MaterialDialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { SFC } from "react";
import { dialogClass } from "uiTestClasses";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import { Theme } from "theme";
import * as cn from "classnames";

interface DialogProps {
    open?: boolean;
    wide?: boolean;
    repositionOnUpdate?: boolean;
    onRequestClose?: () => void;
    fullScreen?: boolean;
    title?: React.ReactNode;
    actions?: React.ReactNode;
}

const styles = (theme: Theme) => createStyles({
    wide: {
        maxWidth: "1000px",
        width: "100%",
    },
    normal: {
        maxWidth: "600px",
        width: "100%", // UX: Needed for mobile.
    }
});

const DialogRaw: SFC<DialogProps & WithStyles<typeof styles>> = ({ classes, wide, open, children, fullScreen, title, actions }) => {
    return (
        <MaterialDialog
            classes={{ paperWidthSm: wide ? classes.wide : classes.normal }}
            className={cn(dialogClass)}
            open={open || false}
            fullScreen={fullScreen}
        >
            {title && <DialogTitle>{title}</DialogTitle>}
            {children}
            {actions && <DialogActions>{actions}</DialogActions>}
        </MaterialDialog>
    );
};

const Dialog = withMobileDialog<DialogProps>()(withStyles(styles)(DialogRaw));
Dialog.displayName = "Dialog";

export default Dialog;