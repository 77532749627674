import { environmentChipList, ExternalSecurityGroupChip, projectChipList, projectGroupChipList, tenantChipList } from "components/Chips";
import * as React from "react";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";
import OverflowMenu, { OverflowMenuDisabledItem, OverflowMenuGenericItem, OverflowMenuNavLink } from "components/Menu/OverflowMenu";
import { client, repository, session } from "clientInstance";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import { required } from "components/form/Validators";
import { Summary, Text, Note, FormSection, UnstructuredFormSection } from "components/form";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import { UserInviteDialog } from "areas/configuration/components/Users/UserInviteDialog";
import { EnvironmentResource, ExternalSecurityGroupProviderResource, NewScopedUserRoleResource, ProjectGroupResource, ProjectResource, ScopedUserRoleResource, SpaceResource, TeamResource, TenantResource, TeamConstants } from "client/resources";
import { UserRoleResource, UserRoleConstants } from "client/resources/userRoleResource";
import { fromPairs, sortBy, uniq } from "lodash";
import { RemoveItemsList } from "components/RemoveItemsList/RemoveItemsList";
import { UserResource } from "client/resources/userResource";
import { NamedReferenceItem } from "client/resources/namedReferenceItem";
import { Avatar } from "components/Avatar/Avatar";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import AddMember from "areas/configuration/components/Teams/AddMember";
import AddExternalGroup from "areas/configuration/components/Teams/AddExternalGroup";
import AddExternalRole from "areas/configuration/components/Teams/AddExternalRole";
import Permission from "client/resources/permission";
import StringHelper from "utils/StringHelper";
import routeLinks from "../../../../routeLinks";
import { UserChip } from "components/Chips/UserChip";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import TabContainer from "components/Tabs/TabContainer";
import TabItem from "components/Tabs/TabItem";
import IncludeRoleDialog, { IncludeRoleDialogResult } from "./IncludeRoleDialog";
import { ScopedUserRolesExpandersList } from "./ScopedUserRolesExpandersList/ScopedUserRolesExpandersList";
import { Card, CardTitle } from "material-ui/Card";
import { formSectionClass, formSectionTitleClass } from "uiTestClasses";
import * as cn from "classnames";
import buildValueList from "components/EventFilter/buildValueList";
import { connect } from "react-redux";
import GlobalState from "globalState";
import Repository from "client/repository";
import teamEditStyles = require("./TeamEditStyle.less");
import Chip from "components/Chips/Chip";
import ExternalLink from "components/Navigation/ExternalLink";

const workTeamImage = require("../../../../components/Avatar/img/work_team.svg");

export type ScopedUserRoleModel = ScopedUserRoleResource | NewScopedUserRoleResource;

interface TeamEditModel {
    name: string;
    memberUserIds: string[];
    externalSecurityGroups: NamedReferenceItem[];
    scopedUserRoles: ScopedUserRoleModel[];
}

export interface TeamEditProps {
    teamId: string;
}

type MemberTypes = UserResource | NamedReferenceItem;

class UserList extends RemoveItemsList<MemberTypes> {
}

function isUser(item: MemberTypes): item is UserResource {
    return !!(item as UserResource).Username;
}

export interface AvailableRoleScopes {
    projects: ProjectResource[];
    projectGroups: ProjectGroupResource[];
    environments: EnvironmentResource[];
    tenants: TenantResource[];
}

interface TeamEditState extends OptionalFormBaseComponentState<TeamEditModel> {
    team: TeamResource;
    isSystem: boolean;
    availableScopes: Record<string, AvailableRoleScopes>;
    userRoles: UserRoleResource[];
    users: UserResource[];
    spaces: SpaceResource[];
    deleted: boolean;
    externalSecurityGroupProviders?: ExternalSecurityGroupProviderResource[];
}

interface ConnectedProps {
    isMultiTenancyEnabled: boolean;
}

async function loadAvailableScopesForSpace(spaceId: string) {
    if (repository.spaceId === spaceId) {
        return await load(repository);
    }
    return await load(await repository.forSpace(spaceId));

    async function load(scopedRepository: Repository): Promise<AvailableRoleScopes> {
        const projectsAsync = scopedRepository.Projects.all();
        const projectGroupAsync = scopedRepository.ProjectGroups.all();
        const environmentsAsync = scopedRepository.Environments.all();
        const tenantsAsync = scopedRepository.Tenants.all();

        return {
            projects: await projectsAsync,
            projectGroups: await projectGroupAsync,
            environments: await environmentsAsync,
            tenants: await tenantsAsync
        };
    }
}

class TeamEdit extends FormBaseComponent<ConnectedProps & TeamEditProps, TeamEditState, TeamEditModel> {
    constructor(props: ConnectedProps & TeamEditProps) {
        super(props);
        this.state = {
            team: null,
            isSystem: false,
            model: null,
            cleanModel: null,
            deleted: false,
            availableScopes: {},
            userRoles: [],
            users: [],
            spaces: [],
        };
    }

    async componentDidMount() {
        if (this.state.deleted) {
            return;
        }
        await this.doBusyTask(async () => {
            const teamAsync = repository.Teams.get(this.props.teamId);
            const scopedUserRolesAsync = loadScopedUserRoles(teamAsync);

            const availableScopesAsync = loadAvailableScopes(scopedUserRolesAsync);
            const userRolesAsync = repository.UserRoles.all();
            const usersAsync = repository.Users.all();
            const spacesAsync = loadSpaces(teamAsync);

            const externalSecurityGroupProvidersAsync =
                (isAllowed({ permission: Permission.TeamEdit }))
                    ? repository.ExternalSecurityGroupProviders.all()
                    : [] as ExternalSecurityGroupProviderResource[];

            const team = await teamAsync;
            const loadedScopedUserRoles = await scopedUserRolesAsync;
            const model = this.buildModel(team, loadedScopedUserRoles);

            this.setState({
                team,
                isSystem: team.SpaceId === null,
                model,
                cleanModel: model,
                availableScopes: await availableScopesAsync,
                userRoles: await userRolesAsync,
                users: await usersAsync,
                externalSecurityGroupProviders: await externalSecurityGroupProvidersAsync,
                spaces: await spacesAsync,
            });

            async function loadSpaces(teamPromise: Promise<TeamResource>) {
                // We don't want to give users the option of creating scoped user roles within spaces
                // that they can't operate within (they don't currently have any permissions within that space)
                // AND they can see they space itself (because they have system level SpaceView permission)
                // So here we *only* load the spaces that the user can access
                const [userSpaces, loadedTeam] = await Promise.all([repository.Users.getSpaces(session.currentUser), teamPromise]);

                // If they could load the team, then they have access *within* that team's space
                return loadedTeam.SpaceId ? userSpaces.filter(s => s.Id === loadedTeam.SpaceId) : userSpaces;
            }

            async function loadScopedUserRoles(teamPromise: Promise<TeamResource>): Promise<ScopedUserRoleResource[]> {
                const loadedTeam = await teamPromise;
                return (await repository.Teams.listScopedUserRoles(loadedTeam)).Items;
            }

            async function loadAvailableScopes(scopedUserRolesPromise: Promise<ScopedUserRoleResource[]>): Promise<Record<string, AvailableRoleScopes>> {
                const scopedUserRoles = await scopedUserRolesPromise;
                const uniqueSpaceIds = uniq(scopedUserRoles.map(r => r.SpaceId).filter(s => !!s));
                const availableScopes = await Promise.all(uniqueSpaceIds.map(async id => {
                    return {
                        spaceId: id,
                        availableScopes: await loadAvailableScopesForSpace(id)
                    };
                }));
                return fromPairs<AvailableRoleScopes>(availableScopes.map<[string, AvailableRoleScopes]>(a =>
                    [a.spaceId, a.availableScopes]));
            }
        });
    }

    render() {
        const title = this.state.model
            ? <span>{this.state.model.name} {this.addTeamClassificationChip()}</span>
            : StringHelper.ellipsis;

        const overFlowActions = [];
        if (this.canBeDeleted()) {
            overFlowActions.push(OverflowMenu.deleteItemDefault("team", this.handleDeleteConfirm, { permission: Permission.TeamDelete }));
        }
        if (this.state.model) {
            if (this.state.model.name) {
                overFlowActions.push(
                    OverflowMenu.downloadItem(
                        "Download Team as JSON",
                        this.state.model.name + "-team.json",
                        client.resolveLinkTemplate("Teams", { id: this.props.teamId })));
            }
            overFlowActions.push([OverflowMenu.navItem("Audit Trail",
                routeLinks.configuration.eventsRegardingTeam(this.state.team), null, {
                    permission: Permission.EventView,
                    wildcard: true
                })]);

            const hasTeamEditAndUserInvite = isAllowed({ permission: Permission.TeamEdit })
                && isAllowed({ permission: Permission.TeamCreate })
                && isAllowed({ permission: Permission.UserInvite });

            if (hasTeamEditAndUserInvite && !!this.state.team) {
                overFlowActions.push([
                    OverflowMenu.dialogItem(
                        "Invite User",
                        <UserInviteDialog team={this.state.team} />)
                ]);
            }
        }

        const actions = this.state.team ? [<OpenDialogButton
            key="include user role"
            label={"Include User Role"}
            wideDialog={false}>
            <IncludeRoleDialog
                isTeamConstrainedToASpace={!!this.state.team.SpaceId}
                spaces={this.state.spaces}
                roles={this.state.userRoles}
                isOnlySpaceManagerRoleOnSpaceManagerTeam={false}
                loadScopedUserRoles={this.fetchAvailableScopesForSpace}
                scopedUserRole={null}
                restrictToSpaceId={this.state.team.SpaceId ? this.state.team.SpaceId : undefined}
                onSave={(value: IncludeRoleDialogResult) => this.handleScopedUserRoleAdded(value)}
            />
        </OpenDialogButton>] : [];

        return <FormPaperLayout
            title={title}
            breadcrumbTitle={"Teams"}
            breadcrumbPath={routeLinks.configuration.teams.root()}
            saveText="Team details changed"
            busy={this.state.busy}
            errors={this.state.errors}
            model={this.state.model}
            cleanModel={this.state.cleanModel}
            savePermission={{ permission: Permission.TeamEdit, wildcard: true }}
            expandAllOnMount={false}
            onSaveClick={this.handleSaveClick}
            overFlowActions={overFlowActions}
            hideExpandAll={true}
        >
            {this.state.deleted && <InternalRedirect to={routeLinks.configuration.teams.root()} />}
            {this.state.model && <div>
                <TabContainer defaultValue="members">
                    <TabItem label="Members" value="members">
                        <UnstructuredFormSection>
                            <Note>Add users and groups to this team. A user can belong to more than one team.</Note>
                            {this.renderMembers()}
                        </UnstructuredFormSection>
                    </TabItem>
                    <TabItem label="User Roles" value="user roles">
                        <ScopedUserRolesExpandersList
                            helpElement={<span>User roles grant teams permissions. <ExternalLink href="UserRoleContext">Learn more</ExternalLink>.</span>}
                            spaces={this.state.spaces}
                            scopedUserRoles={this.state.model.scopedUserRoles}
                            listActions={actions}
                            onRow={(item: ScopedUserRoleModel, index: number) => {
                                const userRole = this.state.userRoles.find(x => x.Id === item.UserRoleId);
                                if (!userRole) {
                                    console.error(`Failed to find role ${item.UserRoleId}`);
                                    return;
                                }
                                const spaceOverflowMenuItems: any = [];
                                spaceOverflowMenuItems.push(OverflowMenu.dialogItem(
                                    "Edit",
                                    <IncludeRoleDialog
                                        isTeamConstrainedToASpace={!!this.state.team.SpaceId}
                                        spaces={this.state.spaces}
                                        roles={this.state.userRoles}
                                        loadScopedUserRoles={this.fetchAvailableScopesForSpace}
                                        scopedUserRole={item}
                                        isOnlySpaceManagerRoleOnSpaceManagerTeam={this.isOnlySpaceManagerRoleOnSpaceManagerTeam(item)}
                                        restrictToSpaceId={item.SpaceId}
                                        onSave={(value: IncludeRoleDialogResult) => this.handleScopedUserRoleChanged(item, value)}
                                    />
                                ));

                                spaceOverflowMenuItems.push(this.addDeleteScopedUserRoleMenuItem(item));
                                spaceOverflowMenuItems.push(this.addAuditScopedUserRoleMenuItem(item));
                                const spaceSubtitle = <span className={cn(teamEditStyles.cardSubTitle, teamEditStyles.cardSubTitleWithOverflowMenu)}>{this.summaryForScopedUserRole(item)}</span>;
                                const spaceTitle = <div className={teamEditStyles.cardTitleContainer}>
                                    <span className={`${formSectionTitleClass} ${teamEditStyles.cardTitle}`}>{userRole.Name}</span>
                                    {spaceSubtitle}
                                    <div className={teamEditStyles.overflowMenuActions}>
                                        <OverflowMenu menuItems={spaceOverflowMenuItems} />
                                    </div>
                                </div>;
                                return <Card
                                    key={index} // Can't use scopedUserRole id as the key, because new scoped user roles don't have ids yet
                                    className={cn(formSectionClass, teamEditStyles.formExpander)}
                                    expanded={false}>
                                    <CardTitle
                                        title={spaceTitle}
                                        onClick={null}
                                        showExpandableButton={false}
                                    />
                                </Card>;
                            }}
                            onRowSummary={(item: SpaceResource) => {
                                return Summary.summary(item.Name);
                            }}
                            onRowHelp={(item: SpaceResource) => {
                                return null;
                            }}
                            onRowOverflowMenuItems={(spaceId: string | null) => {
                                const spaceOverflowActions: any = [];
                                spaceOverflowActions.push(OverflowMenu.dialogItem(
                                    "Include User Role",
                                    <IncludeRoleDialog
                                        isTeamConstrainedToASpace={!!this.state.team.SpaceId}
                                        spaces={this.state.spaces}
                                        roles={this.state.userRoles}
                                        loadScopedUserRoles={this.fetchAvailableScopesForSpace}
                                        scopedUserRole={null}
                                        isOnlySpaceManagerRoleOnSpaceManagerTeam={false}
                                        restrictToSpaceId={spaceId}
                                        onSave={(value: IncludeRoleDialogResult) => this.handleScopedUserRoleAdded(value)}
                                    />
                                ));
                                return spaceOverflowActions;
                            }}
                        />
                    </TabItem>
                    <TabItem label="Settings" value="settings">
                        <FormSection
                            title="Name"
                            help={"Enter a name for your team."}
                            includeBorder={true}>
                            <Text
                                value={this.state.model.name}
                                onChange={name => this.setModelState({ name })}
                                label="Name"
                                validate={required("Please enter a team name")}
                                error={this.getFieldError("name")}
                                autoFocus={true}
                            />
                            <Note>A short, memorable, unique name for this team. eg. ACME Project Contributors.</Note>
                        </FormSection>
                    </TabItem>
                </TabContainer>
            </div>}
        </FormPaperLayout>;
    }

    addTeamClassificationChip() {
        const spaceName = this.state.isSystem ? "" : this.state.spaces.find(s => s.Id === client.spaceId).Name;
        const desc = this.state.isSystem ? "This team is visible across all spaces" : `This team is only visible within the current space (${spaceName})`;
        return <Chip description={desc}>{this.state.isSystem ? "System Team" : "Space Team"}</Chip>;
    }

    addDeleteScopedUserRoleMenuItem(item: ScopedUserRoleModel): OverflowMenuGenericItem | OverflowMenuDisabledItem {
        return this.isOnlySpaceManagerRoleOnSpaceManagerTeam(item)
            ? OverflowMenu.disabledItem(
                "Delete",
                "You cannot delete the last Space Manager configuration"
            )
            : OverflowMenu.item(
                "Delete",
                () => {
                    this.setState(state => {
                        return {
                            model: {
                                ...state.model,
                                scopedUserRoles: state.model.scopedUserRoles.filter(sur => sur !== item)
                            }
                        };
                    });
                });
    }

    addAuditScopedUserRoleMenuItem(item: ScopedUserRoleModel): OverflowMenuNavLink {
        return isExistingScopedUserRole(item)
            ? OverflowMenu.navItem("Audit Trail",
                routeLinks.configuration.eventsRegardingScopedUserRole(item), null, {
                    permission: Permission.EventView,
                    wildcard: true
                })
            : null;
    }

    summaryForScopedUserRole(item: ScopedUserRoleModel): JSX.Element {
        const scopes = item.SpaceId ? this.state.availableScopes[item.SpaceId] : null;
        if (!scopes) {
            if (item.SpaceId) {
                throw new Error(`Available scopes for space ${item.SpaceId} have not been loaded`);
            }

            return null;
        }

        const projectGroups = projectGroupChipList(scopes.projectGroups, item.ProjectGroupIds);
        const projects = projectChipList(scopes.projects, item.ProjectIds);
        const environments = environmentChipList(scopes.environments, item.EnvironmentIds);
        let tenants: JSX.Element[] = [];
        if (this.props.isMultiTenancyEnabled) {
            tenants = tenantChipList(scopes.tenants, item.TenantIds);
        }

        const projectGroupsElement = this.selectedProjectGroupsElement(projectGroups);
        const projectsElement = this.selectedProjectsElement(projects);
        const environmentsElement = this.selectedEnvironmentElement(environments);
        const tenantsElement = this.selectedTenantsElement(tenants);

        const notificationElements: JSX.Element[] = [];
        if (projectGroupsElement) {
            notificationElements.push(projectGroupsElement);
        }
        if (projectsElement) {
            notificationElements.push(projectsElement);
        }
        if (environmentsElement) {
            notificationElements.push(environmentsElement);
        }
        if (tenantsElement) {
            notificationElements.push(tenantsElement);
        }

        const notificationSummary = notificationElements
            .reduce((accu: any, elem) => {
                return accu === null ? [elem] : [...accu, " and ", elem];
            }, null);

        return (
            <span>
                Team has permissions for {notificationSummary}.
            </span>
        );
    }

    selectedProjectGroupsElement(selectedProjectGroups: JSX.Element[]) {
        return selectedProjectGroups.length > 0
            ? <span key={"projectGroups"}>{buildValueList(selectedProjectGroups)} project groups</span>
            : <span key={"projectGroups"} className={teamEditStyles.noScoping}>all project groups</span>;
    }

    selectedProjectsElement(selectedProjects: JSX.Element[]) {
        return selectedProjects.length > 0
            ? <span key={"projects"}>{buildValueList(selectedProjects)} projects</span>
            : <span key={"projects"} className={teamEditStyles.noScoping}>all projects</span>;
    }

    selectedEnvironmentElement(selectedEnvironments: JSX.Element[]) {
        return selectedEnvironments.length > 0
            ? <span key={"environment"}>{buildValueList(selectedEnvironments)} environments</span>
            : <span key={"environment"} className={teamEditStyles.noScoping}>all environments</span>;
    }

    selectedTenantsElement(selectedTenants: JSX.Element[]) {
        return selectedTenants.length > 0
            ? <span key={"tenants"}>{buildValueList(selectedTenants)} tenants</span>
            : <span key={"tenants"} className={teamEditStyles.noScoping}>all tenants</span>;
    }

    buildModel(team: TeamResource, scopedUserRoles: ScopedUserRoleResource[]): TeamEditModel {
        if (team) {
            return {
                name: team.Name,
                memberUserIds: team.MemberUserIds,
                externalSecurityGroups: team.ExternalSecurityGroups,
                scopedUserRoles
            };
        }
        return {
            name: "",
            memberUserIds: [],
            scopedUserRoles: [],
            externalSecurityGroups: []
        };
    }

    handleDeleteConfirm = async () => {
        await repository.Teams.del(this.state.team);
        this.setState(state => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true
            };
        });
        return true;
    }

    handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            let team: TeamResource = {
                ...this.state.team,
                Name: this.state.model.name,
                MemberUserIds: this.state.model.memberUserIds,
                ExternalSecurityGroups: this.state.model.externalSecurityGroups,
            };
            team.SpaceId = this.state.isSystem ? null : client.spaceId;
            team = await repository.Teams.save(team);

            const scopedUserRoles = await Promise.all(this.state.model.scopedUserRoles.map(sur =>
                isExistingScopedUserRole(sur)
                    ? repository.ScopedUserRoles.modify(sur)
                    : repository.ScopedUserRoles.create(sur)
            ));

            const scopedUserRolesToRemove = this.state.cleanModel.scopedUserRoles
                .filter<ScopedUserRoleResource>(isExistingScopedUserRole)
                .filter(x => !this.state.model.scopedUserRoles.some(y => isExistingScopedUserRole(y) && y.Id === x.Id));
            await Promise.all(scopedUserRolesToRemove.map(sur => repository.ScopedUserRoles.del(sur)));

            this.setState({
                team,
                model: this.buildModel(team, scopedUserRoles),
                cleanModel: this.buildModel(team, scopedUserRoles)
            });
        });
    }

    canBeDeleted() {
        return this.state.team && this.state.team.CanBeDeleted;
    }

    canChangeMembers() {
        return this.state.team ? this.state.team.CanChangeMembers : true;
    }

    handleMemberRemoved = (member: MemberTypes) => {
        if (isUser(member)) {
            this.setState(state => ({
                model: {
                    ...state.model,
                    memberUserIds: state.model.memberUserIds.filter(m => m !== member.Id)
                }
            }));
        } else {
            this.setState(state => ({
                model: {
                    ...state.model,
                    externalSecurityGroups: state.model.externalSecurityGroups.filter(g => g.Id !== member.Id)
                }
            }));
        }
    }

    handleMemberAdded = (userIds: string[]) => {
        this.setState(state => ({
            model: {
                ...state.model,
                memberUserIds: uniq([...state.model.memberUserIds, ...userIds])
            }
        }));
        return true;
    }

    handleExternalRoleAdded = (roleId: string, displayName: string) => {
        const newGroup = { Id: roleId, DisplayName: displayName, DisplayIdAndName: true };
        this.setState(state => ({
            model: {
                ...state.model,
                externalSecurityGroups: [newGroup].concat(state.model.externalSecurityGroups)
            }
        }));
        return true;
    }

    handleExternalGroupsAdded = (groups: NamedReferenceItem[]) => {
        this.setState(state => ({
            model: {
                ...state.model,
                externalSecurityGroups: state.model.externalSecurityGroups.filter(existingGroup => !groups.find(newGroup => newGroup.Id === existingGroup.Id)).concat(groups)
            }
        }));
        return true;
    }

    renderMembers = () => {
        const members = sortBy(this.state.users.filter(ur => this.state.model.memberUserIds.indexOf(ur.Id) !== -1), t => t.DisplayName);
        const groups = sortBy(this.state.model.externalSecurityGroups, g => g.DisplayName);

        const actions: any = [];
        if (this.canChangeMembers()) {
            actions.push(<OpenDialogButton label="Add Member"><AddMember users={this.state.users}
                onSelected={this.handleMemberAdded} /></OpenDialogButton>);

            this.state.externalSecurityGroupProviders
                .filter(group => !group.IsRoleBased && group.SupportsGroupLookup)
                .forEach(group => actions.push(<OpenDialogButton label={`Add ${group.Name} Group`}>
                    <AddExternalGroup securityGroupProvider={group}
                        onSelected={this.handleExternalGroupsAdded} /></OpenDialogButton>));

            const hasRoleBasedGroupProviders = !!this.state.externalSecurityGroupProviders.find(p => p.IsRoleBased);
            if (this.canChangeMembers() && hasRoleBasedGroupProviders) {
                actions.push(<OpenDialogButton label="Add External Group/Role"><AddExternalRole
                    onSelected={this.handleExternalRoleAdded} /></OpenDialogButton>);
            }
        }

        return <UserList
            listActions={actions}
            data={[].concat(groups).concat(members)}
            onRow={this.renderMember}
            onRemoveRow={this.canChangeMembers() ? (member) => this.handleMemberRemoved(member) : null} />;
    }

    renderMember(member: MemberTypes) {
        const image = isUser(member)
            ? <Avatar avatarLink={member && member.Links && member.Links.Avatar}
                isService={member && member.IsService}
                size={24}
            />
            : <img src={workTeamImage} className={teamEditStyles.groupImage} alt={member.DisplayIdAndName ? "Role" : "Security group"} />;

        const name = isUser(member) ?
            nameWithUsername(member) :
            namedGroup(member);

        const nameLink = member && member.Id && member.Id.toLowerCase().startsWith("users-") // only internal link to Octopus controlled user accounts
            ? <InternalLink to={routeLinks.configuration.user(member.Id)}>{name}</InternalLink>
            : name;

        return <div className={teamEditStyles.user}>
            {image}
            <span className={teamEditStyles.userName}>
                {nameLink}
            </span>
        </div>;
    }

    membersSummary = () => {
        if (this.state.model.memberUserIds.length === 0 && this.state.model.externalSecurityGroups.length === 0) {
            return Summary.placeholder("No members in this team");
        }

        const members = sortBy<UserResource>(this.state.users.filter(ur => this.state.model.memberUserIds.includes(ur.Id)), t => t.DisplayName)
            .map(u => <UserChip user={u} key={u.Id} />);
        const groups = sortBy<NamedReferenceItem>(this.state.model.externalSecurityGroups, g => g.DisplayName)
            .map(g => <ExternalSecurityGroupChip group={g} key={g.Id} />);
        return Summary.summary(<span>{groups.concat(members)}</span>);
    }

    private handleScopedUserRoleChanged = async (scopedUserRole: ScopedUserRoleModel, scopedUserRoleChanges: IncludeRoleDialogResult) => {
        const additionalAvailableScopes = await this.getAdditionalAvailableScopes(scopedUserRoleChanges.spaceId);
        this.setState(prev => {
            const newScopedUserRoles = prev.model.scopedUserRoles.map(r => {
                if (r !== scopedUserRole) {
                    return r;
                }
                const updatedScopedUserRole: ScopedUserRoleModel = {
                    ...r,
                    ...this.convertScopesUserRoleChangesToNewResource(scopedUserRoleChanges)
                };
                return updatedScopedUserRole;
            });

            return {
                model: {
                    ...prev.model,
                    scopedUserRoles: newScopedUserRoles,
                    availableScopes: this.addAdditionalAvailableScopes(prev.availableScopes, additionalAvailableScopes)
                }
            };
        });
    }

    private isOnlySpaceManagerRoleOnSpaceManagerTeam(scopedUserRole: ScopedUserRoleModel): boolean {
        const isOnlySpaceManagerRole = this.state.model.scopedUserRoles.filter(sur => isSpaceManagerRole(sur)).length === 1;

        return this.state.team.Id.startsWith(TeamConstants.SpaceManagersTeamIdPrefix)
            && isSpaceManagerRole(scopedUserRole) && isOnlySpaceManagerRole;

        function isSpaceManagerRole(s: ScopedUserRoleModel): boolean {
            return s.UserRoleId === UserRoleConstants.SpaceManagerRole;
        }
    }

    private handleScopedUserRoleAdded = async (newScopedUserRoleDefinition: IncludeRoleDialogResult) => {
        const additionalAvailableScopes = await this.getAdditionalAvailableScopes(newScopedUserRoleDefinition.spaceId);
        this.setState(prev => {
            const newResource = this.convertScopesUserRoleChangesToNewResource(newScopedUserRoleDefinition);
            return {
                model: {
                    ...prev.model,
                    scopedUserRoles: [...prev.model.scopedUserRoles, newResource],
                    availableScopes: this.addAdditionalAvailableScopes(prev.availableScopes, additionalAvailableScopes)
                }
            };
        });
    }

    private convertScopesUserRoleChangesToNewResource(scopedUserRoleChanges: IncludeRoleDialogResult): NewScopedUserRoleResource {
        return {
            SpaceId: scopedUserRoleChanges.spaceId,
            UserRoleId: scopedUserRoleChanges.userRoleId,
            ProjectGroupIds: scopedUserRoleChanges.projectGroupIds,
            TenantIds: scopedUserRoleChanges.tenantIds,
            ProjectIds: scopedUserRoleChanges.projectIds,
            EnvironmentIds: scopedUserRoleChanges.environmentIds,
            TeamId: this.state.team.Id
        };
    }

    private addAdditionalAvailableScopes(existingScopes: Record<string, AvailableRoleScopes>, newScopes: { scopes: AvailableRoleScopes, spaceId: string } | null): Record<string, AvailableRoleScopes> {
        if (!newScopes) {
            return existingScopes;
        }
        return { ...existingScopes, [newScopes.spaceId]: newScopes.scopes };
    }

    private async getAdditionalAvailableScopes(spaceId: string | null): Promise<{ scopes: AvailableRoleScopes, spaceId: string } | null> {
        if (!spaceId) {
            return null;
        }
        return { scopes: await this.fetchAvailableScopesForSpace(spaceId), spaceId };
    }

    private fetchAvailableScopesForSpace = async (spaceId: string): Promise<AvailableRoleScopes> => {
        const loadedAvailableScopes = this.state.availableScopes[spaceId];
        if (loadedAvailableScopes) {
            return loadedAvailableScopes;
        }

        const scopes = await loadAvailableScopesForSpace(spaceId);
        this.setState(prev => ({ availableScopes: { ...prev.availableScopes, [spaceId]: scopes } }));
        return scopes;
    }
}

function isExistingScopedUserRole(scopedUserRole: ScopedUserRoleModel): scopedUserRole is ScopedUserRoleResource {
    return !!(scopedUserRole as ScopedUserRoleResource).Links;
}

function nameWithUsername(u: UserResource) {
    return !!u && u.DisplayName !== u.Username ? `${u.DisplayName} (${u.Username})` : u.DisplayName;
}

function namedGroup(u: NamedReferenceItem) {
    return u.DisplayIdAndName ? `${u.DisplayName} (${u.Id})` : u.DisplayName;
}

const mapStateToProps = (state: GlobalState, props: TeamEditProps): ConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace ? state.configurationArea.currentSpace.isMultiTenancyEnabled : false
    };
};

export default connect(mapStateToProps)(TeamEdit);
