import * as React from "react";
import { Card, CardTitle } from "material-ui/Card";
const styles = require("./style.less");
import { FormSectionBase, FormSectionBaseProps } from "components/form/Sections/FormSectionBase";
import { formSectionClass } from "uiTestClasses";
import classnames = require("classnames");

interface FormSectionProps extends FormSectionBaseProps {
    title: React.ReactNode;
    help?: React.ReactNode;
    includeBorder?: boolean;
}

//todo: mattr: rename this to NonExpandableFormSection and add new FormSection component that switches between this and ExpandableFormSection
export default class FormSection extends FormSectionBase<FormSectionProps> {
    constructor(props: FormSectionProps) {
        super(props);
    }

    render() {
        return <Card
            className={classnames(formSectionClass, styles.formSection, this.props.includeBorder ? styles.formBorder : null)}
            expanded={true}>
            <CardTitle title={this.title(this.props.title, this.help(this.props.help))} />
            {this.body(styles.cardMedia, false)}
            {this.moreInformation()}
        </Card>;
    }
}
