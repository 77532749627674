import * as React from "react";
import {repository} from "clientInstance";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import {ActionTemplateResource} from "client/resources/actionTemplateResource";
import {Callout, CalloutType} from "components/Callout/Callout";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {CommunityActionTemplateResource} from "client/resources/communityActionTemplateResource";
import Note from "components/form/Note/Note";
import routeLinks from "../../routeLinks";

interface CommunityTemplateInstallationProps {
    communityTemplateId: string;
    detailsLink: string;
    title: string;

    saveDone(actionTemplate: ActionTemplateResource): void;
}

interface CommunityTemplateInstallationState extends DataBaseComponentState {
    communityTemplate?: CommunityActionTemplateResource;
}

class CommunityTemplateInstallation extends DataBaseComponent<CommunityTemplateInstallationProps, CommunityTemplateInstallationState> {
    constructor(props: CommunityTemplateInstallationProps) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const communityTemplate = await repository.CommunityActionTemplates.get(this.props.communityTemplateId);
            this.setState({communityTemplate});
        });
    }

    async save() {
        return await this.doBusyTask(async () => {
            const actionTemplate = await repository.CommunityActionTemplates.install(this.state.communityTemplate);
            await this.props.saveDone(actionTemplate);
        });
    }

    render() {
        const body = this.state.communityTemplate &&
            <div>
                <h4><InternalLink to={this.props.detailsLink}>{this.state.communityTemplate.Name}</InternalLink></h4>
                <Note>
                    This is a community contributed step that will be installed into your <InternalLink
                    to={routeLinks.library.stepTemplates.root}>step templates</InternalLink> library.
                    Learn more about <ExternalLink href="CommunityContributedStepTemplates">community steps
                    </ExternalLink>.
                </Note>
            </div>;

        return <SaveDialogLayout title={this.props.title}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={() => this.save()}>
            {body}
        </SaveDialogLayout>;
    }
}

export default CommunityTemplateInstallation;