import * as React from "react";
import {DataContext, TypeMetadata, PropertyMetadata} from "client/resources/dynamicFormResources";
import { ControlType} from "client/resources";
import {Checkbox, ExpandableFormSection, FormSection, FormSectionHeading, Sensitive, Summary, Text, SummaryNodeProps} from "../form";
import { VariableLookupText } from "../form/VariableLookupText";
import { Options } from "../form/Select/Options";
import { required } from "../form/Validators";
import MetadataTypeHelpers from "./MetadataTypeHelpers";
import {BoundSelect, default as Select, Item} from "../form/Select/Select";
import { BoundStringCheckbox } from "components/form/Checkbox/StringCheckbox";
import { BoundSensitive } from "components/form/Sensitive/Sensitive";
import { BoundFieldProps } from "../Actions/pluginRegistry";
import Markdown from "components/Markdown";
import Section from "../Section";
import CopyToClipboard from "components/CopyToClipboardButton/CopyToClipboardButton";
import { string } from "prop-types";
const styles = require("./style.less");

export interface DynamicFormProps {
    description?: string;
    types?: TypeMetadata[];
    values?: DataContext;
    onChange?: (context: DataContext) => void;
    isBindable?: boolean;
    getBoundFieldProps?: () => BoundFieldProps;
    customControlTypes?: DynamicFormCustomControlType[];
}

export class DynamicFormCustomControlType {
    name: string;
    summaryNodeProps: (value: any) => React.ComponentType<SummaryNodeProps>;
    readonlyValue: (value: any) => string;
    getInputControl: (property: PropertyMetadata, dataContext: DataContext, value: any) => JSX.Element;
}

interface EitherProps {
    flag: any;
    renderLeft: () => React.ReactElement<any>;
    renderRight: () => React.ReactElement<any>;
}
const Either: React.SFC<EitherProps> = ({flag, renderLeft, renderRight}) => (!!flag === true) ? renderRight() : renderLeft();

const DynamicForm: React.StatelessComponent<DynamicFormProps> = props => {
    const noValueMessage: string = "No value provided";

    const getControlType = (property: PropertyMetadata): ControlType => {
        if (property.DisplayInfo.ListApi || property.DisplayInfo.Options) {
            return ControlType.Select;
        }

        switch (property.Type) {
            case "string":
            case "text":
            case "long":
            case "long?":
            case "int":
            case "int?":
                return ControlType.SingleLineText;
            case "bool":
            case "bool?":
            case "boolean":
                return ControlType.Checkbox;
            case "string[]":
            case "raw_map":
            case "raw_list":
                return ControlType.MultiLineText;
            case "SensitiveValue":
                return ControlType.Sensitive;
        }

        if (props.customControlTypes && props.customControlTypes.find(c => c.name === property.Type)) {
            return ControlType.Custom;
        }
    };

    const getBooleanDisplayValue = (value: any): string => {
        return value === null || value === false ? "Disabled" : "Enabled";
    };

    const getSelectDisplayValue = (property: PropertyMetadata, value: any): string => {
        if (value === null || value === "") {
            return noValueMessage;
        }

        if (property.DisplayInfo.Options) {
            const objectKeys = Object.getOwnPropertyNames(property.DisplayInfo.Options.Values);
            const options =  objectKeys.map(key => ({ value: key.toString(), text: property.DisplayInfo.Options.Values[key].toString() }));
            const selectedOption = options.find(x => x.value === value);
            return selectedOption ? selectedOption.text : value;
        }
        return value;
    };

    const createSummary = (property: PropertyMetadata, value: any) => {
        switch (property.Type) {
            case "bool":
            case "boolean":
            case "bool?":
                return Summary.summary(getBooleanDisplayValue(value));
            case "string":
            case "text":
            case "long":
            case "long?":
            case "int":
            case "int?":
            case "raw_map":
            case "raw_list":
                return value === null || value === "" ? Summary.placeholder(noValueMessage) : Summary.summary(value);
            case "string[]":
                return Summary.summary(value && value.join ? value.join(", ") : value);
            case "SensitiveValue":
                return !value || value.HasValue === false ? Summary.placeholder(noValueMessage) : Summary.summary("Value set");
        }

        if (props.customControlTypes) {
            const customControlType = props.customControlTypes.find(c => c.name === property.Type);
            if (customControlType) {
                return customControlType.summaryNodeProps(value);
            }
        }
    };

    const getReadonlyValue = (property: PropertyMetadata, value: any) => {
        switch (property.Type) {
            case "bool":
            case "boolean":
            case "bool?":
                return getBooleanDisplayValue(value);
            case "string":
            case "text":
            case "long":
            case "long?":
            case "int":
            case "int?":
            case "string[]":
            case "raw_map":
            case "raw_list":
                return value === null || value === "" ? noValueMessage : value.toString();
            case "SensitiveValue":
                return !value || value.HasValue === false ? noValueMessage : "Value set";
        }

        if (props.customControlTypes) {
            const customControlType = props.customControlTypes.find(c => c.name === property.Type);
            if (customControlType) {
                return customControlType.readonlyValue(value);
            }
        }
    };

    const getSelectOptions = (property: PropertyMetadata): Options => {
        if (property.DisplayInfo.Options && property.DisplayInfo.Options.Values) {
            const objectKeys = Object.getOwnPropertyNames(property.DisplayInfo.Options.Values);
            const options =  objectKeys.map(key => ({ value: key.toString(), text: property.DisplayInfo.Options.Values[key].toString() }));
            return options;
        } else if (property.DisplayInfo.ListApi) {
            return []; // TODO: load from api
        } else {
            return [];
        }
    };

    const getInputControl = (property: PropertyMetadata, dataContext: DataContext, getBoundFieldProps?: () => BoundFieldProps) => {
        let value = dataContext[property.Name];
        if (value && property.Type === "string[]") {
            // We might have a string value that was changed to an array, so don't
            // assume join is a valid method.
            if (value.join) {
                value = value.join("\n");
            }
        }

        if (property.DisplayInfo.ReadOnly) {
            const displayValue = getReadonlyValue(property, value);
            return <span>{displayValue}</span>;
        }

        const inputType = getControlType(property);
        const formProps = {
            label: property.DisplayInfo.Label,
            value: value || "",
            onChange: (newValue: any) => onChange(property, dataContext, newValue)
        };
        const boundFieldProps: BoundFieldProps = getBoundFieldProps ? getBoundFieldProps() : {};

        switch (inputType) {
            case ControlType.SingleLineText:
                return <Either flag={props.isBindable}
                    renderLeft={() => <Text id={property.Name} {...formProps} />}
                    renderRight={() =>  <VariableLookupText id={property.Name} {...formProps} {...boundFieldProps}/>}
                />;
            case ControlType.MultiLineText:
                return <Either flag={props.isBindable}
                    renderLeft={() => <Text id={property.Name} {...formProps}   multiLine={true} />}
                    renderRight={() =>  <VariableLookupText id={property.Name} {...formProps}  multiLine={true} {...boundFieldProps}/>}
                />;
            case ControlType.Select:
                return (
                    <Either flag={props.isBindable}
                            renderLeft={() => (
                                <Select items={getSelectOptions(property)}
                                        allowClear={false}
                                        {...formProps}/>
                            )}
                            renderRight={() => (
                                <BoundSelect items={getSelectOptions(property)}
                                             allowClear={false}
                                             resetValue={""}
                                             {...formProps}
                                             {...boundFieldProps}/>
                            )}
                />);
            case ControlType.Checkbox: {
                return <Either flag={props.isBindable}
                               renderLeft={() => <Checkbox {...formProps} />}
                               renderRight={() => <BoundStringCheckbox resetValue={""}{...formProps}{...boundFieldProps} />}
                        />;
            }
            case ControlType.Sensitive:
                return <Either flag={props.isBindable}
                               renderLeft={() => <Sensitive {...formProps} />}
                               renderRight={() => (
                                    <BoundSensitive label={property.DisplayInfo.Label}
                                                    resetValue={""}
                                                    {...formProps}
                                                    {...boundFieldProps} />
                                )}
                        />;
            case ControlType.Custom:
                if (props.customControlTypes) {
                    const customControlType = props.customControlTypes.find(c => c.name === property.Type);
                    if (customControlType) {
                        return customControlType.getInputControl(property, dataContext, value);
                    }
                }
            default:
                return <Text id={property.Name}
                            {...formProps} />;
        }
    };

    const renderProperty = (property: PropertyMetadata, dataContext: DataContext, parentPropertyName: string): React.ReactNode => {
        if (MetadataTypeHelpers.isCompositeType(property) &&
            (!props.customControlTypes || !props.customControlTypes.find(c => c.name === property.Type))) {
            const compositeType = props.types.filter(t => t.Name === property.Type)[0];
            return renderSection(compositeType, dataContext[property.Name], property.DisplayInfo.Label, property.Name);
        }
        const controlType = getControlType(property);
        const selectOptions = getSelectOptions(property);
        const description = property.DisplayInfo && property.DisplayInfo.Description
                                ? <span className={styles.markdownDescriptionContainer}><Markdown markup={property.DisplayInfo.Description} /></span>
                                : `Provide a value for ${property.DisplayInfo.Label}`;

        const controlToRender = getInputControl(property, dataContext, props.getBoundFieldProps);
        return <ExpandableFormSection errorKey={(parentPropertyName ? `${parentPropertyName}.${property.Name}` : property.Name)}
                    title={property.DisplayInfo.Label}
                    help={description}
                    key={(parentPropertyName ? `${parentPropertyName}.${property.Name}` : property.Name)}
                    isExpandedByDefault={false}
                    summary={createSummary(property, dataContext[property.Name])}>
                        {controlToRender}{property.DisplayInfo.ShowCopyToClipboard && <CopyToClipboard value={dataContext[property.Name]}/>}
                </ExpandableFormSection>;
    };

    // Sorting properties by simple types first, then composite types
    const sortPropertiesByCompositeType = (left: PropertyMetadata, right: PropertyMetadata) => {
        const leftIsCompositeType = MetadataTypeHelpers.isCompositeType(left);
        const rightIsCompositeType = MetadataTypeHelpers.isCompositeType(right);
        return (leftIsCompositeType === rightIsCompositeType) ? 0 : leftIsCompositeType ? 1 : -1;
    };

    const renderSection = (compositeType: TypeMetadata, dataContext: DataContext, sectionName: string, parentPropertyName: string) => {
        const types = compositeType && compositeType.Properties && compositeType.Properties.sort(sortPropertiesByCompositeType).map((t) => renderProperty(t, dataContext, parentPropertyName));
        const sectionHeading = sectionName && <FormSectionHeading title={sectionName} key={sectionName}/>;
        return <div key={sectionName}>
             {sectionHeading}
             <div>
                {types}
             </div>
         </div>;
    };

    const onChange = (property: PropertyMetadata, dataContext: DataContext, value: any) => {
        let boundValue = value;
        if (property.Type === "string[]") {
            boundValue = value.split("\n");
        }
        // mutate state and trigger UI refresh
        // it would be really nice to replace this
        dataContext[property.Name] = boundValue;
        if (props.onChange) {
            props.onChange(props.values);
        }
    };

    if (props && props.types && props.types.length > 0) {

        return <div>
            {props.description && <Section className={styles.markdownNote}><Markdown markup={props.description} /></Section>}
            {renderSection(props.types[0], props.values, null, null)}
        </div>;
    } else {
        console.error("no types provided");
    }

    return null;
};

export default DynamicForm;
