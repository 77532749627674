import * as React from "react";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import endpointRegistry, { PackagingRegistration, CategorizedPackagingRegistration, CategoryDefinition } from "./Registry/packagingRegistry";
import * as cn from "classnames";
import { ActiveItem } from "components/ActiveItem";
import { Section, SectionHeadingType } from "components/Section/Section";
import { withRegistrations, RegistrationProps } from "./Registry/withRegistrations";
import { orderBy } from "utils/orderBy";
import { compose } from "recompose";
import { white } from "theme/colors";

const styles = require("./PackagingSelector.less");

interface PackagingRegistrationCallback {
    onSelect: (registration: PackagingRegistration) => void;
}

interface CategorizedPackagingRegistrationCallback {
    onSelect: (registration: CategorizedPackagingRegistration) => void;
}

interface ChangeCategoryCallback {
    onChangeCategory: (category: CategoryDefinition) => void;
}

interface PackagingCardGroupProps extends CategorizedPackagingRegistrationCallback {
    endpoints: CategorizedPackagingRegistration[];
    className?: string;
    category: CategoryDefinition;
}

const PackagingCardGroup: React.SFC<PackagingCardGroupProps> = ({ endpoints = [], className, onSelect, category }) => {
    const ordered = orderBy(endpoints, ["displayOrder", "name"]);

    return (
        <div className={cn(styles.cardGroup, className)}>
            {ordered.map(x => {
                const element = x.renderCard({
                    registration: x,
                    category,
                });
                return React.cloneElement(element, { key: x.name });
            })}
        </div>
    );
};

export const PackagingCardGroupTitle: React.SFC<{ className?: string }> = ({ className, children }) => (
    <div className={cn(styles.groupHeading, className)}>
        {children}
    </div>
);

export const PackagingCardGroupHelp: React.SFC<{ className?: string }> = ({ className, children }) => (
    <div className={cn(styles.groupHelp, className)}>
        {children}
    </div>
);

type ActiveItemButtonProps = { active: boolean, label: string, onClick?: () => void, style?: object, icon?: any; };

const ActiveItemButton: React.SFC<ActiveItemButtonProps> = ({ onClick, label, active, style }) => {
    const labelProps = active ? { color: white } : undefined;
    return (
        <ActionButton
            type={ActionButtonType.Category}
            labelProps={labelProps}
            label={label}
            onClick={onClick}
            className={cn({ [styles.active]: active })}
            style={style}
        />
    );
};

const PackagingActions: React.SFC<{ className?: string }> = ({ children, className }) => (
    <div className={cn(styles.actions, className)}>{children}</div>
);

type PackagingRegistrationViewProps = RegistrationProps & PackagingRegistrationCallback & ChangeCategoryCallback & {
    activeItem?: CategoryDefinition;
};

class PackagingRegistrationsView extends React.Component<PackagingRegistrationViewProps & HeadingProps> {
    render() {
        const { registrations, onChangeCategory, activeItem, categories, title, heading, onSelect } = this.props;

        const actions: React.ReactNode[] = orderBy([
            ...registrations.filter(x => !endpointRegistry.isCategorizedPackaging(x)).map(x => ({ label: x.name, displayOrder: x.displayOrder, action: () => onSelect(x) })),
            ...Object.values(categories).map(x => ({ label: x.category.category, displayOrder: x.category.displayOrder, action: () => onChangeCategory(x.category) }))
        ], ["displayOrder", "label"])
            .map(x => (<ActiveItemButton label={x.label} onClick={x.action} key={x.label} active={activeItem && activeItem.category === x.label} />));

        return (
            <div className={styles.outerContainer}>
                <Section headingType={SectionHeadingType.Heading2} sectionHeader={title} />
                <Section className={styles.headingContainer}>{heading}</Section>
                <PackagingActions children={actions} className={styles.paperActions} />
                <Section bodyClassName={styles.container}>
                    {activeItem && <PackagingCardGroupTitle children={activeItem.title} className={styles.activeItemGroupHeading} />}
                    {activeItem && !!activeItem.help && <PackagingCardGroupHelp children={activeItem.help} />}
                    {activeItem && (
                        <PackagingCardGroup
                            endpoints={(categories[activeItem.category] && categories[activeItem.category].registrations)}
                            onSelect={onSelect}
                            category={(categories[activeItem.category] && categories[activeItem.category].category)}
                        />
                    )}
                </Section>
            </div>);
    }
}

interface HeadingProps {
    title: string;
    heading: React.ReactNode;
}

//Generic components aren't supported in our current version of TypeScript so we extend as a workaround.
class PackagingViewSelector extends ActiveItem<CategoryDefinition> { }

type PackagingSelectorDispatchProps = PackagingRegistrationCallback & ChangeCategoryCallback;
type PackagingSelectorProps = HeadingProps & RegistrationProps & PackagingSelectorDispatchProps & { initial?: CategoryDefinition };

export const PackagingSelector: React.SFC<PackagingSelectorProps> = ({ registrations, categories, title, heading, ...rest }) => {
    return <PackagingViewSelector render={
        ({ onSelect, activeItem }) => (
            <PackagingRegistrationsView
                activeItem={activeItem}
                onChangeCategory={(category) => {
                    if (rest.onChangeCategory) {
                        rest.onChangeCategory(category);
                    }
                    onSelect(category);
                }}
                registrations={registrations}
                categories={categories}
                title={title}
                heading={heading}
                onSelect={rest.onSelect}
            />
        )}
        initial={rest.initial}
    />;
};

const withKnownPackagingRegistrations = withRegistrations((x) => endpointRegistry.categorizePackagings(x), () => endpointRegistry.getAllRegistrations());

type EnhancedSelectorProps = HeadingProps & Partial<RegistrationProps>;

const forCommonPackaging = compose<PackagingSelectorProps, EnhancedSelectorProps>(
    withKnownPackagingRegistrations,
);

const CommonPackagingSelector = forCommonPackaging(PackagingSelector);

export default PackagingSelector;

export {
    withKnownPackagingRegistrations,
    CommonPackagingSelector
};