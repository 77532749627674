import * as React from "react";
import {repository, client} from "clientInstance";
import {RouteComponentProps} from "react-router";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import PaperLayout from "components/PaperLayout/PaperLayout";
import {
    MachineConnectionStatus,
    CommunicationStyle,
    MachineModelHealthStatus,
    TentacleDetailsResource,
    MachineResource, NewTaskResource,
} from "client/resources";
import {
    Note,
} from "components/form";
import DateFormatter from "utils/DateFormatter";
import { Callout, CalloutType } from "components/Callout/Callout";
import { Section } from "components/Section/Section";
import {ActionButton, ActionButtonType} from "components/Button/ActionButton";
import {
    HealthCheckTaskArguments, TaskName, TaskResource,
    UpdateCalamariTaskArguments
} from "client/resources/taskResource";
import {TaskState} from "client/resources/taskState";
import {DataTable} from "components/DataTable/DataTable";
import {DataTableBody} from "components/DataTable/DataTableBody";
import {DataTableRowColumn} from "components/DataTable/DataTableRowColumn";
import {DataTableRowHeaderColumn} from "components/DataTable/DataTableRowHeaderColumn";
import {DataTableRow} from "components/DataTable/DataTableRow";
import TaskLogLines from "components/TaskLogLines/TaskLogLines";
import routeLinks from "../../../../routeLinks";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import BasicRepository from "client/repositories/basicRepository";
import { connect } from "react-redux";
import EndpointsHelper from "utils/EndpointsHelper/EndpointsHelper";

interface MachineConnectionsBaseProps {
    repository: BasicRepository<MachineResource, MachineResource>;
    itemDescription: string;
    getConnectionStatus(machine: MachineResource): Promise<MachineConnectionStatus>;
}

interface MachineConnectionsParams {
    machineId: string;
}

type MachineConnectionsProps = MachineConnectionsBaseProps & RouteComponentProps<MachineConnectionsParams>;

interface MachineConnectionsState extends DataBaseComponentState {
    machineId: string;
    machine: MachineResource;
    connectionStatus: MachineConnectionStatus;
    redirectToTaskId?: string;
}

class MachineConnectionsLayoutInternal extends DataBaseComponent<MachineConnectionsProps, MachineConnectionsState> {
    constructor(props: MachineConnectionsProps) {
        super(props);
        this.state = {
            connectionStatus: null,
            machineId: this.props.match && this.props.match.params ? this.props.match.params.machineId : null,
            machine: null,
        };
    }

    async componentDidMount() {
        this.doBusyTask(async () => {
            const machine = await this.props.repository.get(this.state.machineId);
            const connectionStatus = await this.props.getConnectionStatus(machine);
            this.setState({
                machine,
                connectionStatus,
            });
        });
    }

    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={routeLinks.task(this.state.redirectToTaskId).root} push={true}/>;
        }
        const healthCheckButton = <ActionButton
            type={ActionButtonType.Primary}
            label={"Check health"}
            disabled={this.state.busy}
            onClick={() => this.performHealthCheck()}
        />;
        const communicationsSection = this.renderCommunicationStyleInfo();
        const calamariUpgradeSection = this.renderCalamariUpgradeInfo();
        const tentacleSection = this.renderTentacleInfo();
        const logsSection = this.renderLogsInfo();

        return <PaperLayout title="Connectivity"
            sectionControl={healthCheckButton}
            busy={this.state.busy}
            errors={this.state.errors}>

            {communicationsSection && <Section>
                {communicationsSection}
            </Section>}

            {calamariUpgradeSection && <Section sectionHeader="Calamari Upgrade Recommended">
                {calamariUpgradeSection}
            </Section>}

            {tentacleSection && <Section sectionHeader="Tentacle">
                {tentacleSection}
            </Section>}

            {logsSection && <Section sectionHeader="Recent Communication Logs">
                {logsSection}
            </Section>}

        </PaperLayout>;
    }

    private renderLogsInfo() {
        const connectionStatus = this.state.connectionStatus;
        if (!connectionStatus) {
            return null;
        }
        const noOp = () => {/*Blank*/};
        return <TaskLogLines lines={connectionStatus.Logs} showAdditional={noOp} />;
    }

    private renderCommunicationStyleInfo() {
        const machine = this.state.machine;
        const connectionStatus = this.state.connectionStatus;
        if (!machine || !connectionStatus) {
            return null;
        }

        if (machine.Endpoint.CommunicationStyle === CommunicationStyle.None) {
            return <Callout type={CalloutType.Success} title="Healthy">
                <p>Cloud Regions are always healthy. Hooray!</p>
            </Callout>;
        } else {
            switch (connectionStatus.Status) {
                case MachineModelHealthStatus.Healthy:
                    return <Callout type={CalloutType.Success} title="Healthy">
                        <p>The last health check completed successfully</p>
                        <Note>Last health check {DateFormatter.momentAgo(connectionStatus.LastChecked as string)}</Note>
                    </Callout>;
                case MachineModelHealthStatus.Unhealthy:
                    return <Callout type={CalloutType.Danger} title="Unhealthy">
                        <p>The last health check encountered errors</p>
                        <Note>Last health check {DateFormatter.momentAgo(connectionStatus.LastChecked as string)}</Note>
                    </Callout>;
                case MachineModelHealthStatus.HasWarnings:
                    return <Callout type={CalloutType.Warning} title="Healthy with warnings">
                        <p>The last health check encountered warnings</p>
                        <Note>Last health check {DateFormatter.momentAgo(connectionStatus.LastChecked as string)}</Note>
                    </Callout>;
                case MachineModelHealthStatus.Unavailable:
                    return <Callout type={CalloutType.Danger} title="Unavailable">
                        <Note>Last health check {DateFormatter.momentAgo(connectionStatus.LastChecked as string)}</Note>
                    </Callout>;
                case MachineModelHealthStatus.Unknown:
                    return <Callout type={CalloutType.Warning} title="Unknown">
                        <p>This {this.props.itemDescription} was just added, and a health check has not been performed.</p>
                    </Callout>;
            }
        }
    }

    private renderCalamariUpgradeInfo() {
        const machine = this.state.machine;
        if (!machine || machine.HasLatestCalamari) {
            return null;
        }

        const calamariUpgradeButton = <ActionButton
            type={ActionButtonType.Secondary}
            label={"Upgrade Calamari"}
            disabled={this.state.busy}
            onClick={() => this.performCalamariUpgrade()}
        />;
        return calamariUpgradeButton;
    }

    private renderTentacleInfo() {
        const machine = this.state.machine;
        if (!machine) {
            return null;
        }
        let tentacleDetails: TentacleDetailsResource;
        if (EndpointsHelper.isTentacle(machine.Endpoint)) {
            tentacleDetails = machine.Endpoint.TentacleVersionDetails;
        }
        if (!tentacleDetails || !tentacleDetails.Version) {
            return null;
        }
        return <div>
            {tentacleDetails.UpgradeSuggested && !tentacleDetails.UpgradeRequired &&
            <Callout type={CalloutType.Warning} title="Upgrade available" />}
            {tentacleDetails.UpgradeRequired &&
            <Callout type={CalloutType.Danger} title="Upgrade required" />}
            <DataTable>
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Current Version</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{tentacleDetails.Version}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>&nbsp;</DataTableRowHeaderColumn>
                        <DataTableRowColumn>
                            {tentacleDetails.UpgradeSuggested && <ActionButton
                                label={"Upgrade To Latest"}
                                disabled={this.state.busy}
                                onClick={() => this.upgradeTentacle()}
                            />}
                            {tentacleDetails.UpgradeLocked && <ActionButton
                                label={"Unlock Current Version"}
                                disabled={this.state.busy}
                                onClick={() => this.lockVersion(false)}
                            />}
                            {!tentacleDetails.UpgradeLocked && <div>
                                <ActionButton
                                    label={"Lock Current Version"}
                                    disabled={this.state.busy}
                                    onClick={() => this.lockVersion(true)}
                                />
                                <Note>
                                    Ensures this Tentacle does not get updated past currently installed version or get prompted when newer versions are available.
                                </Note>
                            </div>}
                        </DataTableRowColumn>
                    </DataTableRow>
                </DataTableBody>
            </DataTable>
        </div>;
    }

    private lockVersion(isToBeLocked: boolean) {
        const machine = this.state.machine;
        if (EndpointsHelper.isTentacle(machine.Endpoint)) {
            machine.Endpoint.TentacleVersionDetails.UpgradeLocked = isToBeLocked;
            this.doBusyTask(async () => {
                await this.props.repository.save(machine);
                this.setState({machine});
            });
        }
    }

    private async upgradeTentacle() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createUpgradeTentacleOnMachineTask(this.state.machine);
            this.setState({redirectToTaskId: task.Id});
        });
    }

    private async performHealthCheck() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createHealthCheckTaskForMachine(this.state.machine);
            this.setState({redirectToTaskId: task.Id});
        });
    }

    private async performCalamariUpgrade() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createUpdateCalamariOnTargetTask(this.state.machine);
            this.setState({redirectToTaskId: task.Id});
        });
    }
}

const mapStateToPropsForDeploymentTarget = (): MachineConnectionsBaseProps => {
    return {
        repository: repository.Machines,
        itemDescription: "deployment target",
        getConnectionStatus: (machine: MachineResource) => repository.Machines.getConnectionStatus(machine)
    };
};

const mapStateToPropsForWorkerMachines = (): MachineConnectionsBaseProps => {
    return {
        repository: repository.Workers,
        itemDescription: "worker machine",
        getConnectionStatus: (machine: MachineResource) => repository.Workers.getConnectionStatus(machine)
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export const DeploymentTargetConnectionsLayout = connect<{}, {}, MachineConnectionsBaseProps>(
    mapStateToPropsForDeploymentTarget,
    mapDispatchToProps
)(MachineConnectionsLayoutInternal);

export const WorkerMachineConnectionsLayout = connect<{}, {}, MachineConnectionsBaseProps>(
    mapStateToPropsForWorkerMachines,
    mapDispatchToProps
)(MachineConnectionsLayoutInternal);
