import * as React from "react";
import { flatten } from "lodash";
import { repository } from "clientInstance";
import { ScopeValues, VariableSetResource } from "client/resources/variableSetResource";
import { LibraryVariableSetResource } from "client/resources/libraryVariableSetResource";
import { ProjectResource } from "client/resources/projectResource";
import VariableDisplayer, { ValueWithSource } from "areas/variables/VariableDisplayer/VariableDisplayer";
import mergeScopeValues from "areas/variables/MergeScopeValues";
import {
    createErrorsFromOctopusError,
    DoBusyTask,
    Errors
} from "components/DataBaseComponent/DataBaseComponent";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import { ReleaseResource } from "client/resources/releaseResource";
import { CalloutType, default as Callout } from "components/Callout/Callout";
import { OctopusError } from "client/resources";
import Note from "../../../../../components/form/Note/Note";
import FormSectionHeading from "../../../../../components/form/Sections/FormSectionHeading";
import { Section } from "../../../../../components/Section/Section";
import { NothingToSeeHere } from "../../../../../components/NothingToSeeHere/NothingToSeeHere";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { convertVariableResourcesToVariablesWithSource } from "../../../../variables/convertVariableResourcesToVariablesWithSource";
import { VariableWithSource } from "../../../../variables/VariableDisplayer";
import ActionList from "components/ActionList/ActionList";
import { UpdateVariablesMessage } from "areas/projects/components/Releases/UpdateVariables/UpdateVariables";

interface VariableSnapshotProps {
    projectId: string;
    release: ReleaseResource;
    doBusyTask: DoBusyTask;
    updateVariablesRefreshKey: string;
}

interface VariableSnapshotState {
    model?: Model;
    showConfirmationDialog: boolean;
    variableSetErrors?: Errors;
    showVariables: boolean;
}

interface Model {
    readonly project: ProjectResource;
    readonly release: ReleaseResource;
    readonly projectVariables: VariableSetResource;
    readonly libraryVariableSetVariables: ReadonlyArray<VariableSetResource>;
    readonly libraryVariableSets: ReadonlyArray<LibraryVariableSetResource>;
}

export default class VariableSnapshot extends BaseComponent<VariableSnapshotProps, VariableSnapshotState> {
    constructor(props: VariableSnapshotProps) {
        super(props);
        this.state = { showConfirmationDialog: false, showVariables: false };
    }

    componentDidMount() {
        this.props.doBusyTask(async () => {
            await this.loadData(this.props.release);
        });
    }

    componentWillReceiveProps(nextProps: VariableSnapshotProps) {
        if (nextProps.updateVariablesRefreshKey !== this.props.updateVariablesRefreshKey) {
            this.props.doBusyTask(async () => {
                await this.loadData(nextProps.release);
            });
        }
    }

    render() {
        const hasVariables = this.getVariables().length > 0;
        const actions = [
            <ActionButton label={this.state.showVariables ? "Hide Snapshot" : "Show Snapshot"}
                type={ActionButtonType.Ternary}
                onClick={() => {
                    this.setState({ showVariables: !this.state.showVariables });
                }}
            />,
            <ActionButton label="Update variables"
                onClick={() => {
                    this.setState({ showConfirmationDialog: true });
                }}
            />,
        ];

        return <div>
            <FormSectionHeading title="Variable Snapshot" />
            {hasVariables ? <Section sectionHeader="">
                <Note>
                    When this release was created, a snapshot of the project variables was taken. You can overwrite the variable snapshot by re-importing the variables from the project.
                    <ActionList actions={actions} />
                </Note>
                {this.state.showVariables &&
                    <div>
                        {this.state.variableSetErrors &&
                            <Callout
                                type={CalloutType.Warning}
                                title={this.state.variableSetErrors.message}>
                                {this.state.variableSetErrors.details}
                            </Callout>}
                        <VariableDisplayer
                            availableScopes={this.availableScopes}
                            isProjectScoped={true}
                            variableSections={[this.getVariables()]}
                            doBusyTask={this.props.doBusyTask}
                        />
                    </div>}
                <ConfirmationDialog
                    title="Confirm Variable Update"
                    continueButtonLabel="Update variables"
                    continueButtonBusyLabel="Saving..."
                    open={this.state.showConfirmationDialog}
                    onClose={() => this.setState({ showConfirmationDialog: false })}
                    onContinueClick={() => this.updateVariables()}>
                    <UpdateVariablesMessage />
                </ConfirmationDialog>
            </Section> : <Section sectionHeader=""><NothingToSeeHere content={"No variable snapshot exists."} /></Section>}
        </div>;
    }

    private async loadData(release: any) {
        const project = repository.Projects.get(this.props.projectId);
        const projectVariablesPromise = this.loadProjectVariables(release);
        const libraryVariableSetVariables = await this.loadLibraryVariableSetVariables(release);
        const libraryVariableSets = await repository.LibraryVariableSets.all({ ids: libraryVariableSetVariables.map(v => v.OwnerId) });
        this.setState({
            model: {
                project: await project,
                release,
                projectVariables: await projectVariablesPromise,
                libraryVariableSetVariables,
                libraryVariableSets
            }
        });
    }

    private getVariables(): ReadonlyArray<VariableWithSource> {
        if (!this.state.model) {
            return [];
        }

        const projectSource = {
            projectName: this.state.model.project.Name,
            projectId: this.props.projectId
        };

        const projectVariables = convertVariableResourcesToVariablesWithSource(this.state.model.projectVariables.Variables, projectSource);
        const libraryVariables = flatten(this.state.model.libraryVariableSetVariables.map(vSet => {
            const libraryVariableSet = this.state.model.libraryVariableSets.find(s => s.Id === vSet.OwnerId)!;
            const variableSetSource = {
                variableSetName: libraryVariableSet.Name,
                variableSetId: libraryVariableSet.Id
            };
            return convertVariableResourcesToVariablesWithSource(vSet.Variables, variableSetSource);
        }));
        return [...projectVariables, ...libraryVariables];
    }

    private loadProjectVariables(release: ReleaseResource) {
        return repository.Variables.get(release.ProjectVariableSetSnapshotId);
    }

    private async loadLibraryVariableSetVariables(release: ReleaseResource) {
        const variableSets = await Promise.all(release.LibraryVariableSetSnapshotIds.map(async id => {
            try {
                return await repository.Variables.get(id);
            } catch (ex) {
                if (ex instanceof OctopusError) {
                    const errors = createErrorsFromOctopusError(ex);
                    this.setState({ variableSetErrors: errors });
                }
                return null;
            }
        }));
        return variableSets.filter(s => !!s);
    }

    private get availableScopes(): ScopeValues {
        const allScopeValues: ScopeValues[] = this.state.model ? [
            this.state.model.projectVariables.ScopeValues,
            ...this.state.model.libraryVariableSetVariables.map(set => set.ScopeValues)
        ] : [];
        return mergeScopeValues(allScopeValues);
    }

    private updateVariables() {
        this.props.doBusyTask(async () => {
            const updatedRelease = await repository.Releases.snapshotVariables(this.props.release);
            await this.loadData(updatedRelease);
            this.setState({ showConfirmationDialog: false });
        });
    }
}