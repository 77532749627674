import {RemoveItemsList} from "components/RemoveItemsList/RemoveItemsList";
import {KeyValueOption} from "components/EditList/ExtendedKeyValueEditList";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import {ActionEditProps} from "components/Actions/pluginRegistry";
import {KubernetesIngressProperties} from "components/Actions/kubernetes/kubernetesProperties";
import {JsonUtils} from "utils/jsonUtils";
import DialogOpener from "components/Dialog/DialogOpener";
import IngressRuleDialog from "components/Actions/kubernetes/ingressRuleDialog";
import {default as ExpandableFormSection} from "components/form/Sections/ExpandableFormSection";
import {VariableLookupText} from "components/form/VariableLookupText";
import ActionButton from "components/Button";
import StringExtendedKeyValueEditList from "components/EditList/ExtendedKeyValueEditList";
import Note from "components/form/Note/Note";
import {clone} from "lodash";
import Summary from "components/form/Sections/Summary";
import {IngressAnnotations} from "components/Features/kubernetes/ingressAnnotationsSuggestions";
import * as React from "react";
import * as _ from "lodash";
import ActionProperties from "client/resources/actionProperties";
import {PackageReferenceProperties} from "client/resources/packageReference";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import {IngressTlsCertificateDialog} from "./ingressTlsCertificateDialog";
import {SortableItemModel} from "components/SortableList/SortableList";
import {v4} from "uuid";
import IngressRuleSorter from "components/Actions/kubernetes/ingressRuleSorter";
import OpenDialogButton from "components/Dialog/OpenDialogButton";

interface KubernetesIngressState {
    ingressRules: IngressRule[];
    editIngressRule: IngressRule;
    editIngressRuleIndex: number;
    ingressTlsCertificates: IngressTlsCertificate[];
    editTlsCertificate: IngressTlsCertificate;
    editTlsCertificateIndex: number;
}

class IngressList extends RemoveItemsList<IngressRule> {
}

class IngressTlsCertificateList extends RemoveItemsList<IngressTlsCertificate> {
}

/**
 * The data that the server is interested in for an ingress rule.
 */
interface IngressRuleData {
    host: string;
    http: {
        paths: KeyValueOption[];
    };
}

/**
 * The server is interested in the host and http values
 * in this class (i.e. the data from the IngressRuleData interface).
 * But to allow a list of ingress rules to be sorted, it needs to have a
 * name and an ID (i.e. the SortableItemModel interface).
 */
export class IngressRule implements IngressRuleData, SortableItemModel {
    host: string;
    http: {
        paths: KeyValueOption[];
    };
    Id: string;
    Name: string;
    constructor(host: string = "", paths: KeyValueOption[] = []) {
        this.host = host || "";
        this.http = {paths: paths || []};
        this.Name = (this.http.paths || [])
            .map(path => (this.host || "*") + path.key + " => " + (path.option || "") + ":" + path.value)
            .join(" ");
        this.Id = v4();
    }

    /**
     * Return only the data that the server is interested in.
     */
    cleanObject(): IngressRuleData {
        return {host: this.host, http: {paths: this.http.paths}};
    }
}

export interface IngressTlsCertificate {
    hosts: string[];
    certificateVariableName?: string;
    secretName?: string;
}

export interface KubernetesIngressComponentProperties<T = ActionProperties, P = PackageReferenceProperties> extends ActionEditProps<T, P> {
    standAlone: boolean;
}

export class KubernetesIngressComponent extends BaseComponent<KubernetesIngressComponentProperties<KubernetesIngressProperties>, KubernetesIngressState> {
    constructor(props: KubernetesIngressComponentProperties<KubernetesIngressProperties>) {
        super(props);
        this.state = {
            ingressRules: [],
            editIngressRule: null,
            editIngressRuleIndex: null,
            ingressTlsCertificates: [],
            editTlsCertificate: null,
            editTlsCertificateIndex: null
        };
    }

    componentDidMount() {
        this.props.doBusyTask(async () => {
            this.setState({
                ingressRules: JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.IngressRules"], [])
                    .map((item: IngressRule) => new IngressRule(item.host, item.http.paths)),
                ingressTlsCertificates: JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.IngressTlsCertificates"], []),
            });

            // Sanitise the ingress list, which might have been an object before the input control was switched
            if (JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.IngressAnnotations"], []).length === 0) {
                this.props.setProperties({["Octopus.Action.KubernetesContainers.IngressAnnotations"]: "[]"}, true);
            }
        });
    }

    componentWillReceiveProps(nextProps: ActionEditProps<KubernetesIngressProperties>) {
        if (this.props.properties["Octopus.Action.KubernetesContainers.IngressRules"] !==
            nextProps.properties["Octopus.Action.KubernetesContainers.IngressRules"]) {
            this.setState({ingressRules: JsonUtils.tryParseArray(
                nextProps.properties["Octopus.Action.KubernetesContainers.IngressRules"], [])
                .map((item: IngressRule) => new IngressRule(item.host, item.http.paths))});
        }

        if (this.props.properties["Octopus.Action.KubernetesContainers.IngressTlsCertificates"] !==
            nextProps.properties["Octopus.Action.KubernetesContainers.IngressTlsCertificates"]) {
           this.setState({ingressTlsCertificates: JsonUtils.tryParseArray(nextProps.properties["Octopus.Action.KubernetesContainers.IngressTlsCertificates"], [])});
        }
    }

    render() {
        const editIngressDialog = <DialogOpener open={!!this.state.editIngressRule} onClose={this.resetIngressRule}>
            <IngressRuleDialog
                ingressRule={this.state.editIngressRule}
                projectId={this.props.projectId}
                doBusyTask={this.props.doBusyTask}
                localNames={this.props.localNames}
                onAdd={item => this.saveIngressRule(item)}
                servicePorts={JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.ServicePorts"], [])}
                standAlone={this.props.standAlone}
            />
        </DialogOpener>;

        const editTlsCertificateDialog = <DialogOpener open={!!this.state.editTlsCertificate} onClose={this.resetTlsCertificate}>
            <IngressTlsCertificateDialog
                tlsCertificate={this.state.editTlsCertificate}
                ingressRuleHosts={this.state.ingressRules.map(rule => rule.host)}
                projectId={this.props.projectId}
                localNames={this.props.localNames}
                onSave={item => this.saveTlsCertificate(item)}
            />
        </DialogOpener>;

        return <div>
            {editIngressDialog}
            {editTlsCertificateDialog}
            <ExpandableFormSection
                errorKey="Octopus.Action.KubernetesContainers.IngressName"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Ingress Name"
                summary={this.ingressSummary()}
                help={"Enter the service name exposing the deployment."}>
                <Note>
                    The name of the ingress resource.
                </Note>
                <Note>
                    Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#ingress-name">ingress name</ExternalLink>.
                </Note>
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={this.props.properties["Octopus.Action.KubernetesContainers.IngressName"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.IngressName"]: x })}
                    error={this.props.getFieldError("Octopus.Action.KubernetesContainers.IngressName")}
                    label="Ingress name" />
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="Octopus.Action.KubernetesContainers.IngressAnnotations"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Ingress Annotations"
                summary={this.ingressAnnotationsSummary()}
                help={"Add annotations to configure the ingress controller."}>
                <Note>
                    Ingress annotations can be specific to the type of ingress controller used by the Kubernetes cluster, and the suggested annotation keys are not exhaustive.
                </Note>
                <Note>
                    Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#ingress-annotations">ingress annotations</ExternalLink>.
                </Note>
                <StringExtendedKeyValueEditList
                    items={this.props.properties["Octopus.Action.KubernetesContainers.IngressAnnotations"]}
                    name="Annotation"
                    onChange={val => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.IngressAnnotations"]: val })}
                    valueLabel="Value"
                    keyLabel="Name"
                    getOptions={this.getIngressAnnotations}
                    hideBindOnKey={false}
                    projectId={this.props.projectId}
                    addToTop={true}
                />

            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="Octopus.Action.KubernetesContainers.IngressRules"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Ingress Host Rules"
                summary={this.ingressRulesSummary()}
                help={"Add rules to be applied to hosts."}>
                <Note>
                    Host rules map incoming requests by their host name and path to ports exposed by the service resource.
                </Note>
                <Note>
                    Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#ingress-host-rules">ingress host rules</ExternalLink>.
                </Note>
                <IngressList
                    listActions={[
                        <OpenDialogButton key="sort" label="Sort Rules">
                            <IngressRuleSorter title="Sort Rules"
                                               ingressRules={this.state.ingressRules}
                                               saveDone={(ingressRules) => {
                                                   this.setState({ingressRules});
                                                   this.props.setProperties({["Octopus.Action.KubernetesContainers.IngressRules"]: JSON.stringify(ingressRules.map(item => item.cleanObject()))});
                                               }} />
                        </OpenDialogButton>,
                        <ActionButton key="add" label="Add Host Rule" onClick={() => this.addIngressRule()}/>
                        ]}
                    data={this.state.ingressRules}
                    onRow={(binding) => <div>
                        {binding.http.paths
                            ? <div>
                                <ul>{binding.http.paths.map(
                                    path =>
                                        <li>
                                            <strong>{binding.host || "*"}{path.key}</strong>
                                            &nbsp;=>&nbsp;
                                            <strong>
                                                {this.props.standAlone
                                                    ? <span>{path.option}</span>
                                                    : <span>{this.props.properties["Octopus.Action.KubernetesContainers.ServiceName"]}</span>}:{path.value}
                                            </strong>
                                        </li>)}
                                </ul>
                            </div>
                            : <div>No paths defined</div>}
                    </div>}
                    onRowTouch={(binding) => this.editIngressRule(binding)}
                    onRemoveRow={(binding) => this.removeIngressRule(binding)}/>
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="Octopus.Action.KubernetesContainers.IngressTls"
                title="Ingress TLS"
                summary={this.ingressTlsSummary()}
                help="Configure TLS certificates">
                <Note>
                    The ingress can be configured to use TLS for specified hosts.
                </Note>
                <Note>
                    Learn more about <ExternalLink href="KubernetesIngressTls">ingress TLS</ExternalLink>.
                </Note>
                <IngressTlsCertificateList
                    listActions={[<ActionButton key="add" label="Add TLS certificate" onClick={() => this.addTlsCertificate()}/>]}
                    data={this.state.ingressTlsCertificates}
                    onRow={(hostMapping) => <React.Fragment>
                            {!!hostMapping.certificateVariableName && <div>Certificate variable: <strong>{hostMapping.certificateVariableName}</strong></div>}
                            {!!hostMapping.secretName && <div>Secret: <strong>{hostMapping.secretName}</strong></div>}
                            {hostMapping.hosts
                                ? <div>
                                        Hosts:
                                        <ul>{hostMapping.hosts.map(
                                        host => <li><strong>{host}</strong></li>
                                        )}</ul>
                                </div>
                                : <div>No hosts defined</div>
                            }
                    </React.Fragment>}
                    onRowTouch={(hostMapping) => this.editTlsCertificate(hostMapping)}
                    onRemoveRow={(hostMapping) => this.removeTlsCertificate(hostMapping)}
                />
            </ExpandableFormSection>
        </div>;
    }

    addIngressRule = () => {
        const binding: IngressRule = new IngressRule();
        this.setState({
            editIngressRule: binding,
            editIngressRuleIndex: null
        });
    }

    editIngressRule = (binding: IngressRule) => {
        this.setState({
            editIngressRule: clone(binding),
            editIngressRuleIndex: this.state.ingressRules.indexOf(binding)
        });
    }

    removeIngressRule = (binding: IngressRule) => {
        const bindings = [...this.state.ingressRules];
        bindings.splice(this.state.ingressRules.indexOf(binding), 1);
        this.props.setProperties({["Octopus.Action.KubernetesContainers.IngressRules"]: JSON.stringify(bindings.map(item => item.cleanObject()))});
    }

    resetIngressRule = () => {
        this.setState({
            editIngressRule: null,
            editIngressRuleIndex: null
        });
    }

    saveIngressRule = (binding: IngressRule) => {
        const bindings = [...this.state.ingressRules];
        const rule = new IngressRule(binding.host, binding.http.paths);
        if (this.state.editIngressRuleIndex === null) {
            bindings.push(rule);
        } else {
            bindings[this.state.editIngressRuleIndex] = rule;
        }
        this.props.setProperties({["Octopus.Action.KubernetesContainers.IngressRules"]: JSON.stringify(bindings.map(item => item.cleanObject()))});
        this.resetIngressRule();
        return true;
    }

    addTlsCertificate = () => {
        this.setState({
            editTlsCertificate: {
                hosts: [],
                certificateVariableName: "",
                secretName: null
            },
            editTlsCertificateIndex: null
        });
    }

    editTlsCertificate = (hostMapping: IngressTlsCertificate) => {
       this.setState({
           editTlsCertificate: hostMapping,
           editTlsCertificateIndex: this.state.ingressTlsCertificates.indexOf(hostMapping)
       });
    }

    removeTlsCertificate = (hostMapping: IngressTlsCertificate) => {
        const hostMappings = [...this.state.ingressTlsCertificates];
        hostMappings.splice(this.state.ingressTlsCertificates.indexOf(hostMapping), 1);
        this.props.setProperties({["Octopus.Action.KubernetesContainers.IngressTlsCertificates"]: JSON.stringify(hostMappings)});
    }

    resetTlsCertificate = () => {
        this.setState({
            editTlsCertificate: null,
            editTlsCertificateIndex: null
        });
    }

    saveTlsCertificate = (hostMapping: IngressTlsCertificate) => {
        const hostMappings = [...this.state.ingressTlsCertificates];
        if (this.state.editTlsCertificateIndex === null) {
            hostMappings.push(hostMapping);
        } else {
            hostMappings[this.state.editTlsCertificateIndex] = hostMapping;
        }
        this.props.setProperties({["Octopus.Action.KubernetesContainers.IngressTlsCertificates"]: JSON.stringify(hostMappings)});
        this.resetTlsCertificate();
        return true;
    }

    private ingressRulesSummary() {
        if ((this.state.ingressRules || []).length === 0) {
            return Summary.placeholder("No host rules have been included");
        }

        return Summary.summary(
            <span>Add the rule{this.state.ingressRules.length > 1 && <span>s</span>}: {
                _.chain(this.state.ingressRules)
                    .flatMap(rule => [
                        ..._.chain(rule.http.paths)
                            .flatMap(pair => [<span>{rule.Name}</span>, <span>, </span>])
                            .slice(0, -1)
                            .value(),
                        <span>; </span>])
                    .slice(0, -1)
                    .value()
            }</span>
        );
    }

    private ingressTlsSummary() {
        const tlsHosts = this.state.ingressTlsCertificates;

        if (!tlsHosts || tlsHosts.length === 0) {
            return Summary.placeholder("TLS has not been configured");
        }

        return Summary.summary(
            <span>Configure TLS using {
                _.map(tlsHosts, tls =>
                    <React.Fragment>
                        {!!tls.certificateVariableName && <span>certificate <strong>{tls.certificateVariableName}</strong> </span>}
                        {!!tls.secretName && <div>secret <strong>{tls.secretName}</strong></div>}
                        <span> for {tls.hosts ? "hosts " : "&lt;No hosts defined&gt;"}</span>
                        <React.Fragment>
                        {tls.hosts.map((host, idx) =>
                            <React.Fragment><strong>{host}</strong>{idx < tls.hosts.length - 1 ? ", " : ""}</React.Fragment>
                        )}
                        </React.Fragment>
                    </React.Fragment>)
            }
        </span>);
    }

    private ingressAnnotationsSummary() {
        const annotations: KeyValueOption[] = JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.IngressAnnotations"], []);

        if (annotations.length === 0) {
            return Summary.placeholder("No annotations have been included");
        }

        return Summary.summary(<span>
            Add the annotation{annotations.length > 1 && <span>s</span>} {
                _.chain(annotations)
                .flatMap(annotation => [<strong>{annotation.key}: {annotation.value}</strong>, <span>, </span>])
                .slice(0, -1)
                .value()}
            </span>);
    }

    private ingressSummary() {
        if (!(this.props.properties["Octopus.Action.KubernetesContainers.IngressName"])) {
            return Summary.placeholder("No name has been provided");
        }

        return Summary.summary(<span>
            Create an ingress called <strong>{this.props.properties["Octopus.Action.KubernetesContainers.IngressName"]}</strong>
        </span>);
    }

    private getIngressAnnotations = async (searchText: string) => {
        const results = _.chain(IngressAnnotations)
            .filter(v => !!v)
            .filter(v => v.toLowerCase().includes(searchText.toLowerCase()))
            .value();
        const itemsToTake = 7;
        return {
            items: results.slice(0, itemsToTake).map(f => ({ Id: f, Name: f })),
            containsAllResults: results.length <= itemsToTake
        };
    }
}