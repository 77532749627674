import * as React from "react";
import FormFieldProps from "components/form/FormFieldProps";
import {CertificateResource} from "client/resources/certificateResource";
import CertificateSelector from "./CertificateSelector";
import Select from "components/form/Select/Select";
import HttpsIcon from "material-ui/svg-icons/action/https";
import * as moment from "moment";
import {danger} from "theme/colors";
const styles = require("./style.less");
import OpenDialogIconButton from "components/Dialog/OpenDialogIconButton";
import {Icon, default as IconButton} from "components/IconButton/IconButton";
import InputWithActions from "components/InputWithActions/InputWithActions";
import {repository} from "clientInstance";
import BusyIndicator from "components/BusyIndicator/BusyIndicator";
import { withBoundField } from "components/form/BoundField/BoundField";
import BusyRefreshContainer from "components/BusyRefreshContainer";
import routeLinks from "../../../routeLinks";

interface CertificateSelectProps extends FormFieldProps<string> {
    allowClear?: boolean;
    tenantId?: string;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
    validate?(value: string): string;
    onValidate?(value: string): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}

interface TextState {
    error?: string;
    showSearchDialog: boolean;
    certificates: CertificateResource[];
    isDataLoaded: boolean;
    busy: boolean; //TODO: move busy back out into props and use a HOC/Render prop component to manage this state
}

const toggleBusy = (value?: boolean) => (prev: TextState, props: CertificateSelectProps) => ({...prev, busy: value ? value : !prev.busy});

class CertificateSelect extends React.Component<CertificateSelectProps, TextState> {

    constructor(props: CertificateSelectProps) {
        super(props);
        this.state = {
            error: null,
            showSearchDialog: false,
            certificates: [],
            isDataLoaded: false,
            busy: false
        };
    }

    componentDidMount() {
       this.loadData();
    }

    async loadData() {
        try {
            this.setState(toggleBusy(true));
            await this.props.doBusyTask(async () => {
                // take:1000 is clearly a hack. But we need all the certs for the drop-down, but we need them filtered by tenant
                // certificates/all is cached, and so does not support filtering by tenant.
                const certificates = (await repository.Certificates.list({tenant: this.props.tenantId, take: 1000})).Items;
                this.setState({certificates, isDataLoaded: true});
            });
        } finally {
            this.setState(toggleBusy(false));
        }
    }

    handleChange = (certificateId: string) => {
        const value = certificateId === "" ? null : certificateId;
        if (this.props.validate) {
            const result = this.props.validate(value);
            this.setState({error: result});
            if (this.props.onValidate) {
                this.props.onValidate(result);
            }
        }
        this.props.onChange(value);
    }

    getItems() {
        return this.state.certificates.map(certificate => {
            const expired = this.isCertificateExpired(certificate);
            const icon = expired ? <HttpsIcon color={danger}/> : <HttpsIcon/>;
            return {
                value: certificate.Id,
                text: certificate.Name + (expired ? " (expired)" : ""),
                icon,
                style: expired ? {color: danger} : null
            };
        });
    }

    selectionRenderer = (certificateId: string) => {
        const certificate = this.state.certificates.find(acc => acc.Id === certificateId);
        if (!certificate) {
            return certificateId;
        }

        if (this.isCertificateExpired(certificate)) {
            return <div style={{color: danger}}>
                <span className={styles.selectedIcon}><HttpsIcon color={danger}/></span>
                {certificate.Name} (expired)
            </div>;
        }

        return <div>
            <span className={styles.selectedIcon}><HttpsIcon/></span>
            {certificate.Name}
        </div>;
    }

    render() {

        if (!this.state.isDataLoaded) {
            return <BusyIndicator show={true} inline={true} />;
        }

        const {
            onChange,
            onValidate,
            tenantId,
            doBusyTask,
            ...otherProps
        } = this.props;

        return (
            <BusyRefreshContainer busy={this.state.busy}>
                <InputWithActions input={
                    <Select label="Select certificate"
                        {...otherProps}
                        allowFilter={true}
                        onChange={this.handleChange}
                        items={this.getItems()}
                        selectionRenderer={this.selectionRenderer}
                    />}
                    actions={
                        <React.Fragment>
                            <OpenDialogIconButton toolTipContent="Search" wideDialog={true} icon={Icon.Search}>
                                <CertificateSelector
                                    onSelected={(certificate) => this.handleChange(certificate.Id)}
                                    selectedCertificateId={this.props.value}
                                    tenantId={this.props.tenantId} />
                            </OpenDialogIconButton>
                            <IconButton disabled={this.state.busy} onClick={() => this.loadData()} toolTipContent="Refresh" icon={Icon.Refresh}/>
                            <IconButton toolTipContent="Add" onClick={this.goToAccounts} icon={Icon.Add}/>
                       </React.Fragment>
                    }
                />
            </BusyRefreshContainer>
        );
    }

    private goToAccounts = () => {
        window.open(`#${routeLinks.library.certificates.root}`, "_blank");
    }

    private isCertificateExpired(certificate: CertificateResource) {
        const now = moment();

        const certificateExpiry = moment(certificate.NotAfter);
        return certificateExpiry.isBefore(now);
    }
}

export default CertificateSelect;