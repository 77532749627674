exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_description__RpFtv {\n  margin-bottom: 0.25rem;\n}\n.style_empty__33zoZ {\n  font-size: 1rem;\n  margin: 0;\n  line-height: 1.5rem;\n}\n.style_grid__6e697 {\n  display: flex;\n  flex-direction: column;\n}\n.style_grid__6e697 .style_row__31Jpf {\n  display: flex;\n  flex-direction: row;\n  margin: 0.3rem 0;\n}\n.style_grid__6e697 .style_row__31Jpf .style_header__3zCMM {\n  flex-basis: 13%;\n  text-align: left;\n  margin-right: 1.25rem;\n  font-weight: 600;\n}\n.style_designRuleButton__1NkH6 {\n  display: flex;\n  justify-content: flex-start;\n  margin-top: 0.25rem;\n}\n.style_codeWrapper__3rd4p {\n  position: relative;\n  height: 15rem;\n}\n.style_rulesList__3xKW6 {\n  margin: 0;\n  padding-left: 1rem;\n  list-style-type: decimal;\n}\n.style_rulesList__3xKW6 li {\n  padding-bottom: 0.625rem;\n}\n.style_rulesList__3xKW6 li:last-child {\n  padding-bottom: 0;\n}\n.style_rulesEditHeader__2Cxvn {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n.style_addVersionActionContainer__JCGfB {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n}\n", ""]);

// exports
exports.locals = {
	"description": "style_description__RpFtv",
	"empty": "style_empty__33zoZ",
	"grid": "style_grid__6e697",
	"row": "style_row__31Jpf",
	"header": "style_header__3zCMM",
	"designRuleButton": "style_designRuleButton__1NkH6",
	"codeWrapper": "style_codeWrapper__3rd4p",
	"rulesList": "style_rulesList__3xKW6",
	"rulesEditHeader": "style_rulesEditHeader__2Cxvn",
	"addVersionActionContainer": "style_addVersionActionContainer__JCGfB"
};