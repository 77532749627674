import * as React from "react";
import SimpleDataTable from "components/SimpleDataTable";
import { ProjectUsage } from "client/resources/libraryVariableSetUsageResource";
import InternalLink from "components/Navigation/InternalLink";
import routeLinks from "routeLinks";

export const VariableSetProjectUsages: React.FC<{usage: ProjectUsage[]}> = ({usage}) => {
    return <SimpleDataTable<ProjectUsage>
        data={usage}
        headerColumns={["Project Name"]}
        onRow={project => [
            <InternalLink to={routeLinks.project(project.ProjectSlug).process.root}>
                {project.ProjectName}
            </InternalLink>
        ]}/>;
};
