exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .style_hide-sm__3ahUV {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .style_visible-sm__Ru8DJ {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .style_hide-md__28VBs {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .style_visible-md__rG3_k {\n    display: none !important;\n  }\n}\n.style_headerLinks__1PUMZ {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  align-items: center;\n}\n@media (max-width: 37.4rem) {\n  .style_headerLinks__1PUMZ {\n    flex-direction: column;\n    align-items: flex-start;\n  }\n}\n.style_assignmentAction__2O7QD {\n  margin-left: 0.5rem;\n}\n.style_notes__2dHs- {\n  background-color: #ffffff;\n  width: 100%;\n}\n.style_actions__32hau {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  margin-top: 0.5rem;\n}\n.style_formElements__15Lbb {\n  background-color: #ffffff;\n  padding: 0.5rem 0.5rem 1rem 0.5rem;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "style_hide-sm__3ahUV",
	"hideSm": "style_hide-sm__3ahUV",
	"visible-sm": "style_visible-sm__Ru8DJ",
	"visibleSm": "style_visible-sm__Ru8DJ",
	"hide-md": "style_hide-md__28VBs",
	"hideMd": "style_hide-md__28VBs",
	"visible-md": "style_visible-md__rG3_k",
	"visibleMd": "style_visible-md__rG3_k",
	"headerLinks": "style_headerLinks__1PUMZ",
	"assignmentAction": "style_assignmentAction__2O7QD",
	"notes": "style_notes__2dHs-",
	"actions": "style_actions__32hau",
	"formElements": "style_formElements__15Lbb"
};