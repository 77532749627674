import * as React from "react";
import { CategoryDefinition } from "./endpointRegistry";
import ExternalLink from "components/Navigation/ExternalLink";
import { Note } from "components/form";

const category: CategoryDefinition = {
    category: "Linux",
    title: <React.Fragment>What type of <strong>Linux target</strong> do you want to set up?</React.Fragment>,
    help: <Note>Learn more about <ExternalLink href="SSHTargets">SSH Targets</ExternalLink></Note>,
    displayOrder: 20,
 };

export default category;
