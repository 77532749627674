import * as React from "react";
import { RouteComponentProps } from "react-router";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import { repository } from "clientInstance";
import { cloneDeep } from "lodash";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import FormPaperLayout from "components/FormPaperLayout";
import { Permission, ProjectResource } from "client/resources";
import {
    ExpandableFormSection,
    Summary,
    Text,
    required,
    MarkdownEditor,
    FormSectionHeading,
} from "components/form";
import {
    LibraryVariableSetResource,
    VariableSetContentType
} from "client/resources/libraryVariableSetResource";
import Markdown from "components/Markdown/index";
import CodeEditor from "components/CodeEditor/CodeEditor";
import { VariableSetResource } from "client/resources/variableSetResource";
import { VariableResource, VariableType } from "client/resources/variableResource";
import OverflowMenu from "components/Menu/OverflowMenu";
import { ScriptingLanguage } from "components/scriptingLanguage";
import StringHelper from "utils/StringHelper";
import routeLinks from "../../../../routeLinks";
import { CardFill } from "components/form/Sections/ExpandableFormSection";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import ScriptingLanguageSelector, { SupportedLanguage } from "components/ScriptingLanguageSelector/ScriptingLanguageSelector";
import { DataTable, DataTableHeader, DataTableRow, DataTableHeaderColumn, DataTableBody, DataTableRowColumn } from "components/DataTable";
import InternalLink from "components/Navigation/InternalLink";
import LibraryVariableSetUsageResource, { ProjectUsage, ReleaseUsageEntry } from "client/resources/libraryVariableSetUsageResource";
import Chip from "components/Chips/Chip";
import SimpleDataTable from "components/SimpleDataTable";
import { VariableSetProjectUsages } from "../VariableSets/VariableSetProjectUsage";
import { VariableSetReleaseUsages } from "../VariableSets/VariableSetReleaseUsage";
import { TabContainer, TabItem } from "components/Tabs";

interface ScriptModuleProps extends RouteComponentProps<ScriptModuleRouteParams> {
    create?: boolean;
}

interface ScriptModuleRouteParams {
    scriptModuleId: string;
}

interface ScriptModuleModel extends LibraryVariableSetResource {
    scriptBody: string;
    syntax: ScriptingLanguage;
    variableSet: VariableSetResource;
}

interface ScriptModuleState extends OptionalFormBaseComponentState<ScriptModuleModel> {
    deleted: boolean;
    newId: string;
    scriptModule: LibraryVariableSetResource;
    usages: LibraryVariableSetUsageResource;
    usagesInProjects: ProjectUsage[];
    usagesInReleaseSnapshots: ProjectUsage[];
    countOfReleasesUserCannotSee: number;
    countOfProjectsUserCannotSee: number;
}

class ScriptModule extends FormBaseComponent<ScriptModuleProps, ScriptModuleState, ScriptModuleModel> {
    constructor(props: ScriptModuleProps) {
        super(props);
        this.state = {
            deleted: false,
            newId: null,
            usagesInProjects: [],
            usagesInReleaseSnapshots: [],
            countOfReleasesUserCannotSee: 0,
            countOfProjectsUserCannotSee: 0,
            scriptModule: null,
            usages: null,
        };
    }

    async componentDidMount() {
        let model: ScriptModuleModel = null;
        let variableSet: VariableSetResource = null;
        let scriptModule: LibraryVariableSetResource = null;

        if (this.props.create) {
            model = {
                Id: null as string,
                Name: null,
                Description: null,
                Links: null,
                VariableSetId: null as string,
                ContentType: VariableSetContentType.ScriptModule,
                Templates: [],
                scriptBody: this.getSampleScriptBody(ScriptingLanguage.PowerShell),
                syntax: ScriptingLanguage.PowerShell,
                variableSet: null
            };
        } else {
            await this.doBusyTask(async () => {
                scriptModule = await repository.LibraryVariableSets.get(this.props.match.params.scriptModuleId);

                variableSet = await repository.Variables.get(scriptModule.VariableSetId);
                const bodyVariable = this.getBodyVariable(variableSet);
                const syntax = this.getSyntax(variableSet);
                const scriptBody = bodyVariable ? bodyVariable.Value : "";
                model = this.buildModel(scriptModule, scriptBody, syntax, variableSet);
            });
        }

        this.setState({
            model,
            cleanModel: cloneDeep(model),
            scriptModule
        });
    }

    getSampleScriptBody(syntax: ScriptingLanguage): string {
        switch (syntax) {
            case ScriptingLanguage.PowerShell:
                return "function Say-Hello()\r\n{\r\n    Write-Output \"Hello, Octopus!\"\r\n}\r\n";
            case ScriptingLanguage.Bash:
                return "say_hello() {\r\n    echo \"Hello, Octopus!\"\r\n}\r\n";
            case ScriptingLanguage.CSharp:
                return "void SayHello()\r\n{\r\n    Console.WriteLine(\"Hello, Octopus!\");\r\n}\r\n";
            case ScriptingLanguage.FSharp:
                return "module ScriptModule\r\n\r\nlet SayHello() = \r\n    printfn \"Hello, Octopus!\"\r\n";
            case ScriptingLanguage.Python:
                return "def say_hello():\r\n    print(\"Hello, Octopus!\")\r\n";
        }
    }

    getBodyVariable(variableSet: VariableSetResource): VariableResource {
        return variableSet.Variables.find(v => v.Name.startsWith("Octopus.Script.Module["));
    }

    getSyntaxVariable(variableSet: VariableSetResource): VariableResource {
        return variableSet.Variables.find(v => v.Name.startsWith("Octopus.Script.Module.Language["));
    }

    getSyntax(variableSet: VariableSetResource): ScriptingLanguage {
        const syntax = this.getSyntaxVariable(variableSet);
        return syntax
            ? syntax.Value as ScriptingLanguage
            : ScriptingLanguage.PowerShell;
    }

    render() {
        const title = this.props.create
            ? "New Script Module"
            : this.state.model
                ? this.state.model.Name
                : StringHelper.ellipsis;

        const overFlowActions = [];
        if (!this.props.create && !!this.state.model) {
            overFlowActions.push(OverflowMenu.deleteItemDefault("script module", this.handleDeleteConfirm, { permission: Permission.LibraryVariableSetDelete }));
            overFlowActions.push([OverflowMenu.navItem("Audit Trail",
                routeLinks.configuration.eventsRegardingAny([this.state.model.Id]), null, {
                    permission: Permission.EventView,
                    wildcard: true
                })]);
        }

        const saveText: string = this.state.newId
            ? "Script module created"
            : "Script module details updated";

        return <LibraryLayout {...this.props}>
            <FormPaperLayout
                title={title}
                breadcrumbTitle={"Script Modules"}
                breadcrumbPath={routeLinks.library.scripts.root}
                busy={this.state.busy}
                errors={this.state.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={{ permission: this.props.create ? Permission.LibraryVariableSetCreate : Permission.LibraryVariableSetEdit }}
                onSaveClick={() => this.handleSaveClick(false)}
                saveText={saveText}
                expandAllOnMount={this.props.create}
                overFlowActions={overFlowActions} >
                {this.state.deleted && <InternalRedirect to={routeLinks.library.scripts.root} />}
                {this.state.newId && <InternalRedirect to={routeLinks.library.script(this.state.newId)} />}
                {this.state.model && <TransitionAnimation>
                    <TabContainer defaultValue="summary">
                        <TabItem label="Summary" value="summary">
                            <ExpandableFormSection
                                errorKey="Name"
                                title="Name"
                                focusOnExpandAll
                                summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your script module")}
                                help="A short, memorable, unique name for this script module. Example: Service Configuration.">
                                <Text
                                    value={this.state.model.Name}
                                    onChange={Name => this.setModelState({ Name })}
                                    label="Name"
                                    error={this.getFieldError("Name")}
                                    validate={required("Please enter a script module name")}
                                    autoFocus={true}
                                />
                            </ExpandableFormSection>
                            <ExpandableFormSection
                                errorKey="Description"
                                title="Description"
                                summary={this.descriptionSummary()}
                                help="This summary will be presented to users when selecting the script module for inclusion in a project.">
                                <MarkdownEditor
                                    value={this.state.model.Description}
                                    label="Description"
                                    onChange={Description => this.setModelState({ Description })}
                                />
                            </ExpandableFormSection>
                            <ExpandableFormSection
                                errorKey="Body"
                                title="Body"
                                fillCardWidth={CardFill.FillRight}
                                summary={this.scriptBodySummary()}
                                help={<Markdown markup={this.languageSpecificHelpText(this.state.model.syntax)} />}>
                                <ScriptingLanguageSelector supportedLanguages={SupportedLanguage.All}
                                    value={this.state.model.syntax}
                                    onChange={syntax => this.handleSyntaxChange(syntax)} />
                                <CodeEditor value={this.state.model.scriptBody}
                                    language={this.state.model.syntax}
                                    allowFullScreen={true}
                                    onChange={scriptBody => this.setModelState({ scriptBody })}
                                />
                            </ExpandableFormSection>
                        </TabItem>
                        {!this.props.create && <TabItem label="Usage" value="usage" onActive={() => this.onUsageTabActive()}>
                            {this.state.usages && <ExpandableFormSection
                                    key="usageInProjects"
                                    errorKey="usageInProjects"
                                    title="Projects"
                                    expandable={this.state.usagesInProjects.length > 0}
                                    summary={this.projectUsageSummary()}
                                    help={this.projectUsageHelp()}>
                                    <VariableSetProjectUsages usage={this.state.usagesInProjects} />
                                </ExpandableFormSection>}
                            {this.state.usages && <ExpandableFormSection
                                key="usageInReleaseSnapshots"
                                errorKey="usageInReleaseSnapshots"
                                title="Releases"
                                expandable={this.state.usagesInReleaseSnapshots.length > 0}
                                summary={this.releaseUsageSummary()}
                                help={this.releaseUsageHelp()}>
                                <VariableSetReleaseUsages usage={this.state.usagesInReleaseSnapshots} />
                            </ExpandableFormSection>}
                        </TabItem>}
                    </TabContainer>
                </TransitionAnimation>}
            </FormPaperLayout>
        </LibraryLayout>;
    }

    async onUsageTabActive() {
        if (this.state.usages || this.props.create) {
           return;
        }
        await this.doBusyTask(async () => {
            const usages = await repository.LibraryVariableSets.getUsages(this.state.scriptModule);
            const usagesInProjects = usages.Projects.filter(x => x.IsCurrentlyBeingUsedInProject === true);
            const usagesInReleaseSnapshots = usages.Projects.filter(x => x.Releases.length > 0);
            const countOfReleasesUserCannotSee = usages.CountOfReleasesUserCannotSee;
            const countOfProjectsUserCannotSee = usages.CountOfProjectsUserCannotSee;
            this.setState({
                usages,
                usagesInProjects,
                usagesInReleaseSnapshots,
                countOfReleasesUserCannotSee,
                countOfProjectsUserCannotSee
            });
        });
    }

    getProjectUsageSummary(count: number, countOfProjectsUserCannotSee: number) {
        return this.pluraliseSummary(count, "This script module", "is being", "has not been", "project", countOfProjectsUserCannotSee);
    }

    getReleaseUsageSummary(count: number, countOfReleasesUserCannotSee: number) {
        return this.pluraliseSummary(count, "This script module", "has been", "has not been", "release snapshot", countOfReleasesUserCannotSee);
    }

    getProjectUsageHelp(count: number, countOfProjectsUserCannotSee: number) {
        return this.pluraliseHelp(count, "This script module", "is being", "has not been", "project", countOfProjectsUserCannotSee);
    }

    getReleaseUsageHelp(count: number, countOfReleasesUserCannotSee: number) {
        return this.pluraliseHelp(count, "This script module", "has been", "has not been", "release", countOfReleasesUserCannotSee);
    }

    languageSpecificHelpText(syntax: ScriptingLanguage): string {
        switch (syntax) {
            case ScriptingLanguage.PowerShell:
                return "This script will be written as a PowerShell module (a `.psm1` file). " +
                       "Functions and cmdlets from this module will be automatically available for use in PowerShell deployment scripts.";
            case ScriptingLanguage.Bash:
                return "This script will be be written as a Bash script next to the executing script. " +
                    (this.scriptModuleNameIsValid()
                        ? "Import this script via `source " + this.convertScriptModuleNameToModuleName() + ".sh` to use in deployment scripts."
                        : "");
            case ScriptingLanguage.CSharp:
                return "This script will be be written as a C# module next to the executing script. " +
                    (this.scriptModuleNameIsValid()
                        ? "Import this script via `#load " + this.convertScriptModuleNameToModuleName() + ".csx` to use in deployment scripts."
                        : "");
            case ScriptingLanguage.FSharp:
                return "This script will be be written as an F# module next to the executing script. " +
                    (this.scriptModuleNameIsValid()
                        ? "Import this script via `#load \"" + this.convertScriptModuleNameToModuleName() + ".fsx\"` to use in deployment scripts."
                        : "");
            case ScriptingLanguage.Python:
                return "This script will be written as Python module next to the executing script. " +
                       (this.scriptModuleNameIsValid()
                           ? "Import this script via `import " + this.convertScriptModuleNameToModuleName() + "` to use in deployment scripts."
                           : "");
        }
    }

    private pluraliseSummary(count: number, prefix: string, used: string, notUsed: string, type: string, notVisibleCount: number) {
        const plural = (count + notVisibleCount) === 1 ? "" : "s";
        const suffix = notVisibleCount > 0 ? `, ${notVisibleCount} of which you do not have permission to see` : "";
        return count > 0
            ? Summary.summary(<span>{prefix} {used} used in <b>{count + notVisibleCount}</b> {type}{plural}{suffix}</span>)
            : notVisibleCount > 0
                ? Summary.placeholder(`${prefix} ${used} used in ${notVisibleCount} ${type}${notVisibleCount === 1 ? "" : "s"} that you do not have permission to see`)
                : Summary.placeholder(`${prefix} ${notUsed} used in any ${type}s${suffix}`);
    }

    private pluraliseHelp(count: number, prefix: string, used: string, notUsed: string, type: string, notVisibleCount: number) {
        const plural = count === 1 ? "" : "s";
        const suffix = notVisibleCount > 0 ? `, as well as ${notVisibleCount} ${type}${(notVisibleCount === 1 ? "" : "s")} that you do not have permission to see` : "";
        return count > 0
            ? `${prefix} ${used} used in the following ${type}${plural}${suffix}`
            : notVisibleCount > 0
                ? `${prefix} ${used} used in ${notVisibleCount} ${type}${notVisibleCount === 1 ? "" : "s"} that you do not have permission to see`
                : `${prefix} ${notUsed} used in any ${type}s${suffix}`;
    }

    private projectUsageSummary() {
        return this.getProjectUsageSummary(this.state.usagesInProjects.length, this.state.countOfProjectsUserCannotSee);
    }

    private projectUsageHelp() {
        return this.getProjectUsageHelp(this.state.usagesInProjects.length, this.state.countOfProjectsUserCannotSee);
    }

    private releaseUsageSummary() {
        const usageCount = this.state.usagesInReleaseSnapshots.reduce((prevValue, currValue) => prevValue + currValue.Releases.length, 0);
        return this.getReleaseUsageSummary(usageCount, this.state.countOfReleasesUserCannotSee);
    }

    private releaseUsageHelp() {
        const usageCount = this.state.usagesInReleaseSnapshots.reduce((prevValue, currValue) => prevValue + currValue.Releases.length, 0);
        return this.getReleaseUsageHelp(usageCount, this.state.countOfReleasesUserCannotSee);
    }

    private handleSyntaxChange(syntax: ScriptingLanguage): void {
        const sampleScriptBody = this.getSampleScriptBody(this.state.model.syntax);

        return this.setModelState({
            syntax,
            scriptBody: this.state.model.scriptBody === sampleScriptBody
                ? this.getSampleScriptBody(syntax)
                : this.state.model.scriptBody
        });
    }

    private buildModel(scriptModule: LibraryVariableSetResource, script: string, syntax: ScriptingLanguage, vs: VariableSetResource): ScriptModuleModel {
        const thisModel: ScriptModuleModel = {
            Id: scriptModule.Id,
            Name: scriptModule.Name,
            Description: scriptModule.Description,
            VariableSetId: scriptModule.VariableSetId,
            ContentType: scriptModule.ContentType,
            Templates: scriptModule.Templates,
            Links: scriptModule.Links,
            scriptBody: script,
            syntax,
            variableSet: vs
        };
        return thisModel;
    }

    private scriptModuleNameIsValid(): boolean {
        return !!this.convertScriptModuleNameToModuleName();
    }

    private convertScriptModuleNameToModuleName(): string {
        return this.state.model &&
               this.state.model.Name &&
               this.state.model.Name.replace(/[^0-9a-z_]/gi, "");
    }

    private descriptionSummary() {
        return this.state.model.Description
            ? Summary.summary(<Markdown markup={this.state.model.Description} />)
            : Summary.placeholder("No description provided");
    }

    private scriptBodySummary() {
        const syntax = this.state.model.syntax;
        if (!this.state.model.scriptBody) {
            return Summary.placeholder("The script body has not been defined");
        } else if (syntax === ScriptingLanguage.FSharp) {
            return Summary.summary(<span>An <strong>F#</strong> script has been defined</span>);
        } else if (syntax === ScriptingLanguage.CSharp) {
            return Summary.summary(<span>A <strong>C#</strong> script has been defined</span>);
        }
        return Summary.summary(<span>A <strong>{syntax}</strong> script has been defined</span>);
    }

    private handleSaveClick = async (redirectToTest: boolean) => {
        await this.doBusyTask(async () => {
            const isNew = this.state.model.Id == null;
            const result = await repository.LibraryVariableSets.save(this.state.model);
            let variableSet = null;
            if (this.state.model.scriptBody) {
                variableSet = await repository.Variables.get(result.VariableSetId);
                const bodyVar = this.getBodyVariable(variableSet) || this.addEmptyVariable(variableSet);
                bodyVar.Name = "Octopus.Script.Module[" + this.state.model.Name + "]";
                bodyVar.Value = this.state.model.scriptBody;
                const syntaxVar = this.getSyntaxVariable(variableSet) || this.addEmptyVariable(variableSet);
                syntaxVar.Name = "Octopus.Script.Module.Language[" + this.state.model.Name + "]";
                syntaxVar.Value = this.state.model.syntax;
                await repository.Variables.save(variableSet);
            }
            const thisModel = this.buildModel(result, this.state.model.scriptBody, this.state.model.syntax, variableSet);
            this.setState({
                model: thisModel,
                cleanModel: cloneDeep(thisModel),
                newId: isNew ? result.Id : null,
            });
        });
    }

    private handleDeleteConfirm = async () => {
        const result = await repository.LibraryVariableSets.del(this.state.model);
        this.setState(state => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true
            };
        });
        return true;
    }

    private addEmptyVariable(variableSet: VariableSetResource) {
        const emptyVariable: VariableResource = {
            Id: "",
            Name: "",
            Value: "",
            Description: null,
            Scope: {},
            IsEditable: false,
            Prompt: null,
            Type: VariableType.String,
            IsSensitive: false
        };
        variableSet.Variables.push(emptyVariable);
        return emptyVariable;
    }
}

export default ScriptModule;
