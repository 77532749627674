exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_preWrapped__2WHzi {\n  position: relative;\n  float: left;\n  width: 100%;\n  padding: 0.5rem;\n  margin: 0 0 0.5rem;\n  font-size: 9pt;\n  word-break: break-all;\n  word-wrap: break-word;\n  background-color: white;\n  border: 0.0625rem dashed #cccccc;\n  border-radius: 0.125rem;\n}\n.style_preWrapped__2WHzi div > ins {\n  border-bottom: 0.125rem solid #48B350 !important;\n  padding: 0.1rem 0.375rem;\n  text-decoration: unset!important;\n}\n.style_preWrapped__2WHzi div > del {\n  border-bottom: 0.125rem solid #db4437 !important;\n  padding: 0.1rem 0.375rem;\n}\n.style_changeIndicatorOverlay__2ohws {\n  margin: 0.5rem 0;\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  top: 0;\n  left: 0;\n}\n.style_changeLine__1Okcp {\n  position: absolute;\n  min-height: 1.3125rem;\n  width: 100%;\n  background-color: #f7f7f7;\n  border-left: 0.125rem solid #cccccc;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n.style_changeSet__1UZ1O {\n  position: relative;\n  width: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"preWrapped": "style_preWrapped__2WHzi",
	"changeIndicatorOverlay": "style_changeIndicatorOverlay__2ohws",
	"changeLine": "style_changeLine__1Okcp",
	"changeSet": "style_changeSet__1UZ1O"
};