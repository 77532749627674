import * as React from "react";
import {repository} from "clientInstance";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import PaperLayout from "components/PaperLayout";
import {
    LetsEncryptConfigurationResource,
    OctopusServerNodeResource,
    OctopusServerNodeSummaryResource,
    ServerConfigurationResource,
    TaskResource
} from "client/resources";
import * as _ from "lodash";
import SimpleDataTable from "components/SimpleDataTable/SimpleDataTable";
import {alert, danger, success} from "theme/colors";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import ToolTip from "components/ToolTip/index";
import OverflowMenu from "components/Menu/OverflowMenu";
import {EditServerConfiguration} from "areas/configuration/components/Nodes/EditServerConfiguration";
import {ChangeTaskCap} from "areas/configuration/components/Nodes/ChangeTaskCap";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import SidebarLayout from "components/SidebarLayout/SidebarLayout";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import {NavigationButton} from "components/Button/NavigationButton";
import routeLinks from "routeLinks";
const styles = require("./style.less");
import { TaskFilterState } from "areas/tasks/components/Tasks/Tasks";
import {Refresh} from "components/DataBaseComponent/DataBaseComponent";
import * as moment from "moment";
import Section from "components/Section";
import UseLabelStrategy from "components/LabelStrategy/LabelStrategy";
import { SelectInternal } from "components/form/Select/Select";
import DateFormatter from "utils/DateFormatter";
import { ActionButtonType } from "components/Button";

const SelectWithoutPrefix = UseLabelStrategy(SelectInternal, fieldName => fieldName);

export enum NodesFilterState {
    RecentlyActive = "RecentlyActive",
    Running = "Running",
    Draining = "Draining",
    Drained = "Drained",
    Offline = "Offline",
}

interface NodesState extends DataBaseComponentState {
    nodes?: OctopusServerNodeSummaryResource[];
    filteredNodes?: OctopusServerNodeSummaryResource[];
    serverConfiguration?: ServerConfigurationResource;
    letsEncryptConfiguration?: LetsEncryptConfigurationResource;
    latestLetsEncryptTasks?: Array<TaskResource<any>>;
    hasLoadedOnce?: boolean;
    filter: NodesFilterState;
}

class NodeTable extends SimpleDataTable<OctopusServerNodeSummaryResource> {}

function filterNodes(nodes: OctopusServerNodeSummaryResource[], filter: NodesFilterState, cutoffDate: moment.Moment): OctopusServerNodeSummaryResource[] {
    return nodes.filter(node => {
        switch (filter) {
            case NodesFilterState.RecentlyActive:
                //Tuesday, September 4, 2018 12:05:12 PM
                const lastSeen: moment.Moment = moment(node.LastSeen);
                return !node.IsOffline || lastSeen > cutoffDate;
            case NodesFilterState.Running:
                return !node.IsOffline && !node.IsInMaintenanceMode;
            case NodesFilterState.Draining:
                return !node.IsOffline && node.IsInMaintenanceMode && node.RunningTaskCount;
            case NodesFilterState.Drained:
                return !node.IsOffline && node.IsInMaintenanceMode && !node.RunningTaskCount;
            case NodesFilterState.Offline:
                return node.IsOffline;
            default:
                return true;
        }
    });
}

export class Nodes extends DataBaseComponent<{}, NodesState> {
    constructor(props: {}) {
        super(props);
        this.state = { filter: NodesFilterState.RecentlyActive };
    }

    componentDidMount() {
        this.doBusyTask(async () => {
            this.doRefresh = await this.startRefreshLoop(() => this.refresh(), 5000);
        });
    }

    async refresh() {
        const getServerConfiguration = repository.ServerConfiguration.get();
        const nodes = (await repository.OctopusServerNodes.summary()).Nodes;
        const filteredNodes = filterNodes(nodes, this.state.filter, moment().add(-1, "hours"));
        return {
            nodes,
            filteredNodes,
            serverConfiguration: await getServerConfiguration,
            hasLoadedOnce: true,
        };
    }

    save(node: OctopusServerNodeResource) {
        return this.doBusyTask(() => repository.OctopusServerNodes.modify(node));
    }

    render() {
        const configurationSettingsButton =
            <PermissionCheck permission={Permission.AdministerSystem}>
                <NavigationButton label="Server Settings" href={routeLinks.configuration.nodes.serverSettings} />
            </PermissionCheck>;

        const table = this.state.nodes && this.state.filteredNodes &&
            <NodeTable data={this.state.filteredNodes}
                       headerColumns={["Name", "Rank", "Status", "Last Seen", "Task Cap", "Running Tasks", null]}
                       onRow={this.onRow}
            />;

        const sidebar = this.state.serverConfiguration && <div>
            <h4>Server Uri</h4>
            <div className={styles.serverUri}>{this.state.serverConfiguration.ServerUri || "Not Set"}</div>
            <OpenDialogButton label="Change">
                <EditServerConfiguration onSaveDone={serverConfiguration => this.setState({serverConfiguration})}/>
            </OpenDialogButton>
        </div>;

        const stateFilter = this.state.nodes && this.state.nodes.length > 1 &&
            <Section className={styles.filterBoxStandardWidth}>
                    <SelectWithoutPrefix value={this.state.filter}
                            onChange={(state: NodesFilterState) => this.search(state)}
                            items={Object.keys(NodesFilterState).map((value) => ({value, text: value.split(/(?=[A-Z])/).join(" ")}))}
                            allowClear={true}
                            fieldName="Filter by"
                            hintText="All nodes"
                    />
                </Section>;

        return <PaperLayout title="Nodes"
                         sectionControl={configurationSettingsButton}
                         busy={this.state.busy}
                         enableLessIntrusiveLoadingIndicator={this.state.hasLoadedOnce}
                         errors={this.state.errors}>
                {stateFilter}
                <SidebarLayout sideBar={sidebar}>{table}</SidebarLayout>
            </PaperLayout>;
    }

    search(filter: NodesFilterState) {
        this.setState({filter}, async () => await this.doRefresh());
    }

    private onRow = (node: OctopusServerNodeSummaryResource) => {

        const status = node.IsOffline
            ? <ToolTip key="offline" content="This Octopus Server node is offline">
                <span style={({color: danger})}>Offline</span>
            </ToolTip>
            : node.IsInMaintenanceMode
                ? <ToolTip key="drain" content="New tasks are prevented from executing on this node">
                    <span style={({color: alert})}>{node.RunningTaskCount ? "Draining" : "Drained"}</span>
                </ToolTip>
                : <span style={({color: success})}>Running</span>;

        const lastSeen = node.LastSeen
            ? node.IsOffline
                ? <span style={({color: danger})}>{DateFormatter.dateToLongFormatWithSeconds(node.LastSeen)}</span>
                : DateFormatter.dateToLongFormatWithSeconds(node.LastSeen)
            : <span style={({color: danger})}>Never</span>;

        const overflowMenu = <OverflowMenu menuItems={this.getOverflowMenuItems(node)}/>;

        return [
            node.Name,
            node.Rank,
            status,
            lastSeen,
            node.MaxConcurrentTasks,
            <InternalLink key={node.Id}
                to={routeLinks.tasks.filtered({node: node.Id, state: TaskFilterState.Running, spaces: [], includeSystem: true})}>{this.getTaskRunningText(node.RunningTaskCount)}</InternalLink>,
            overflowMenu
        ];
    }

    private getOverflowMenuItems(node: OctopusServerNodeSummaryResource) {

        const changeTaskCap =
            OverflowMenu.dialogItem(
                "Change Task Cap",
                <ChangeTaskCap nodeId={node.Id} onSaveDone={this.doRefresh}/>
            );

        const maintMode = OverflowMenu.item(
            node.IsInMaintenanceMode ? "Disable Node Drain" : "Drain Node",
            () => this.setIsInMaintenanceMode(node, !node.IsInMaintenanceMode)
        );

        const deleteItem = this.state.nodes.length > 1 &&
            OverflowMenu.deleteItemDefault("node", () => this.deleteNode(node));

        const auditTrail = OverflowMenu.navItem("Audit Trail",
            routeLinks.configuration.eventsRegardingNode(node), null, {
                permission: Permission.EventView,
                wildcard: true
            });

        return [changeTaskCap, maintMode, deleteItem, [auditTrail]]
            .filter(i => !!i);
    }

    private getTaskRunningText(n: number) {
        switch (n) {
            case 0:
                return "No running tasks";
            case 1:
                return "1 running task";
            default:
                return n + " running tasks";
        }
    }

    private async setIsInMaintenanceMode(node: OctopusServerNodeSummaryResource, value: boolean) {
        await this.doBusyTask(async () => {
            const freshNode = await repository.OctopusServerNodes.get(node.Id);
            freshNode.IsInMaintenanceMode = value;
            await repository.OctopusServerNodes.modify(freshNode);
        });
        await this.doRefresh();
    }

    private async deleteNode(node: OctopusServerNodeSummaryResource) {
        await repository.OctopusServerNodes.del(node);
        await this.doRefresh();
        return true;
    }

    private doRefresh: Refresh = () => Promise.resolve();
}

export default Nodes;
export { OctopusServerNodeSummaryResource as OctopusServerNodeSummaryResource, filterNodes };
