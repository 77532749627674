import { PaletteType, Theme as MuiTheme } from "@material-ui/core";
import { PaletteOptions as MuiPaletteOptions, Palette as MuiPalette } from "@material-ui/core/styles/createPalette";
import createMuiTheme, { ThemeOptions as MuiThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import * as colors from "./colors";
import { legacyTheme } from "./legacy";

interface StatusColors {
    danger: string;
    info: string;
}

interface ExtraPaletteOptions {
    status: StatusColors;
}

type OctopusPaletteOptions = ExtraPaletteOptions & MuiPaletteOptions;

const baseColors = {
    primary: colors.octopusBlue,
    secondary: {
      main: colors.octopusBlue["500"],
      dark: colors.octopusBlue["700"],
      light: colors.octopusBlue["A200"],
      contrastText: colors.white
    },
    error: {
      main: colors.danger
    },
    status: {
        danger: colors.alert,
        info: colors.info
    }
};

const lightPalette: OctopusPaletteOptions = {
    ...baseColors,
    type: "light",
    text: {
        // The most important text.
        primary: colors.primaryText, //This controls the remove icon on some chips
        // Secondary text.
        secondary: colors.secondaryText,
        // Disabled text have even lower visual prominence.
        disabled: colors.disable,
        // Text hints.
        hint: colors.ternaryText
    },
    background: {
        paper: colors.white,
        default: "#fafafa"
    },
    divider: colors.divider,
    // The colors used to style the action elements.
    action: {
        // The color of an active action like an icon button.
        active: colors.primaryText,
        // The color of an hovered action.
        hover: colors.hover,
        hoverOpacity: 0.08,
        // The color of a selected action.
        selected: colors.hover,
        // The color of a disabled action.
        disabled: colors.disable, //Not sure why this is going pink
        // The background color of a disabled action.
        disabledBackground: colors.disabledBackground
    }
};

const darkPalette: OctopusPaletteOptions = {
      type: "dark",
      ...baseColors,
      divider: colors.divider
  };

const themePalettes: Record<PaletteType, OctopusPaletteOptions> = {
    light: lightPalette,
    dark: darkPalette
};

const getTheme = (type: PaletteType) => {
    const options = {
        palette: themePalettes[type],
        typography: {
            useNextVariants: true
        }
    };

    return createMuiTheme(options) as Theme;
};

type ThemePaletteType = PaletteType;

interface OctopusPalette extends MuiPalette {
    status: StatusColors;
}
export interface Theme extends MuiTheme {
    palette: OctopusPalette;
}

export default getTheme;
export { themePalettes, getTheme, ThemePaletteType, legacyTheme  };