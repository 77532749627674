import * as React from "react";
import { ControlType, AccountType, SensitiveValue } from "client/resources";
import FormFieldProps from "../form/FormFieldProps";
import CertificateSelect from "../form/CertificateSelect/CertificateSelect";
import { AccountResource } from "../../client/resources/accountResource";
import { BoundAccountSelect } from "../form/AccountSelect/AccountSelect";
import { BoundSensitive } from "components/form/Sensitive/Sensitive";
import { ResetValue } from "../form/BoundField/BoundField";
const styles = require("./style.less");
import { AwsBoundAccountVariableSelect, AzureBoundAccountVariableSelect } from "components/form/AccountSelect/AccountVariableSelect";
import CertificateVariableSelect from "components/form/CertificateSelect/CertificateVariableSelect";
import { ActionTemplateParameterResource } from "../../client/resources/actionTemplateParameterResource";
import selectOptionsToItems from "../form/Select/Options";
import getResetValue from "../form/Sensitive/getResetValue";
import DebounceValue from "components/DebounceValue/DebounceValue";
import Text from "components/form/Text/Text";
import { BoundSelect } from "components/form/Select/Select";
import { BoundStringCheckbox } from "components/form/Checkbox/StringCheckbox";
import { VariableLookupText } from "components/form/VariableLookupText";
import { isEqual } from "lodash";
import Note from "components/form/Note/Note";

import ActionButton, { ActionButtonType } from "components/Button/ActionButton";

export interface SourceItems {
    tenantId?: string;
    projectId?: string;
    accounts?: {
        type: AccountType | AccountType[];
        items: AccountResource[];
        onRequestRefresh(): Promise<any>;
    };
    stepNames?: string[];
}

interface ActionTemplateParameterInputProps extends FormFieldProps<any> {
    parameter: ActionTemplateParameterResource;
    localNames?: string[];
    sourceItems: SourceItems;
    disabled?: boolean;
    error?: string;
    warning?: string;
    projectId?: string;
    validate?(value: any): string;
    onValidate?(value: string): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}

class ActionTemplateParameterInput
    extends React.Component<ActionTemplateParameterInputProps, never> {

    render() {
        return <div className={styles.container} >
            {this.getInputControl()}
        </div>;
    }

    private getInputControl = () => {
        const {
            warning, sourceItems = {}, doBusyTask, value,
            localNames, projectId, error,
            ...rest
        } = this.props;

        const resetValue = getResetValueFromParameter(this.props.parameter);
        const type = this.props.parameter.DisplaySettings["Octopus.ControlType"];
        const label = this.props.parameter.Label || this.props.parameter.Name;
        const formProps = { ...rest, label };
        const evaluatedResetValue = (typeof resetValue === "function" ? resetValue() : resetValue);
        const fieldValue = value !== undefined
            ? value
            : evaluatedResetValue;
        const usingDefaultValue = isEqual(fieldValue, evaluatedResetValue);
        const defaultValueIndicator = evaluatedResetValue && usingDefaultValue
            ? <Note>Using default value.</Note>
            : <Note>
                <a href="#" onClick={e => {
                    e.preventDefault();
                    this.props.onChange(evaluatedResetValue);
                }}>Reset to default</a>
            </Note>;

        switch (type) {
            case ControlType.SingleLineText:
                return <React.Fragment>
                    <VariableLookupText
                        projectId={sourceItems.projectId}
                        localNames={localNames}
                        warning={warning}
                        {...formProps}
                        value={fieldValue} />
                    {defaultValueIndicator}
                </React.Fragment>;
            case ControlType.MultiLineText:
                return <React.Fragment>
                    <VariableLookupText
                        projectId={sourceItems.projectId}
                        localNames={localNames}
                        multiLine={true}
                        warning={warning}
                        {...formProps}
                        value={fieldValue} />
                    {defaultValueIndicator}
                </React.Fragment>;
            case ControlType.Select: {
                const options = selectOptionsToItems(this.props.parameter.DisplaySettings["Octopus.SelectOptions"]);
                const allowClear = this.props.parameter.AllowClear !== undefined ? this.props.parameter.AllowClear : true;
                return <React.Fragment>
                    <BoundSelect
                        variableLookup={{
                            localNames,
                            projectId: sourceItems.projectId
                        }}
                        resetValue={resetValue as string}
                        items={options}
                        warning={warning}
                        allowClear={allowClear}
                        value={fieldValue}
                        {...formProps} />
                    {defaultValueIndicator}
                </React.Fragment>;
            }
            case ControlType.Checkbox: {
                return <React.Fragment>
                    <BoundStringCheckbox
                        variableLookup={{
                            localNames,
                            projectId: sourceItems.projectId
                        }}
                        resetValue={resetValue as string}
                        warning={warning}
                        {...formProps}
                        value={fieldValue} />
                    {defaultValueIndicator}
                </React.Fragment>;
            }
            case ControlType.Sensitive:
                return <React.Fragment>
                    <BoundSensitive
                        variableLookup={{
                            localNames,
                            projectId: sourceItems.projectId
                        }}
                        value={fieldValue}
                        resetValue={resetValue}
                        warning={warning}
                        {...formProps} />
                    {defaultValueIndicator}
                </React.Fragment>;
            case ControlType.StepName:
                const items = sourceItems.stepNames ? sourceItems.stepNames.map(s => ({ value: s, text: s })) : [];
                return <React.Fragment>
                    <BoundSelect
                        variableLookup={{
                            localNames,
                            projectId: sourceItems.projectId
                        }}
                        resetValue={resetValue as string}
                        items={items}
                        value={fieldValue}
                        warning={warning}
                        {...formProps} />
                    {defaultValueIndicator}
                </React.Fragment>;
            case ControlType.Certificate:
                return <React.Fragment>
                    {sourceItems.projectId
                        ? <CertificateVariableSelect
                            value={fieldValue}
                            projectId={sourceItems.projectId}
                            doBusyTask={this.props.doBusyTask}
                            allowClear={true}
                            {...formProps} />
                        : <CertificateSelect
                            value={fieldValue}
                            warning={warning}
                            doBusyTask={doBusyTask}
                            tenantId={this.props.sourceItems.tenantId}
                            allowClear={true}
                            {...formProps} />}
                    {defaultValueIndicator}
                </React.Fragment>;
            case ControlType.AmazonWebServicesAccount:
                return <React.Fragment>
                    {sourceItems.projectId ?
                        <AwsBoundAccountVariableSelect
                            value={fieldValue}
                            resetValue={resetValue as string}
                            projectId={sourceItems.projectId}
                            doBusyTask={this.props.doBusyTask}
                            allowClear={true}
                            {...formProps} /> :
                        <BoundAccountSelect
                            resetValue={resetValue as string}
                            value={fieldValue}
                            onRequestRefresh={sourceItems.accounts ? sourceItems.accounts.onRequestRefresh : this.noop}
                            items={sourceItems.accounts ? sourceItems.accounts.items : []}
                            type={[AccountType.AmazonWebServicesAccount]}
                            warning={warning}
                            allowClear={true}
                            {...formProps} />}
                    {defaultValueIndicator}
                </React.Fragment>;
            case ControlType.AzureAccount:
                return <React.Fragment>
                    {sourceItems.projectId ?
                        <AzureBoundAccountVariableSelect
                            value={fieldValue}
                            resetValue={resetValue as string}
                            projectId={sourceItems.projectId}
                            doBusyTask={this.props.doBusyTask}
                            allowClear={true}
                            {...formProps} /> :
                        <BoundAccountSelect
                            variableLookup={{
                                localNames,
                                projectId: sourceItems.projectId
                            }}
                            resetValue={resetValue as string}
                            value={fieldValue}
                            onRequestRefresh={sourceItems.accounts ? sourceItems.accounts.onRequestRefresh : this.noop}
                            items={sourceItems.accounts ? sourceItems.accounts.items : []}
                            type={sourceItems.accounts ? sourceItems.accounts.type : []}
                            warning={warning}
                            allowClear={true}
                            {...formProps} />}
                    {defaultValueIndicator}
                </React.Fragment>;
            default:
                return <Text warning={warning} {...formProps} value={fieldValue} />;
        }
    }

    private noop = () => {
        return Promise.resolve();
    }
}

function getResetValueFromParameter(parameter: ActionTemplateParameterResource): ResetValue<SensitiveValue> {
    return parameter.DisplaySettings &&
        parameter.DisplaySettings["Octopus.ControlType"] === "Sensitive" ? getResetValue(parameter.DefaultValue) : parameter.DefaultValue;
}

export default DebounceValue(ActionTemplateParameterInput);
export { ActionTemplateParameterInputProps };