exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_addRowsContainer__22VqO {\n  width: 100%;\n  height: 100%;\n}\n.style_addRowBackground__3IBnH {\n  background-color: rgba(153, 153, 153, 0.08);\n}\n.style_controlCell__n8iEc {\n  align-items: center;\n  display: flex;\n  justify-content: flex-end;\n  height: 100%;\n}\n.style_scopeCell__mWQVC {\n  height: 100%;\n}\n.style_actions__z14aN {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 0.5rem;\n  border-bottom: 2px solid #cccccc;\n  background-color: #ffffff;\n}\n.style_scopeWrapper__3sNtU {\n  flex: 1 1 auto;\n}\n", ""]);

// exports
exports.locals = {
	"addRowsContainer": "style_addRowsContainer__22VqO",
	"addRowBackground": "style_addRowBackground__3IBnH",
	"controlCell": "style_controlCell__n8iEc",
	"scopeCell": "style_scopeCell__mWQVC",
	"actions": "style_actions__z14aN",
	"scopeWrapper": "style_scopeWrapper__3sNtU"
};