import * as React from "react";
import { ResourceCollection, EventResource, EventAgentResource, SpaceResource, ProjectGroupResource } from "client/resources";
import { client, repository, session } from "clientInstance";
import PaperLayout from "components/PaperLayout/PaperLayout";
import DateFormatter from "utils/DateFormatter";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import { ActionButton, ActionButtonType } from "components/Button";
import { EventFilterSelections, EventFilter } from "components/EventFilter";
import {
    OverflowMenuDisabledItem, OverflowMenuNavLink
} from "components/Menu/OverflowMenu";
import {
    TeamResource,
    EnvironmentResource,
    UserResource,
    ProjectResource,
    EventCategoryResource,
    EventGroupResource,
    DocumentTypeResource,
    TenantResource
} from "client/resources";
import * as URI from "urijs";
import * as moment from "moment";
import DateRangeIcon from "material-ui/svg-icons/action/date-range";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import DateRangePicker from "components/DateRangePicker/DateRangePicker";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
const styles = require("./style.less");
import OverflowMenu from "components/Menu/OverflowMenu";
import ActionList from "components/ActionList/ActionList";
import AdvancedFilterLayout from "components/AdvancedFilterLayout";
import { FilterSection } from "components/AdvancedFilterLayout";
import { arrayValueFromQueryString } from "utils/ParseHelper/ParseHelper";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import Callout, { CalloutType } from "components/Callout/Callout";
import RequestRaceConditioner from "utils/RequestRaceConditioner";
import { QueryStringFilters, IQuery } from "components/QueryStringFilters/QueryStringFilters";
import { Moment } from "moment";
import FormPage from "components/FormPage/FormPage";
import routeLinks from "../../../../routeLinks";
import Note from "components/form/Note/Note";
import List from "components/List";
import AuditListRow from "components/AuditListRow/AuditListRow";
import store from "../../../../store";
import { onExpanderContainerDestroyed, defaultContainerKey } from "components/form/Sections/reducers/expanders";

class AuditDataList extends List<EventResource> {
}

export interface AuditQuery extends IQuery {
    environments?: string[];
    users?: string[];
    projects?: string[];
    projectGroups?: string[];
    eventCategories?: string[];
    eventGroups?: string[];
    eventAgents?: string[];
    documentTypes?: string[];
    tenants?: string[];
    tags?: string[];
    from?: string;
    to?: string;
    regardingAny?: string[];
    includeSystem?: string;
    spaces?: string[];
}

interface LookupData {
    environments: EnvironmentResource[];
    teams: TeamResource[];
    users: UserResource[];
    projects: ProjectResource[];
    projectGroups: ProjectGroupResource[];
    eventCategories: EventCategoryResource[];
    eventGroups: EventGroupResource[];
    eventAgents: EventAgentResource[];
    documentTypes: DocumentTypeResource[];
    tenants: TenantResource[];
    spaces: SpaceResource[];
}

interface AuditFilter extends EventFilterSelections {
    StartDate: moment.Moment;
    EndDate: moment.Moment;
    RegardingAny: string[];
    Spaces: string[];
    IncludeSystem: boolean;
}

class FilterLayout extends AdvancedFilterLayout<AuditFilter> { }

const AuditQueryStringFilters = QueryStringFilters.For<AuditFilter, AuditQuery>();

const defaultStartDate = moment().subtract(30, "days").startOf("day");
const defaultEndDate = moment().endOf("day");
const dateFormat = "YYYY-MM-DDTHH:mm:ss.Z"; // This matches the server-side date format for subscription notification deep links, play carefully!

const AuditFormPage = FormPage<LookupData>();
const title = "Audit";

const AuditPage: React.SFC = () => {
    return <AuditFormPage
        title={title}
        load={async () => {
            const environments = isAllowed({ permission: Permission.EnvironmentView, environment: "*" }) ? repository.Environments.all() : Promise.resolve<EnvironmentResource[]>([]);
            const teams = isAllowed({ permission: Permission.TeamView }) ? repository.Teams.all() : Promise.resolve<TeamResource[]>([]);
            const usersPromise = isAllowed({ permission: Permission.UserView }) ? repository.Users.all() : Promise.resolve<UserResource[]>([]);
            const projects = isAllowed({ permission: Permission.ProjectView, wildcard: true })
                ? repository.Projects.all()
                : Promise.resolve<ProjectResource[]>([]);
            const projectGroups = isAllowed({ permission: Permission.ProjectGroupView, projectGroup: "*" })
                ? repository.ProjectGroups.all()
                : Promise.resolve<ProjectGroupResource[]>([]);
            const eventCategories = repository.Events.categories({});
            const eventGroups = repository.Events.groups({});
            const eventAgents = repository.Events.eventAgents();
            const documentTypes = repository.Events.documentTypes({});
            const tenants = repository.Tenants.all();
            const spaces = repository.Users.getSpaces(session.currentUser);

            const users = await usersPromise;

            return {
                environments: await environments,
                teams: await teams,
                users,
                projects: await projects,
                projectGroups: await projectGroups,
                eventCategories: await eventCategories,
                eventGroups: await eventGroups,
                eventAgents: await eventAgents,
                documentTypes: await documentTypes,
                tenants: await tenants,
                spaces: await spaces
            };
        }}
        render={(lookupData: LookupData) => {
            return <AuditLayout
                lookupData={lookupData}
            />;
        }}
    />;
};

interface AuditLayoutProps {
    lookupData: LookupData;
}

interface AuditLayoutState extends DataBaseComponentState {
    eventsResponse?: ResourceCollection<EventResource>;
    filterSelections: AuditFilter;
    queryFilterSelections?: AuditFilter;
    additionalRequestParams?: Map<string, any>;
    exportUrl: string;
}

class AuditLayout extends DataBaseComponent<AuditLayoutProps, AuditLayoutState> {
    private requestRaceConditioner = new RequestRaceConditioner();

    constructor(props: AuditLayoutProps) {
        super(props);

        this.state = {
            filterSelections: createDefaultFilter(),
            exportUrl: ""
        };
    }

    componentDidMount() {
        this.doBusyTask(() => this.reload());
    }

    componentWillUnmount() {
        store.dispatch(onExpanderContainerDestroyed(defaultContainerKey));
    }

    render() {
        const overflowActions = [this.createSubscriptionAction()];
        const refreshButton = <PermissionCheck permission={Permission.EventView} wildcard={true}>
            <ActionButton
                type={ActionButtonType.Secondary}
                label="Refresh"
                onClick={() => this.reload()}
            />
        </PermissionCheck>;
        const exportButton = <PermissionCheck permission={Permission.EventView} wildcard={true}>
            <ActionButton
                type={ActionButtonType.Primary}
                label="Export"
                onClick={() => this.onExportClicked()}
            />
        </PermissionCheck>;
        const actions = overflowActions.length ? [refreshButton, exportButton, <OverflowMenu menuItems={overflowActions} />] : [refreshButton, exportButton];

        return <PaperLayout busy={this.state.busy}
            errors={this.state.errors}
            sectionControl={<ActionList actions={actions} />}
            title={title}>
            <PermissionCheck permission={Permission.EventView} wildcard={true} alternate={
                <Callout type={CalloutType.Information} title={"Permission required"}>
                    The {Permission.EventView} permission is required to view the audit log
                </Callout>}>
                <AuditQueryStringFilters
                    filter={this.state.filterSelections}
                    getQuery={filter => this.queryFromFilters(filter, true)}
                    getFilter={this.getFilterFromQuery}
                    onFilterChange={filters => {
                        this.setState({ queryFilterSelections: filters });
                        this.onFiltersChanged(filters);
                    }} />
                {this.renderContent()}
            </PermissionCheck>
        </PaperLayout>;
    }

    private renderContent() {
        return <FilterLayout
            defaultFilter={createDefaultFilter()}
            filterSections={this.filterSections()}
            filter={this.state.filterSelections}
            queryFilter={this.state.queryFilterSelections}
            onFilterReset={filterSelections => this.onFiltersChanged(filterSelections)}
            additionalHeaderFilters={[this.filterHeader()]}
            renderContent={() => this.state.eventsResponse && <AuditDataList
                initialData={this.state.eventsResponse}
                additionalRequestParams={this.state.additionalRequestParams}
                onRow={(item: EventResource) => <AuditListRow event={item} />}
                onFilter={this.filter}
                filterHintText="Filter by message..."
                showPagingInNumberedStyle={true}
                wrapRowsInListItem={false} // AuditListRow does custom styling on these rows.
            />}
        />;
    }

    private filter(filter: string, resource: EventResource) {
        return !filter || filter.length === 0 || !resource
            || resource.Message.toLowerCase().includes(filter.toLowerCase());
    }

    private filterSections(): FilterSection[] {
        return [{
            render: <div>
                {this.state.filterSelections.RegardingAny && this.state.filterSelections.RegardingAny.length > 0 && <div className={styles.regardingAnyContainer}>
                    <Note style={{ fontSize: "0.875rem" }}>
                        Regarding documents:
                        <ul>{this.state.filterSelections.RegardingAny.map((x, index) => <li key={index}><strong>{x}</strong></li>)}</ul>
                    </Note>
                    <div>
                        <ActionButton type={ActionButtonType.Ternary}
                            onClick={() => {
                                this.onFiltersChanged({ ...this.state.filterSelections, RegardingAny: null });
                            }}
                            label="Clear" />
                    </div>
                </div>}
                <EventFilter
                    doBusyTask={this.doBusyTask}
                    documentTypes={this.props.lookupData.documentTypes}
                    environments={this.props.lookupData.environments}
                    eventCategories={this.props.lookupData.eventCategories}
                    eventGroups={this.props.lookupData.eventGroups}
                    eventAgents={this.props.lookupData.eventAgents}
                    projects={this.props.lookupData.projects}
                    projectGroups={this.props.lookupData.projectGroups}
                    tenants={this.props.lookupData.tenants}
                    users={this.props.lookupData.users}
                    spaces={this.props.lookupData.spaces}
                    selectedDocumentTypes={this.state.filterSelections.DocumentTypes}
                    selectedEnvironments={this.state.filterSelections.Environments}
                    selectedEventCategories={this.state.filterSelections.EventCategories}
                    selectedEventGroups={this.state.filterSelections.EventGroups}
                    selectedEventAgents={this.state.filterSelections.EventAgents}
                    selectedProjects={this.state.filterSelections.Projects}
                    selectedProjectGroups={this.state.filterSelections.ProjectGroups}
                    selectedTenants={this.state.filterSelections.Tenants}
                    selectedTenantTags={this.state.filterSelections.Tags}
                    selectedUsers={this.state.filterSelections.Users}
                    selectedSpaces={this.state.filterSelections.Spaces}
                    includeSystem={this.state.filterSelections.IncludeSystem}
                    onChangeDocumentTypes={DocumentTypes => this.onFiltersChanged({ ...this.state.filterSelections, DocumentTypes })}
                    onChangeEnvironments={Environments => this.onFiltersChanged({ ...this.state.filterSelections, Environments })}
                    onChangeEventCategories={EventCategories => this.onFiltersChanged({ ...this.state.filterSelections, EventCategories })}
                    onChangeEventGroups={EventGroups => this.onFiltersChanged({ ...this.state.filterSelections, EventGroups })}
                    onChangeEventAgents={EventAgents => this.onFiltersChanged({ ...this.state.filterSelections, EventAgents })}
                    onChangeProjects={Projects => this.onFiltersChanged({ ...this.state.filterSelections, Projects })}
                    onChangeProjectGroups={ProjectGroups => this.onFiltersChanged({ ...this.state.filterSelections, ProjectGroups })}
                    onChangeTenants={Tenants => this.onFiltersChanged({ ...this.state.filterSelections, Tenants })}
                    onChangeTenantTags={Tags => this.onFiltersChanged({ ...this.state.filterSelections, Tags })}
                    onChangeUsers={Users => this.onFiltersChanged({ ...this.state.filterSelections, Users })}
                    onChangeSpaces={Spaces => this.onFiltersChanged({ ...this.state.filterSelections, Spaces })}
                    onIncludeSystemChange={IncludeSystem => this.onFiltersChanged({ ...this.state.filterSelections, IncludeSystem })}
                />
            </div>
        }];
    }

    private filterHeader = () => {
        // endDate is exclusive, so subtract a second to (possibly) get the previous day

        const dateRangeTitle = DateFormatter.dateToCustomFormat(this.state.filterSelections.StartDate, "D MMMM YYYY") + " - " +
            DateFormatter.dateToCustomFormat(this.state.filterSelections.EndDate.clone().subtract(1, "ms"), "D MMMM YYYY");

        return <OpenDialogButton
            label={dateRangeTitle}
            icon={<DateRangeIcon />}
            type={ActionButtonType.Ternary}
            wideDialog={true}>
            <DateRangePicker
                startDate={this.state.filterSelections.StartDate.clone()}
                endDate={this.state.filterSelections.EndDate.clone()}
                onSaveDone={async (range) => this.onDateRangePickerChange(range)}
            />
        </OpenDialogButton>;
    }

    private onExportClicked() {
        window.location.href = this.state.exportUrl;
    }

    private async onDateRangePickerChange(range: any) {
        const filterSelections = {
            ...this.state.filterSelections,
            StartDate: range.startDate.clone().startOf("day"),
            EndDate: range.endDate.clone().add(1, "days").startOf("day")
        };

        this.onFiltersChanged(filterSelections);
    }

    private onFiltersChanged = async (filterSelections: AuditFilter) => {
        this.setState({ filterSelections }, async () => {
            await this.reload();
        });
    }

    private createSubscriptionAction(): OverflowMenuDisabledItem | OverflowMenuNavLink {
        const newQS = new URI().search(this.queryFromFilters(this.state.filterSelections, false)).search();

        const createSub = "Create subscription from this filter";

        if (this.state.filterSelections.Spaces.length > 1) {
            return OverflowMenu.disabledItem(createSub,
                "Multiple Spaces are selected, a subscription can only be created for a single Space");
        } else if (this.state.filterSelections.Spaces.length === 0) {
            return OverflowMenu.disabledItem(createSub,
                "A subscription can only be created for your current Space");
        } else if (this.state.filterSelections.Spaces.length === 1 && this.state.filterSelections.Spaces[0] !== client.spaceId) {
            const otherSpaceName = this.props.lookupData.spaces.find(s => s.Id === this.state.filterSelections.Spaces[0]).Name;
            return OverflowMenu.disabledItem(createSub,
                `A subscription can only be created for your current Space, you will need to switch to the '${otherSpaceName}' Space to create it.`);
        }

        return OverflowMenu.navItem(createSub, routeLinks.configuration.subscriptions.create, newQS, {
            permission: Permission.SubscriptionEdit,
        });
    }

    private queryFromFilters(filterSelections: AuditFilter, includeDates: boolean): AuditQuery {
        return {
            users: filterSelections.Users,
            projects: filterSelections.Projects,
            projectGroups: filterSelections.ProjectGroups,
            environments: filterSelections.Environments,
            eventGroups: filterSelections.EventGroups,
            eventCategories: filterSelections.EventCategories,
            eventAgents: filterSelections.EventAgents,
            tenants: filterSelections.Tenants,
            tags: filterSelections.Tags,
            documentTypes: filterSelections.DocumentTypes,
            from: filterSelections.StartDate && includeDates ? filterSelections.StartDate.format(dateFormat) : undefined,
            to: filterSelections.EndDate && includeDates ? filterSelections.EndDate.format(dateFormat) : undefined,
            regardingAny: filterSelections.RegardingAny,
            includeSystem: filterSelections.IncludeSystem ? "true" : "false",
            spaces: filterSelections.Spaces
        };
    }

    private getFilterFromQuery = (query: AuditQuery): AuditFilter => {
        return {
            Users: arrayValueFromQueryString(query.users),
            Projects: arrayValueFromQueryString(query.projects),
            ProjectGroups: arrayValueFromQueryString(query.projectGroups),
            Environments: arrayValueFromQueryString(query.environments),
            EventGroups: arrayValueFromQueryString(query.eventGroups),
            EventCategories: arrayValueFromQueryString(query.eventCategories),
            EventAgents: arrayValueFromQueryString(query.eventAgents),
            Tenants: arrayValueFromQueryString(query.tenants),
            Tags: arrayValueFromQueryString(query.tags),
            DocumentTypes: arrayValueFromQueryString(query.documentTypes),
            StartDate: getDate(query.from, defaultStartDate),
            EndDate: getDate(query.to, defaultEndDate),
            RegardingAny: arrayValueFromQueryString(query.regardingAny),
            Spaces: arrayValueFromQueryString(query.spaces),
            IncludeSystem: query.includeSystem === "true"
        };

        function getDate(dateQueryString: string | undefined, defaultDate: Moment) {
            if (dateQueryString) {
                const parsedDate = moment(dateQueryString, dateFormat);
                if (parsedDate.isSame(defaultDate, "second")) {
                    return defaultDate;
                }
                return parsedDate;
            }
            return defaultDate;
        }
    }

    private getAdditionalRequestParams(): Map<string, any> | undefined {
        const filterSelections = this.state.filterSelections;

        function isNotEmpty<T>(items: T[]) {
            return items.length !== 0;
        }

        const additionalRequestParams = new Map<string, any>();
        if (isNotEmpty(filterSelections.Users)) {
            additionalRequestParams.set("users", filterSelections.Users);
        }
        if (isNotEmpty(filterSelections.Projects)) {
            additionalRequestParams.set("projects", filterSelections.Projects);
        }
        if (isNotEmpty(filterSelections.ProjectGroups)) {
            additionalRequestParams.set("projectGroups", filterSelections.ProjectGroups);
        }
        if (isNotEmpty(filterSelections.Environments)) {
            additionalRequestParams.set("environments", filterSelections.Environments);
        }
        if (isNotEmpty(filterSelections.EventGroups)) {
            additionalRequestParams.set("eventGroups", filterSelections.EventGroups);
        }
        if (isNotEmpty(filterSelections.EventCategories)) {
            additionalRequestParams.set("eventCategories", filterSelections.EventCategories);
        }
        if (isNotEmpty(filterSelections.EventAgents)) {
            additionalRequestParams.set("eventAgents", filterSelections.EventAgents);
        }
        if (isNotEmpty(filterSelections.Tags)) {
            additionalRequestParams.set("tags", filterSelections.Tags);
        }
        if (isNotEmpty(filterSelections.Tenants)) {
            additionalRequestParams.set("tenants", filterSelections.Tenants);
        }
        if (isNotEmpty(filterSelections.DocumentTypes)) {
            additionalRequestParams.set("documentTypes", filterSelections.DocumentTypes);
        }
        if (filterSelections.StartDate) {
            additionalRequestParams.set("from", filterSelections.StartDate.format());
        }
        if (filterSelections.EndDate) {
            additionalRequestParams.set("to", filterSelections.EndDate.format());
        }
        if (isNotEmpty(filterSelections.RegardingAny)) {
            additionalRequestParams.set("regardingAny", filterSelections.RegardingAny);
        }
        return additionalRequestParams;
    }

    private async reload() {
        const filterSelections = this.state.filterSelections;

        function isNotEmpty<T>(items: T[]) {
            return items.length !== 0;
        }
        // Recall API.
        const eventListOptions: AuditQuery & { asCsv?: boolean } = {};
        if (isNotEmpty(filterSelections.Users)) {
            eventListOptions.users = filterSelections.Users;
        }
        if (isNotEmpty(filterSelections.Projects)) {
            eventListOptions.projects = filterSelections.Projects;
        }
        if (isNotEmpty(filterSelections.ProjectGroups)) {
            eventListOptions.projectGroups = filterSelections.ProjectGroups;
        }
        if (isNotEmpty(filterSelections.Environments)) {
            eventListOptions.environments = filterSelections.Environments;
        }
        if (isNotEmpty(filterSelections.EventGroups)) {
            eventListOptions.eventGroups = filterSelections.EventGroups;
        }
        if (isNotEmpty(filterSelections.EventCategories)) {
            eventListOptions.eventCategories = filterSelections.EventCategories;
        }
        if (isNotEmpty(filterSelections.EventAgents)) {
            eventListOptions.eventAgents = filterSelections.EventAgents;
        }
        if (isNotEmpty(filterSelections.Tags)) {
            eventListOptions.tags = filterSelections.Tags;
        }
        if (isNotEmpty(filterSelections.Tenants)) {
            eventListOptions.tenants = filterSelections.Tenants;
        }
        if (isNotEmpty(filterSelections.DocumentTypes)) {
            eventListOptions.documentTypes = filterSelections.DocumentTypes;
        }

        eventListOptions.spaces = getSpacesFilter();
        eventListOptions.includeSystem = filterSelections.IncludeSystem ? "true" : "false";
        eventListOptions.from = filterSelections.StartDate.format();
        eventListOptions.to = filterSelections.EndDate.format();
        if (isNotEmpty(filterSelections.RegardingAny)) {
            eventListOptions.regardingAny = filterSelections.RegardingAny;
        }

        await this.doBusyTask(async () => {
            const eventPromise = isAllowed({ permission: Permission.EventView, wildcard: true })
                ? repository.Events.list(eventListOptions)
                : Promise.resolve([] as any as ResourceCollection<EventResource>);

            const eventAgents = await repository.Events.eventAgents();
            this.props.lookupData.eventAgents = eventAgents;

            await this.requestRaceConditioner.avoidStaleResponsesForRequest(eventPromise, (response) => {
                const eventsResponse = response as ResourceCollection<EventResource>;
                eventListOptions.asCsv = true;
                const exportUrl = client.resolveLinkTemplate("Events", eventListOptions);
                const additionalRequestParams = this.getAdditionalRequestParams();

                this.setState({
                    eventsResponse,
                    exportUrl,
                    additionalRequestParams
                });
            });
        });

        function getSpacesFilter() {
            const hasEventViewInAnySpace = session.currentPermissions.hasPermissionInAnyScope(Permission.EventView);

            if (filterSelections.Spaces.length === 0) {
                if (hasEventViewInAnySpace) {
                    return ["all"];
                } else {
                    return [];
                }
            }

            return filterSelections.Spaces;
        }
    }
}

// We may not want the dates to be on this object, so that when the user hits the reset button, the dates are unaffected
// Although on the other hand, you probably want them to 'reset' to the default range
// to be consistent with the rest of the app... This is the way it is currently implemented
function createDefaultFilter(): AuditFilter {

    const hasEventViewInCurrentSpace = session.currentPermissions.scopeToSpace(client.spaceId).hasPermissionInAnyScope(Permission.EventView);
    const shouldFilterToCurrentSpace = client.spaceId && hasEventViewInCurrentSpace;
    const includeSystem = !shouldFilterToCurrentSpace;

    return {
        Users: [],
        Projects: [],
        ProjectGroups: [],
        Environments: [],
        EventGroups: [],
        EventCategories: [],
        EventAgents: [],
        Tenants: [],
        Tags: [],
        DocumentTypes: [],
        StartDate: defaultStartDate,
        EndDate: defaultEndDate,
        RegardingAny: [],
        Spaces: shouldFilterToCurrentSpace ? [client.spaceId] : [],
        IncludeSystem: includeSystem
    };
}

export default AuditPage;
