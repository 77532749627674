import { WorkerPoolResource } from "../../../../client/resources/index";
import { ActionExecutionLocation } from "../../../../client/resources";
import * as React from "react";
import { ExpandableFormSection, Summary, StringRadioButtonGroup, RadioButton, Note, Select, BooleanRadioButtonGroup, UnstructuredFormSection } from "components/form";
import ParseHelper from "utils/ParseHelper/ParseHelper";
import { RunOn, TargetRoles } from "areas/projects/components/DeploymentProcess/ActionDetails";
import { RoleChip, WorkerPoolChip } from "components/Chips";
import { RoleMultiSelect } from "components/MultiSelect";
import MaxParallelism from "./MaxParallelism";
const styles = require("./style.less");
import ExternalLink from "../../../../components/Navigation/ExternalLink/ExternalLink";
import { CardFill } from "../../../../components/form/Sections/ExpandableFormSection";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import ActionButton, { ActionButtonType } from "components/Button";

interface ExecutionPlanProps {
    projectId: string;
    expandedByDefault: boolean;
    executionLocation: ActionExecutionLocation;
    runOn: RunOn;
    targetRoleOption: TargetRoles;
    targetRoles: string;
    targetWorkerPool: string;
    disableAddTargetRoles?: boolean;
    isChildStep: boolean;
    maxParallelism: string;
    availableRoles: string[];
    canRunOnWorker: boolean;
    availableWorkerPools: WorkerPoolResource[];
    isBuiltInWorkerEnabled: boolean;
    targetRolesError: string;
    runsOnServer: boolean;
    onRunOnChanged(runOn: RunOn): void;
    onTargetRolesChanged(roles: string[]): void;
    onTargetWorkerPoolChanged(workerPoolId: string): void;
    onMaxParallelismChanged(max: string): void;
}

interface ExecutionPlanState {
    showWindowSize: boolean;
    targetWorkerPool: string;
}

function resolveWorkerPool(runOn: RunOn, availableWorkerPools: WorkerPoolResource[], workerPool: string | null) {
    if (workerPool) {
        return workerPool;
    }

    if ((runOn === RunOn.WorkerPool || runOn === RunOn.WorkerPoolForRoles)) {
        const found = availableWorkerPools.filter((element) => element.IsDefault).pop();
        return found ? found.Id : null;
    }
}

export default class ExecutionPlan extends React.Component<ExecutionPlanProps, ExecutionPlanState> {
    phaseEnvironments: string[] = [];
    environmentNameMap: any = {};

    constructor(props: ExecutionPlanProps) {
        super(props);

        this.state = {
            showWindowSize: props.maxParallelism ? props.maxParallelism.length > 0 : false,
            targetWorkerPool: resolveWorkerPool(props.runOn, props.availableWorkerPools, props.targetWorkerPool)
        };
    }

    shouldComponentUpdate(nextProps: ExecutionPlanProps, nextState: ExecutionPlanState) {
        return nextProps.runOn !== this.props.runOn
            || nextProps.targetRoles !== this.props.targetRoles
            || nextProps.targetRoleOption !== this.props.targetRoleOption
            || nextProps.maxParallelism !== this.props.maxParallelism
            || nextProps.targetRolesError !== this.props.targetRolesError
            || nextState.showWindowSize !== this.state.showWindowSize
            || nextState.targetWorkerPool !== this.state.targetWorkerPool;
    }

    render() {
        return <div>
            <ExpandableFormSection
                isExpandedByDefault={this.props.expandedByDefault}
                errorKey="ActionExecutionLocation"
                title="Execution Location"
                summary={this.executionLocationSummary()}
                help="Choose the execution location Octopus should use for this step. "
                fillCardWidth={CardFill.FillRight}>
                {this.renderRunOnOptions()}
            </ExpandableFormSection>
            {this.renderRolesOptions()}
        </div>;
    }

    private executionLocationSummary() {
        const summary = [<span>This step will run</span>];
        if (this.state.targetWorkerPool) {
            summary.push(<span> on a <strong>worker</strong> from the worker pool</span>);
        } else {
            // keeping these as four cases not two and combining the if checks because I am
            // not sure of the priority of Octopus.Action.RunOnServer over props.executionLocation
            if (this.props.executionLocation === ActionExecutionLocation.AlwaysOnServer) {
                summary.push(<span> on the <strong>Octopus Server</strong></span>);
            } else if (this.props.executionLocation === ActionExecutionLocation.AlwaysOnTarget) {
                summary.push(<span> on each <strong>deployment target</strong></span>);
            } else if (this.props.runOn === RunOn.DeploymentTarget) {
                summary.push(<span> on each <strong>deployment target</strong></span>);
            } else {
                summary.push(<span> on the <strong>Octopus Server</strong></span>);
            }
        }

        if (this.props.targetRoleOption === TargetRoles.None) {
            summary.push(<p />);
            return Summary.summary(React.Children.toArray(summary));
        }
        if (this.props.runOn === RunOn.OctopusServerForRoles || this.props.runOn === RunOn.WorkerPoolForRoles) {
            summary.push(<span> on behalf of each deployment target</span>);
        }

        return Summary.summary(React.Children.toArray(summary));
    }

    private WorkerPoolSummary() {
        const worker = this.props.availableWorkerPools.find((element) => element.Id === this.state.targetWorkerPool);
        return worker ? <WorkerPoolChip workerPoolName={worker.Name} /> : null;
    }

    private workerPoolsAvailable() {
        return this.props.availableWorkerPools.length > 0 && this.props.canRunOnWorker;
    }

    private renderRunOnOptions = () => {
        const executeTarget = require("./execute-targets.svg");
        const executeWorker = require("./execute-worker.svg");
        const executeWorkerForRole = require("./execute-worker-roles.svg");
        const executeServer = require("./execute-octopus-server.svg");
        const executeServerForRole = require("./execute-octopus-server-roles.svg");

        const alwaysOnServer = this.props.executionLocation === ActionExecutionLocation.AlwaysOnServer;
        if (alwaysOnServer && this.props.targetRoleOption !== TargetRoles.Optional) {
            if (this.workerPoolsAvailable()) {
                // Workers
                return <div>{this.props.targetRoleOption !== TargetRoles.None
                    ? <div className={styles.col}> <span>This step will run once on a <strong>worker</strong> on behalf of each deployment target</span>
                        <img src={executeWorkerForRole} alt="Worker on behalf of roles" />
                        <Note>Execute on a worker on behalf of all the deployment targets in selected roles.</Note>
                    </div>
                    : <div className={styles.col}> <span>This step will run once on a <strong>worker</strong></span>
                        <img src={executeWorker} alt="Worker" />
                        <Note>Execute once on a worker.</Note>
                    </div>}
                </div>;
            } else {
                // Octopus Server
                return <div>{this.props.targetRoleOption !== TargetRoles.None
                    ? <div className={styles.col}> <span>This step will run on the <strong>Octopus Server</strong> on behalf of each deployment target</span>
                        <img src={executeServerForRole} alt="Octopus Server on behalf of roles" />
                        <Note>Execute on a the Octopus Server on behalf of all the deployment targets in selected roles.</Note>
                    </div>
                    : <div className={styles.col}> <span>This step will run on the <strong>Octopus Server</strong></span>
                        <img src={executeServer} alt="Octopus Server" />
                        <Note>Execute once on the Octopus Server.</Note>
                    </div>}
                </div>;
            }
        }

        if (this.props.executionLocation === ActionExecutionLocation.AlwaysOnTarget) {
            return <div className={styles.col}>
                <span>This step will run on each <strong>deployment target</strong></span>
                <img src={executeTarget} alt="Deployment Target" />
                <Note>Execute on each deployment target with the selected roles.</Note>
            </div>;
        }

        return <div className={styles.row}>
            <StringRadioButtonGroup value={this.props.runOn} onChange={this.onRunOnChanged}>
                {(!this.props.isChildStep && this.workerPoolsAvailable()) && <RadioButton value={RunOn.WorkerPool} label="Run once on a worker" />}
                {(!this.props.isChildStep && !this.workerPoolsAvailable()) && <RadioButton value={RunOn.OctopusServer} label="Run on the Octopus Server" />}
                {this.workerPoolsAvailable()
                    ? <RadioButton value={RunOn.WorkerPoolForRoles} label="Run on a worker on behalf of each deployment target" />
                    : <RadioButton value={RunOn.OctopusServerForRoles} label="Run on the Octopus Server on behalf of each deployment target" />}
                {!alwaysOnServer && <RadioButton value={RunOn.DeploymentTarget} label="Run on each deployment target" isDefault={true} />}
            </StringRadioButtonGroup>
            <div className={styles.images}>
                {(this.props.runOn === RunOn.DeploymentTarget) && <TransitionAnimation><img src={executeTarget} alt="Deployment Target" />
                    <Note>Execute on each deployment target with the selected roles.</Note>
                </TransitionAnimation>}
                {(this.props.runOn === RunOn.OctopusServer) && <TransitionAnimation><img src={executeServer} alt="Octopus Server" />
                    <Note>Execute once on the Octopus Server.</Note>
                </TransitionAnimation>}
                {(this.props.runOn === RunOn.OctopusServerForRoles) && <TransitionAnimation><img src={executeServerForRole} alt="Octopus Server on behalf of roles" />
                    <Note>Execute on the Octopus Server on behalf of all the deployment targets in selected roles.</Note>
                </TransitionAnimation>}
                {(this.props.runOn === RunOn.WorkerPool) && <TransitionAnimation><img src={executeWorker} alt="Worker" />
                    <Note>Execute once on a worker.</Note>
                </TransitionAnimation>}
                {(this.props.runOn === RunOn.WorkerPoolForRoles) && <TransitionAnimation><img src={executeWorkerForRole} alt="Worker on behalf of roles" />
                    <Note>Execute on a worker on behalf of all the deployment targets in selected roles.</Note>
                </TransitionAnimation>}
            </div>
        </div>;
    }

    private renderRolesOptions = () => {
        if (this.props.targetRoleOption === TargetRoles.None) {
            return null;
        }

        if (this.props.isChildStep) {
            return (this.props.runsOnServer)
                ? <UnstructuredFormSection>
                    <p className="text-muted">This step is part of a rolling step, which
                    runs on the Octopus Server on behalf of deployment targets in the following
                    roles:
                    {this.roleList(this.props.targetRoles)}
                    </p>
                </UnstructuredFormSection>
                : <UnstructuredFormSection>
                    <p className="text-muted">This step is part of a rolling step, which
                    runs on deployment targets in the following roles:
                    {this.roleList(this.props.targetRoles)}
                    </p>
                </UnstructuredFormSection>;
        }

        const nodes = [];
        if (this.props.runOn === RunOn.WorkerPool || this.props.runOn === RunOn.WorkerPoolForRoles) {
            nodes.push(
                <ExpandableFormSection
                    isExpandedByDefault={this.props.expandedByDefault}
                    summary={Summary.summary(this.WorkerPoolSummary())}
                    title="Worker Pool"
                    errorKey="Octopus.Action.WorkerPoolId"
                    help="Which worker pool should Octopus use for this step?">
                    <Select
                        items={this.props.availableWorkerPools.map((e) => {
                            return { value: e.Id, text: e.Name };
                        })}
                        value={this.state.targetWorkerPool}
                        validate={value => value == null ? "Please select a worker pool" : ""}
                        label={"Runs on a worker from the pool"}
                        onChange={this.onTargetWorkerPoolChange}
                    />
                </ExpandableFormSection>
            );
        }

        const rolePostfix = this.props.disableAddTargetRoles !== true && " (type to add new)"; // Keep this text short for mobile.
        if (this.props.runOn === RunOn.OctopusServerForRoles ||
            this.props.runOn === RunOn.WorkerPoolForRoles ||
            this.props.runOn === RunOn.DeploymentTarget) {
            nodes.push(<div>
                <ExpandableFormSection
                    isExpandedByDefault={this.props.expandedByDefault}
                    summary={this.props.targetRoles
                        ? Summary.summary(this.roleList(this.props.targetRoles))
                        : Summary.placeholder("No roles selected")}
                    title={this.props.runOn === RunOn.DeploymentTarget
                        ? "On Targets in Roles"
                        : "On Behalf Of"}
                    errorKey="Octopus.Action.TargetRoles"
                    help={this.props.runOn === RunOn.DeploymentTarget
                        ? "Run this step on these deployment targets."
                        : "Run this step on behalf of these deployment targets."}
                >
                    <RoleMultiSelect
                        onChange={this.props.onTargetRolesChanged}
                        value={ParseHelper.parseCSV(this.props.targetRoles)}
                        label={this.props.runOn === RunOn.DeploymentTarget
                            ? `Runs on targets in roles${rolePostfix}`
                            : `On behalf of target roles${rolePostfix}`}
                        validate={roles => roles.length === 0 ? "Please enter one or more roles" : ""}
                        error={this.props.targetRolesError}
                        items={this.props.availableRoles}
                        canAdd={this.props.disableAddTargetRoles !== true}
                    />
                    <Note>This step will run on all deployment targets with these roles.</Note>
                    {!this.state.showWindowSize && <React.Fragment>
                        <ActionButton label="Configure a rolling deployment"
                            type={ActionButtonType.Ternary}
                            onClick={() => {
                                this.setState({ showWindowSize: true });
                                this.props.onMaxParallelismChanged("1");
                            }} />
                    </React.Fragment>}
                    {this.state.showWindowSize && <React.Fragment>
                        <ActionButton label="Deploy to all deployment targets in parallel"
                            type={ActionButtonType.Ternary}
                            onClick={() => {
                                this.setState({ showWindowSize: false });
                                this.props.onMaxParallelismChanged("");
                            }} />
                    </React.Fragment>}
                </ExpandableFormSection>
            </div>);

            if (this.state.showWindowSize) {
                nodes.push(<div>
                    <ExpandableFormSection
                        isExpandedByDefault={this.props.expandedByDefault}
                        summary={Summary.summary(this.rollingDeploymentSummary())}
                        title="Rolling Deployment"
                        errorKey="Octopus.Action.RollingDeployment"
                        help="Select the rolling window Octopus should use for this step. ">
                        <Note>Learn more about <ExternalLink href="rolling-deployments">Rolling Deployments</ExternalLink></Note>
                        <MaxParallelism key="maxparallelism"
                            projectId={this.props.projectId}
                            value={this.props.maxParallelism}
                            onChange={(x) => this.props.onMaxParallelismChanged(x)} />
                    </ExpandableFormSection>
                </div>);
            }
        }

        return React.Children.toArray(nodes);
    }

    private rollingDeploymentSummary() {
        return this.state.showWindowSize
            ? <span>This is a rolling deployments step that will run on <strong>{this.props.maxParallelism} target{this.props.maxParallelism !== "1" ? "s" : ""}</strong> at once.</span>
            :
            <span>This step will be run on all deployment targets in parallel.</span>;
    }

    private roleList(csv: string) {
        const list = ParseHelper.parseCSV(csv);
        return list.map(r => <RoleChip role={r} key={"role-" + r} />);
    }

    private onRunOnChanged = (runOn: RunOn) => {
        const targetWorkerPool = resolveWorkerPool(runOn, this.props.availableWorkerPools, null);
        this.setState({ targetWorkerPool }, () => this.props.onTargetWorkerPoolChanged(targetWorkerPool));
        this.props.onRunOnChanged(runOn);
    }

    private onTargetWorkerPoolChange = (workerPool: string) => {
        this.setState({
            targetWorkerPool: workerPool
        }, () => this.props.onTargetWorkerPoolChanged(workerPool));
    }
}