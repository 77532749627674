import * as React from "react";
import { Card, CardTitle } from "material-ui/Card";
import CardExpandable from "./CardExpandable";
const styles = require("./style.less");
import * as cn from "classnames";
import { FormSectionBase, FormSectionBaseProps } from "components/form/Sections/FormSectionBase";
import Expandable, { ExpandableProps, ExpandableContainerProps } from "components/Expandable/Expandable";
import { formSectionClass, formSectionTitleClass } from "uiTestClasses";
import { OverflowMenu } from "components/Menu";
import { MenuItem } from "components/Menu/OverflowMenu";
import { getSummary } from "./Summary";
import { SummaryNode } from "./Summary";
import { Collapse } from "@material-ui/core";
export { SummaryNode };

export enum CardFill {
    None,
    FillRight,
    FillAll
}

//todo: mattr: change all usages of this to use a new FormSection component that switches between this and NonExpandableFormSection
interface ExpandableFormSectionProps extends FormSectionBaseProps {
    summary: SummaryNode | React.ReactNode;
    title: React.ReactNode;
    help?: React.ReactNode; //todo: mattr: make mandatory
    expandable?: boolean; //todo: this prop will move to the new FormSection component
    focusOnExpandAll?: boolean;
    fillCardWidth?: CardFill;
    overflowMenuItems?: Array<MenuItem | MenuItem[]>;
}

type Props = ExpandableFormSectionProps & ExpandableProps & ExpandableContainerProps;

export class ExpandableFormSectionInternal extends FormSectionBase<Props> {
    focusControl = false;
    ignore = false;
    expandable = true;

    constructor(props: Props) {
        super(props);
        this.ignore = this.props.isExpandedByDefault; //don't focus the first time we expand due to being expandedByDefault
        this.expandable = typeof this.props.expandable === "undefined" ? true : this.props.expandable;
    }

    // we can't just use actAsExpander on card Title because it eats the click
    // and we can't add links to the title in help text or our show more info link
    handleExpand = (e: any) => {
        if (e.target.tagName.toLowerCase() !== "a") {
            e.preventDefault();

            const isSelectingText = window.getSelection().toString().length > 0;
            if (isSelectingText) {
                // usability: Don't expand/collapse in cases where the user is just trying to select/copy text
                // from our expander's title, summary or help text.
                return;
            }

            this.props.onExpandedChanged(!this.props.isExpanded);
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.isExpanded === false && nextProps.isExpanded === true) {
            if (this.ignore) {
                this.ignore = false;
            } else {
                this.focusControl = true;
            }
        }
    }

    render() {
        const { forceMobileBehaviour } = this.props;
        const summary = getSummary(this.props.summary, {forceMobileBehaviour: Boolean(forceMobileBehaviour)});
        const subtitle = this.props.isExpanded ? this.help(this.props.help) : summary;
        const title = this.title(this.props.title, subtitle);
        const bodyclasses = cn({
            [styles.cardMedia]: true,
            [styles.cardMediaForceMobile]: this.props.forceMobileBehaviour,
            [styles.cardMediaFillRight]: this.props.fillCardWidth === CardFill.FillRight || this.props.fillCardWidth === CardFill.FillAll,
            [styles.cardMediaFillAll]: this.props.fillCardWidth === CardFill.FillAll
        });

        return <Card
            className={cn(formSectionClass, styles.formExpander)}
            onExpandChange={this.expandable ? this.props.onExpandedChanged : undefined}
            expanded={this.expandable && this.props.isExpanded}
        >
            <CardTitle
                title={title}
                onClick={this.expandable ? this.handleExpand : undefined}
                showExpandableButton={false}
            />
            <Collapse in={this.props.isExpanded} timeout="auto" unmountOnExit={true}>
                {this.body(bodyclasses, true)}
                {this.moreInformation()}
            </Collapse>
        </Card>;
    }

    protected title(title: React.ReactNode, subtitle: React.ReactNode) {
        return <div className={cn(styles.cardTitleContainer, this.props.forceMobileBehaviour && styles.cardTitleContainerForceMobile)}>
            <span className={cn(formSectionTitleClass, styles.cardTitle, this.props.forceMobileBehaviour && styles.cardTitleForceMobile)}>{title}</span>
            {subtitle}
            {this.props.overflowMenuItems && <div className={styles.overflowMenuActions}>
                <OverflowMenu menuItems={this.props.overflowMenuItems} />
            </div>}
            {this.expandable && <div className={styles.expandCollapse}>
                <CardExpandable tabIndex={this.props.isExpanded ? -1 : 0}
                    expanded={this.props.isExpanded} />
            </div>}
        </div>;
    }

    protected alterChild = (child: any): any => {
        const focusOnExpand = this.props.expandingAll === false || this.props.focusOnExpandAll;
        if (focusOnExpand && this.focusControl && this.isFocusableControl(child)) {
            this.focusControl = false;
            return React.cloneElement(child, {
                ...child.props,
                autoFocus: true
            });
        } else {
            return child;
        }
    }

    private isFocusableControl = (child: any) => {
        return child.props && (child.props.onChange || child.props.setProperties) && !child.props.doNotAutoFocus;
    }
}

const ExpandableFormSection = Expandable<ExpandableFormSectionProps>(ExpandableFormSectionInternal);
export { ExpandableFormSectionProps };
export default ExpandableFormSection;
