import * as React from "react";
import ExternalLink from "../Navigation/ExternalLink";
import { repository, session } from "clientInstance";
import Divider from "components/Divider/Divider";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import ErrorPanel from "components/ErrorPanel/ErrorPanel";
import {default as Callout, CalloutType} from "components/Callout/Callout";
import {ServerStatusResource} from "../../client/resources";

const styles = require("./style.less");

interface SystemMessagesBannerState extends DataBaseComponentState {
    browserNotSupported: boolean;
    browserName: string;
    serverStatusMessage: string;
}

export default class SystemMessagesBanner extends DataBaseComponent<{}, SystemMessagesBannerState> {

    // this resource object is needed only once so that we can obtain the health url
    singleUseServerStatusResource: ServerStatusResource = null;

    constructor(props: {}) {
        super(props);

        let browserNotSupported = false;
        let browserName = "";

        const ieVersion = (document as any)["documentMode"];
        if (ieVersion && ieVersion <= 10) {
            browserNotSupported = true;
            browserName = "Internet Explorer " + ieVersion;
        }

        this.state = {
            serverStatusMessage: null,
            browserNotSupported,
            browserName
        };
    }

    async componentDidMount() {
        this.singleUseServerStatusResource = await repository.ServerStatus.getServerStatus();
        this.doBusyTask(async () => {
            await this.startRefreshLoop(() => this.refreshData(), 10000);
        });
    }

    async refreshData() {
        const health = await repository.ServerStatus.getHealth(this.singleUseServerStatusResource)
            .then(succ => succ, err => {
                // Returns 418 if the server node is draining or offline
                // Treat other non 200 as a standard failure
                if (err.StatusCode === 418) {
                    return err;
                }
                throw err;
            });
        return { serverStatusMessage: !health.IsOperatingNormally ? health.Description : null};
    }

    render() {
        return <div className={styles.pageBanners}>
            {this.state.serverStatusMessage && <Callout type={CalloutType.Warning} title={"Server status"}>
                {this.state.serverStatusMessage}
            </Callout>}
            {this.state.errors && <ErrorPanel message={this.state.errors.message} details={this.state.errors.details}/>}
            {this.state.serverStatusMessage && this.state.browserNotSupported && <Divider/>}
            {this.state.browserNotSupported && <Callout type={CalloutType.Warning} title={<span>Your current browser, <strong>{this.state.browserName}</strong>, is not supported in <strong>Octopus\n" + "4</strong>.</span>}>
                    We still allow you to use Octopus but no guarantee that everything works!<br/>
                    If you have any questions or feedback then please share them with us <strong>
                    <ExternalLink
                        href="https://github.com/OctopusDeploy/Issues/issues/3457">here</ExternalLink></strong>.
            </Callout>}
        </div>;
    }
}