var escape = require("../../../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .style_hide-sm__wuaKO {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .style_visible-sm__1tBgH {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .style_hide-md__3a0RZ {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .style_visible-md__2JiwB {\n    display: none !important;\n  }\n}\n.style_container__8Wtl6 {\n  margin: 0.5rem 0;\n  padding: 0 0.5rem;\n}\n.style_ok__2Vmy8 {\n  background-image: url(" + escape(require("../PackagesList/shipment-ok.svg")) + ");\n  line-height: 1.6rem;\n  background-repeat: no-repeat;\n  padding-left: 1.75rem;\n  margin-left: 0;\n  margin-bottom: 0.0625rem;\n  background-size: 1.5rem auto;\n}\n.style_error__SP1JQ {\n  background-image: url(" + escape(require("../PackagesList/shipment-error.svg")) + ");\n  line-height: 1.6rem;\n  background-repeat: no-repeat;\n  padding-left: 1.75rem;\n  margin-left: 0;\n  margin-bottom: 0.0625rem;\n  background-size: 1.5rem auto;\n}\ndfn {\n  font-style: normal;\n  color: #333333;\n  margin-left: 0.5rem;\n}\n.style_metadataTitle__2Q8UM {\n  font-size: 1.0rem;\n}\n.style_metadataSummary__2QLoU {\n  color: #333333;\n  font-size: 0.8125rem;\n}\n@media (max-width: 37.4rem) {\n  .style_metadataSummary__2QLoU {\n    padding: 0;\n  }\n}\n.style_metadataSummaryLabel__2BX5w {\n  width: 12rem;\n  text-align: left;\n  font-weight: 600;\n}\n@media (max-width: 37.4rem) {\n  .style_metadataSummaryLabel__2BX5w {\n    text-align: initial;\n  }\n}\n.style_metadataSummary__2QLoU table {\n  background-color: transparent;\n}\n.style_metadataSummary__2QLoU tr:hover {\n  background-color: transparent !important;\n}\n.style_metadataSummary__2QLoU tr {\n  border: 0 !important;\n}\n.style_metadataSummary__2QLoU td {\n  padding: 0.125rem 0 !important;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "style_hide-sm__wuaKO",
	"hideSm": "style_hide-sm__wuaKO",
	"visible-sm": "style_visible-sm__1tBgH",
	"visibleSm": "style_visible-sm__1tBgH",
	"hide-md": "style_hide-md__3a0RZ",
	"hideMd": "style_hide-md__3a0RZ",
	"visible-md": "style_visible-md__2JiwB",
	"visibleMd": "style_visible-md__2JiwB",
	"container": "style_container__8Wtl6",
	"ok": "style_ok__2Vmy8",
	"error": "style_error__SP1JQ",
	"metadataTitle": "style_metadataTitle__2Q8UM",
	"metadataSummary": "style_metadataSummary__2QLoU",
	"metadataSummaryLabel": "style_metadataSummaryLabel__2BX5w"
};