import * as React from "react";
import {client, repository, session} from "clientInstance";
import {DataBaseComponent, DataBaseComponentState, Refresh} from "components/DataBaseComponent/DataBaseComponent";
import {RouteComponentProps} from "react-router";
import PaperLayout from "components/PaperLayout/PaperLayout";
import * as moment from "moment";
import {EnvironmentResource, OctopusServerNodeResource, ProjectResource, SpaceResource, TaskResource, TaskState, TenantResource, TaskTypeResource} from "client/resources";
import {SpaceMultiSelect} from "components/MultiSelect";
import {PagingDataTable} from "components/PagingDataTable/PagingDataTable";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import Select from "components/form/Select/Select";
import DateFormatter from "utils/DateFormatter";
import TaskDetails from "components/TaskDetails/TaskDetails";
import AdvancedFilterLayout, {AdvancedFilterCheckbox} from "components/AdvancedFilterLayout";
import NavigationButton, {NavigationButtonType} from "components/Button/NavigationButton";
import PermissionCheck, {hasPermission} from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import TaskLayout from "areas/tasks/TaskLayout/TaskLayout";
import {StatsResourceCollection} from "client/repositories/taskRepository";
import routeLinks from "../../../../routeLinks";
import {Feature, FeatureToggle} from "components/FeatureToggle";
import {IQuery, QueryStringFilters} from "components/QueryStringFilters/QueryStringFilters";
import {arrayValueFromQueryString} from "utils/ParseHelper/ParseHelper";
import Callout, {CalloutType} from "../../../../components/Callout";

const styles = require("./style.less");

export interface Filter {
    ids?: string[];
    state?: TaskFilterState;
    project?: string;
    environment?: string;
    name?: string;
    node?: string;
    tenant?: string;
    spaces: string[];
    includeSystem: boolean;
    hasPendingInterruptions?: boolean | null;
    hasWarningsOrErrors?: boolean;
}

export interface TasksQuery extends IQuery {
    serverNode?: string;
    state?: string;
    ids?: string[];
    project?: string;
    environment?: string;
    name?: string;
    tenant?: string;
    spaces?: string[];
    hasPendingInterruptions?: string;
    includeSystem?: string;
    hasWarningsOrErrors?: string;
}

interface TasksState extends DataBaseComponentState {
    tasks?: StatsResourceCollection;
    projects: ProjectResource[];
    environments: EnvironmentResource[];
    nodes: OctopusServerNodeResource[];
    tenants: TenantResource[];
    spaces: SpaceResource[];
    taskTypes: TaskTypeResource[];
    currentPageIndex: number; // We manage our own paging due to automatic refresh / timers.
    filter: Filter;
    hasLoadedOnce?: boolean;
}

class TaskResourceDataTable extends PagingDataTable<TaskResource<any>> {
}

class FilterLayout extends AdvancedFilterLayout<Filter> {
}

export enum TaskFilterState {
    Incomplete = "Incomplete",
    Running = "Running",
    Completed = "Completed",
    Unsuccessful = "Unsuccessful",
    Queued = "Queued",
    Executing = "Executing",
    Cancelling = "Cancelling",
    Success = "Success",
    Canceled = "Canceled",
    TimedOut = "TimedOut",
    Failed = "Failed"
}

function getTaskStatesFromFilterState(taskFilterState: TaskFilterState) {
    switch (taskFilterState) {
        case TaskFilterState.Incomplete:
            return [TaskState.Queued, TaskState.Executing, TaskState.Cancelling].join(",");
        case TaskFilterState.Running:
            return [TaskState.Executing, TaskState.Cancelling].join(",");
        case TaskFilterState.Completed:
            return [TaskState.Canceled, TaskState.Success, TaskState.Failed, TaskState.TimedOut].join(",") ;
        case TaskFilterState.Unsuccessful:
            return [TaskState.Canceled, TaskState.Failed, TaskState.TimedOut].join(",");
        case TaskFilterState.Queued:
            return TaskState.Queued;
        case TaskFilterState.Executing:
            return TaskState.Executing;
        case TaskFilterState.Cancelling:
            return TaskState.Cancelling;
        case TaskFilterState.Success:
            return TaskState.Success;
        case TaskFilterState.Canceled:
            return TaskState.Canceled;
        case TaskFilterState.TimedOut:
            return TaskState.TimedOut;
        case TaskFilterState.Failed:
            return TaskState.Failed;
    }
}

function getFilter(query: TasksQuery): Filter {
    return {
        node: query.serverNode,
        state: TaskFilterState[query.state as keyof typeof TaskFilterState],
        ids: arrayValueFromQueryString(query.ids),
        hasPendingInterruptions: query.hasPendingInterruptions === "true",
        hasWarningsOrErrors: query.hasWarningsOrErrors === "true",
        environment: query.environment,
        project: query.project,
        tenant: query.tenant,
        spaces: arrayValueFromQueryString(query.spaces),
        name: query.name,
        includeSystem: query.includeSystem === "true"
    };
}

export function getQuery(filter: Filter): TasksQuery {
    return {
        serverNode: filter.node,
        state: filter.state,
        ids : filter.ids,
        environment: filter.environment,
        hasPendingInterruptions: filter.hasPendingInterruptions ? "true" : undefined,
        hasWarningsOrErrors: filter.hasWarningsOrErrors ? "true" : undefined,
        name: filter.name,
        project: filter.project,
        tenant: filter.tenant,
        ...(filter.spaces.length !== 0 ? {spaces: filter.spaces} : {}),
        ...(filter.includeSystem ? {includeSystem: "true"} : {})
    };
}

const TasksQueryStringFilters = QueryStringFilters.For<Filter, TasksQuery>();

export class Tasks extends DataBaseComponent<RouteComponentProps<any>, TasksState> {
    constructor(props: RouteComponentProps<any>) {
        super(props);
        this.state = {
            currentPageIndex: 0,
            filter: createEmptyFilter(),
            environments: [],
            nodes: [],
            projects: [],
            tenants: [],
            taskTypes: [],
            spaces: []
        };
    }

    componentDidMount() {
        return this.doBusyTask(async () => {
            const getProjects = hasPermission(Permission.ProjectView) ? repository.Projects.all() : Promise.resolve([]);
            const getEnvironments = hasPermission(Permission.EnvironmentView) ? repository.Environments.all() : Promise.resolve([]);
            const getNodes = repository.OctopusServerNodes.all();
            const getTenants = repository.Tenants.all();
            const getTaskTypes = repository.Tasks.taskTypes();
            const spaces = repository.Users.getSpaces(session.currentUser);

            this.setState({
                projects: await getProjects,
                environments: await getEnvironments,
                nodes: await getNodes,
                tenants: await getTenants,
                spaces: await spaces,
                taskTypes: await getTaskTypes
            });
            this.doRefresh = await this.startRefreshLoop(() => this.searchInternal(this.state.filter, this.state.currentPageIndex), 5000);
        });
    }

    search(filter: Filter) {
        this.setState({filter, hasLoadedOnce: false, currentPageIndex: 0}, async () => await this.doRefresh());
    }

    async searchInternal<K extends keyof Filter>(filter: Filter, currentPageIndex: number) {
        this.setState({currentPageIndex});

        const searchFilter = {
            states: getTaskStatesFromFilterState(filter.state),
            project: filter.project,
            environment: filter.environment,
            name: filter.name,
            node: filter.node,
            tenant: filter.tenant,
            spaces: getSpacesFilter(),
            skip: this.state.tasks ? currentPageIndex * this.state.tasks.ItemsPerPage : 0,
            ids: filter.ids && filter.ids.length ? filter.ids.join(",") : undefined,
            hasPendingInterruptions: filter.hasPendingInterruptions ? true : null,
            includeSystem: filter.includeSystem,
            hasWarningsOrErrors: filter.hasWarningsOrErrors ? true : null
        };
        const tasks = await repository.Tasks.list(searchFilter);

        return {
            tasks,
            hasLoadedOnce: true,
        };

        function getSpacesFilter() {
            const hasTaskViewInAnySpace = session.currentPermissions.hasPermissionInAnyScope(Permission.TaskView);

            if (filter.spaces.length === 0) {
                if (hasTaskViewInAnySpace) {
                    return ["all"];
                } else {
                    return [];
                }
            }

            return filter.spaces;
        }
    }

    clear() {
        return this.search(createEmptyFilter());
    }

    render() {
        const list = this.state.tasks &&
            <TaskResourceDataTable
                initialData={this.state.tasks}
                onRow={(item: any) => this.buildRow(item)}
                onRowRedirectUrl={(task: TaskResource<any>) => `tasks/${task.Id}`}
                rowColumnClassName={styles.taskDetailsCell}
                headerColumns={["", "State", "Start Time", "Completed Time", "Duration"]}
                onEmpty={this.handleOnEmpty}
                showPagingInNumberedStyle={true}
                currentPageIndex={this.state.currentPageIndex}
                onPageSelected={async (skip: number, p: number) => {
                    this.setState({hasLoadedOnce: false, currentPageIndex: p}, async () => await this.doRefresh());
                }}/>;

        const filterSections = [
            {
                render: <div>
                    <AdvancedFilterCheckbox
                        label="Awaiting manual intervention"
                        value={this.state.filter.hasPendingInterruptions}
                        onChange={hasPendingInterruptions => this.search({ ...this.state.filter, hasPendingInterruptions })}
                    />
                    {this.renderIncludeSystem()}
                    <AdvancedFilterCheckbox
                        label="Has warnings or errors"
                        value={this.state.filter.hasWarningsOrErrors}
                        onChange={hasWarningsOrErrors => this.search({ ...this.state.filter, hasWarningsOrErrors })}
                    />
                    {this.renderSpaceSelector()}
                    <Select value={this.state.filter.name}
                            onChange={(name: string) => this.search({...this.state.filter, name})}
                            items={this.state.taskTypes.map(t => ({value: t.Id, text: t.Name}))}
                            allowClear={true}
                            fieldName="task type"
                            hintText="All task types"
                            label="By task type"/>
                            <Select value={this.state.filter.node}
                                    onChange={node => this.search({...this.state.filter, node})}
                                    items={this.state.nodes.map(n => ({value: n.Id, text: n.Name}))}
                                    allowClear={true}
                                    label="By node"
                                    hintText="All nodes"/>
                    {this.renderSpaceSpecificSelectors()}
                </div>
            }
        ];

        const stateFilter = <div className={styles.states}>
                <Select value={this.state.filter.state}
                        onChange={(state: TaskFilterState) => this.search({...this.state.filter, state})}
                        items={Object.keys(TaskFilterState).map(t => ({value: t, text: t}))}
                        allowClear={true}
                        fieldName="task state"
                        hintText="All task states"
                />
        </div>;

        return <TaskLayout>
            <TasksQueryStringFilters
                filter={this.state.filter}
                onFilterChange={filter => this.search(filter)}
                getFilter={getFilter}
                getQuery={getQuery}
            />
            <PaperLayout title="Tasks"
                         busy={this.state.busy}
                         enableLessIntrusiveLoadingIndicator={this.state.hasLoadedOnce}
                         errors={this.state.errors}
                         sectionControl={<PermissionCheck permission={[Permission.AdministerSystem, Permission.TaskCreate]}  wildcard={true}>
                             <NavigationButton label="Script Console" href={routeLinks.tasks.console} type={NavigationButtonType.Primary}/>
                         </PermissionCheck>}
                         fullWidth={true}>
                {this.state.tasks && this.state.tasks.TotalCounts &&
                <StatisticsPanel totals={this.state.tasks.TotalCounts}
                                 setFilter={partialStatisticsFilter => {
                                     const filterWithPreservedSpacePartitions = {
                                         spaces: this.state.filter.spaces,
                                         includeSystem: this.state.filter.includeSystem
                                     };
                                     this.search({...filterWithPreservedSpacePartitions, ...partialStatisticsFilter});
                                 }}/>}
                <FilterLayout
                    filter={this.state.filter}
                    defaultFilter={createEmptyFilter()}
                    additionalHeaderFilters={[stateFilter]}
                    onFilterReset={resetFilter => {
                        this.search(resetFilter);
                    }}
                    filterSections={filterSections}
                    renderContent={() => list}
                />
            </PaperLayout>
        </TaskLayout>;
    }

    private renderSpaceSpecificSelectors = () => {
        // These are linked to access in the current space, because that's where the data will come from
        // we need to revisit how these will work going forward to make the filtering easier to do cross-space
        const isWithinASpace = client.spaceId;

        return isWithinASpace && [
            <PermissionCheck permission={Permission.ProjectView} wildcard={true}>
                <Select value={this.state.filter.project}
                        onChange={project => this.search({...this.state.filter, project})}
                        items={this.state.projects.map(p => ({value: p.Id, text: p.Name}))}
                        allowClear={true}
                        allowFilter={true}
                        fieldName="project"
                        hintText="All projects"
                />
            </PermissionCheck>,
            <PermissionCheck permission={Permission.EnvironmentView} wildcard={true}>
                <Select value={this.state.filter.environment}
                        onChange={environment => this.search({...this.state.filter, environment})}
                        items={this.state.environments.map(e => ({value: e.Id, text: e.Name}))}
                        allowClear={true}
                        allowFilter={true}
                        fieldName="environment"
                        hintText="All environments"
                />
            </PermissionCheck>,
            <FeatureToggle feature={Feature.MultiTenancy}>
                <PermissionCheck permission={Permission.TenantView} tenant="*">
                    <Select value={this.state.filter.tenant}
                        onChange={tenant => this.search({...this.state.filter, tenant})}
                        items={this.state.tenants.map(t => ({value: t.Id, text: t.Name}))}
                        allowClear={true}
                        allowFilter={true}
                        fieldName="tenant"
                        hintText="All tenants"
                        label="By tenant"/>
                </PermissionCheck>
            </FeatureToggle>
        ];
    }

    private renderIncludeSystem = () => {
        const hasSystemTaskView = session.currentPermissions.scopeToSystem().hasPermissionInAnyScope(Permission.TaskView);
        if (hasSystemTaskView) {
            return <AdvancedFilterCheckbox
                label="Include system tasks"
                value={this.state.filter.includeSystem}
                onChange={includeSystem => this.search({ ...this.state.filter, includeSystem })}
            />;
        }
        return null;
    }

    private renderSpaceSelector = () => {
        const hasTaskViewInAnySpace = session.currentPermissions.hasPermissionInAnyScope(Permission.TaskView);
        if (!hasTaskViewInAnySpace) {
            return <div style={{margin: "1rem 0 0 0"}}>
                <Callout type={CalloutType.Information} title={"Permission required"}>
                    You do not have {Permission.TaskView} permission in any given Space.
                </Callout>
            </div>;
        }
        return <SpaceMultiSelect
            items={this.state.spaces}
            label={"By space"}
            hintText={this.state.filter.spaces.length ? null : "All spaces"}
            onChange={(spaces: string[]) => this.search({...this.state.filter, spaces})}
            value={this.state.filter.spaces} />;
    }

    private doRefresh: Refresh = () => Promise.resolve();

    private handleOnEmpty = () => {
        return (
            <div>No tasks found</div>
        );
    }

    private buildRow = (task: TaskResource<any>) => {
        return [
            // mark.siedle: We want this InternalLink here so users have the option of standard
            // anchor-behaviour (ie. right click) that you don't get with the onClick from our SimpleDataTable component.
            <InternalLink to={(!!task.SpaceId ? routeLinks.forSpace(task.SpaceId) : routeLinks).task(task).root}><TaskDetails task={task}
                                                                       stripTopBottomPadding={true}/></InternalLink>,
            task.State,
            task.State === TaskState.Queued
                ? DateFormatter.dateToLongFormat(task.QueueTime) + " (" + moment(task.QueueTime).fromNow() + ")"
                : DateFormatter.dateToLongFormat(task.StartTime),
            DateFormatter.dateToLongFormat(task.CompletedTime),
            task.State === TaskState.Queued ? null : task.Duration,
        ];
    }
}

interface StatisticsPanelProps {
    totals: { [state: string]: number };
    setFilter(filter: Partial<Filter>): void;
}

const StatisticsPanel: React.StatelessComponent<StatisticsPanelProps> = props => {
    const addSIfNeeded = (length: number) => {
        return length > 1 ? "s" : "";
    };

    const stat = () => {
        function setFilter(e: React.MouseEvent<HTMLAnchorElement>, filter: Partial<Filter>) {
            e.preventDefault();
            props.setFilter(filter);
        }
        const result: JSX.Element[] = [];

        if (props.totals) {
            if (props.totals.Interrupted > 0) {
                result.push(<span key="interrupted">{props.totals.Interrupted} task{addSIfNeeded(props.totals.Interrupted)} <a
                    href="#" onClick={e => setFilter(e, {hasPendingInterruptions: true})}>awaiting intervention</a></span>);
            }
            if (props.totals.Executing > 0) {
                result.push(<span key="executing">{props.totals.Executing} task{addSIfNeeded(props.totals.Executing)} <a
                    href="#" onClick={e => setFilter(e, {state: TaskFilterState.Executing})}>running</a></span>);
            }
            if (props.totals.Cancelling > 0) {
                result.push(<span key="cancelling">{props.totals.Cancelling} task{addSIfNeeded(props.totals.Cancelling)} <a
                    href="#" onClick={e => setFilter(e, {state: TaskFilterState.Cancelling})}>cancelling</a></span>);
            }
            if (props.totals.Queued > 0) {
                result.push(<span key="queued">{props.totals.Queued} task{addSIfNeeded(props.totals.Queued)} <a
                    href="#" onClick={e => setFilter(e, {state: TaskFilterState.Queued})}>waiting in queue</a></span>);
            }
        }

        if (result.length === 0) {
            return "No active tasks";
        }

        if (result.length === 1) {
            return result;
        }

        const delimited = result.map((v, i) => {
            if (i === result.length - 1) {
                return v;
            }
            if (i === result.length - 2) {
                return [v, <span> and </span>];
            }
            return [v, <span>, </span>];
        });
        return delimited;
    };

    return <div className={styles.stats}>
        {stat()}
    </div>;
};

function createEmptyFilter(): Filter {
    const hasTaskViewInCurrentSpace = session.currentPermissions.scopeToSpace(client.spaceId).hasPermissionInAnyScope(Permission.TaskView);
    const shouldFilterToCurrentSpace = client.spaceId && hasTaskViewInCurrentSpace;
    return getFilter({
        spaces: shouldFilterToCurrentSpace ? [client.spaceId] : [],
        includeSystem: shouldFilterToCurrentSpace ? "" : "true"
    });
}

export default Tasks;
