exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_checkbox__1mWPn {\n  display: inline-block;\n  margin-right: 2rem;\n}\n.style_optionsBar__25fRC {\n  display: flex;\n  align-items: center;\n}\n.style_tableColumn__2Q3XV {\n  padding-bottom: 0.5rem !important;\n}\n.style_versionRadio__3x6p1 {\n  margin-top: -1rem;\n}\n.style_loadMore__3_khv {\n  display: flex;\n  justify-content: center;\n}\n.style_infoCircle__3T_JK {\n  color: #0878D1;\n  width: 1.5rem;\n  margin-top: 1rem;\n  display: flex;\n}\n.style_legend__18NY5 {\n  margin-top: 0.5rem;\n}\n.style_legend__18NY5 .style_infoIcon__2704J {\n  color: #0878D1;\n}\n", ""]);

// exports
exports.locals = {
	"checkbox": "style_checkbox__1mWPn",
	"optionsBar": "style_optionsBar__25fRC",
	"tableColumn": "style_tableColumn__2Q3XV",
	"versionRadio": "style_versionRadio__3x6p1",
	"loadMore": "style_loadMore__3_khv",
	"infoCircle": "style_infoCircle__3T_JK",
	"legend": "style_legend__18NY5",
	"infoIcon": "style_infoIcon__2704J"
};