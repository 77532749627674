import * as React from "react";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import BusyIndicator from "components/BusyIndicator";
import * as cn from "classnames";
import { gettingStartedLoader, GettingStartedStatus } from "components/GettingStarted/gettingStartedLoader";
import { ProjectResource, ReleaseResource } from "client/resources";
import ExternalLink from "components/Navigation/ExternalLink";
const styles = require("./GettingStartedDetails.less");
import routeLinks from "../../routeLinks";
import { RouteComponentProps, withRouter } from "react-router";
import {
    Step,
    Stepper,
    StepContent,
    StepButton,
} from "material-ui/Stepper";
import { SectionStepLink } from "./SectionStepLink";
import CheckCircleIcon from "material-ui/svg-icons/action/check-circle";
import DoneIcon from "material-ui/svg-icons/action/done";
import AvPlayCircleFilled from "material-ui/svg-icons/av/play-circle-filled";
import { Note } from "components/form";
import { success } from "theme/colors";
import Callout, { CalloutType } from "components/Callout";

enum GettingStartedSectionArea {
    Infrastructure = "Infrastructure",
    Package = "Package",
    Project = "Project",
    Deploy = "Deploy",
}

interface GettingStartedSection {
    index: number;
    title: string;
    area: GettingStartedSectionArea;
    complete: boolean;
    path: string;
    help?: React.ReactNode;
    actions: Array<ActionLink | ActionLink[]>;
}

interface IActionLink {
    path?: string;
    text: string;
    note?: React.ReactNode;
    complete: boolean;
    enabled: boolean;
    items?: DropDownItem[];
}

interface DropDownItem {
    label: string;
    link: string;
}

class ActionLink implements IActionLink {
    public path?: string;
    public text: string;
    public note?: React.ReactNode;
    public complete: boolean;
    public enabled: boolean;
    public items?: DropDownItem[];

    constructor(item: IActionLink) {
        this.path = item.path;
        this.text = item.text;
        this.complete = item.complete;
        this.enabled = item.enabled;
        this.items = item.items;
    }
}

interface GettingStartedDetailsState {
    next: string;
    projects: ProjectResource[];
    releases: ReleaseResource[];
    sections: {
        readonly: boolean;
        items: GettingStartedSection[];
    };
    stepperIndex: number;
}

interface GettingStartedDetailsComponentProps {
    highlightSection?: string;
    onlyShowCurrentSection?: boolean;
    showIntroHeading?: boolean;
    onNavigating?(): void;
    showGettingStartedDialog?(): void;
}

type GettingStartedDetailsProps = GettingStartedDetailsComponentProps & RouteComponentProps<{ spaceId: string }>;

class GettingStartedDetailsInternal extends BaseComponent<GettingStartedDetailsProps, GettingStartedDetailsState> {
    unsubscribe: () => void;

    constructor(props: GettingStartedDetailsProps) {
        super(props);
        this.state = {
            next: "",
            projects: [],
            releases: [],
            sections: null,
            stepperIndex: null,
        };
    }

    async componentDidMount() {
        const tasksStatus = await gettingStartedLoader.loadStatus();
        this.refresh(tasksStatus);
        if (!tasksStatus.tasks.Deploy.DeployedRelease.IsComplete) {
            this.unsubscribe = gettingStartedLoader.subscribe(status => this.refresh(status));
        }
    }

    componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }

    render() {
        let firstIncompleteIndex: number = 0;
        let firstIncompleteSection: GettingStartedSection = null;
        let sectionItems: GettingStartedSection[] = null;
        if (this.state.sections) {
            if (this.props.onlyShowCurrentSection) {
                firstIncompleteIndex = 0;
                firstIncompleteSection = this.state.sections.items && this.state.sections.items.filter(x => !x.complete)[0];
                sectionItems = [firstIncompleteSection];
            } else {
                sectionItems = this.state.sections.items;
                if (this.state.stepperIndex !== null) { // Specifically checking for null on purpose ... don't want 0 to be considered :)
                    firstIncompleteIndex = this.state.stepperIndex;
                    firstIncompleteSection = this.state.sections.items[this.state.stepperIndex];
                } else {
                    this.state.sections.items.forEach((section, sectionIndex) => {
                        if (!section.complete && !firstIncompleteSection) {
                            firstIncompleteIndex = sectionIndex;
                            firstIncompleteSection = section;
                        }
                    });
                }
            }
        }

        const showAdditionalInfoForCurrentSection = !this.props.onlyShowCurrentSection;

        return <div className={cn(styles.container, !this.state.sections && styles.liftBusyIndicator)}>
            {!this.state.sections && <BusyIndicator show={true} inline={false} />}
            {this.state.sections && this.state.sections.readonly && <div className={cn(styles.container, styles.header)}>
                <span className={styles.title}>Getting Started Overview</span>
                <Callout type={CalloutType.Success} title={"You've completed the steps to successfully deploy your software."} />
            </div>}
            {this.state.sections && !this.state.sections.readonly && this.props.showIntroHeading &&
                <div className={cn(styles.container, styles.header)}>
                    <h2>Let's deploy your first software application</h2>
                </div>}

            <div className={styles.gettingStartedSection}>
                <div className={styles.stepperContainer}>
                    <Stepper
                        activeStep={this.state.stepperIndex}
                        linear={false}
                        orientation="vertical"
                    >
                        {this.state.sections && sectionItems.map((section, sectionIndex) => {
                            const originalSectionIndex = this.state.sections.items.findIndex(x => x === section);
                            const isDisabled = false;
                            const isActive = firstIncompleteIndex === sectionIndex;
                            return <Step key={sectionIndex}
                                completed={section.complete}
                                disabled={isDisabled}
                                active={isActive}
                            >
                                <StepButton onClick={() => {
                                    if (this.props.onlyShowCurrentSection) {
                                        if (this.props.showGettingStartedDialog) {
                                            this.props.showGettingStartedDialog();
                                        }
                                    } else {
                                        this.setState({ stepperIndex: sectionIndex });
                                    }
                                }}
                                    className={isActive
                                        ? styles.stepButtonActive
                                        : (section.complete
                                            ? cn(styles.stepButtonInActive, styles.stepButtonIconComplete)
                                            : styles.stepButtonInActive)
                                        }
                                    icon={section.complete ? <CheckCircleIcon /> : <svg viewBox="0 0 24 24" className={styles.stepButtonCustomCircleIndex}
                                    >
                                        <circle cx="12" cy="12" r="10" />
                                        <text x="12" y="16" textAnchor="middle" fontSize="12" fill="#fff">{originalSectionIndex + 1}</text>
                                    </svg>}
                                >
                                    {section.title}
                                </StepButton>
                                <StepContent>
                                    {isActive && <div className={styles.stepContent}>
                                        {section.actions.map((action, actionIndex) => {
                                            if (action instanceof ActionLink) {
                                                return <div className={styles.actionLinkRow} key={actionIndex}>
                                                    {action.complete ? <DoneIcon /> : <AvPlayCircleFilled color={success} className={styles.stepperIcon} />}
                                                    <div className={styles.actionLinkRowContent}>
                                                        {!action.enabled && <span>{action.text}</span>}
                                                        {action.enabled && <SectionStepLink weight={action.complete ? "normal" : "bold"} onNavigating={this.onNavigating}
                                                            text={action.text} path={action.path}
                                                            items={action.items && action.items.slice(0, 5)} />}
                                                    </div>
                                                </div>;
                                            }
                                            if (action instanceof Array) {
                                                const isConsideredComplete = action.filter(x => x.complete).length > 0;
                                                return <div className={styles.actionLinkRow} key={actionIndex}>
                                                    {isConsideredComplete ? <DoneIcon /> : <AvPlayCircleFilled color={success} className={styles.stepperIcon} />}
                                                    <div className={styles.actionLinkRowContent}>
                                                        {action.map((orAction, orActionIndex) => {
                                                            return <span key={orActionIndex}>
                                                                {!orAction.enabled && <span>{orAction.text}</span>}
                                                                {orAction.enabled && <SectionStepLink weight={orAction.complete ? "normal" : "bold"} onNavigating={this.onNavigating}
                                                                    text={orAction.text} path={orAction.path}
                                                                    items={orAction.items && orAction.items.slice(0, 5)} />}
                                                                {orActionIndex !== (action.length - 1) && <span> or </span>}
                                                            </span>;
                                                        })}
                                                    </div>
                                                </div>;
                                            }
                                        })}
                                        {showAdditionalInfoForCurrentSection && <React.Fragment>
                                            {section.help && <Note style={{ marginTop: "1rem" }}>{section.help}</Note>}
                                        </React.Fragment>}
                                    </div>}
                                </StepContent>
                            </Step>;
                        })}
                    </Stepper>
                </div>
                {showAdditionalInfoForCurrentSection && <div className={styles.footer}>
                    <Note>See our <ExternalLink href="GettingStarted">Getting Started guide</ExternalLink>.</Note>
                </div>}
            </div>
        </div>;
    }

    private onNavigating = () => {
        if (this.props.onNavigating) {
            this.props.onNavigating();
        }
    }

    private refresh(status: GettingStartedStatus) {
        const deployRelease = (() => {
            const project = status.projects.find(p =>
                p.Id === status.releases[0].ProjectId
            );
            return routeLinks.project(project).root;
        }).bind(this);

        const deployMultipleRelease = (() => {
            let projectIds: string[] = [];
            projectIds = status.releases.reduce((state, r) => {
                if (!state.includes(r.ProjectId)) {
                    state.push(r.ProjectId);
                }
                return state;
            }, projectIds);
            return status.projects
                .filter(p => projectIds.includes(p.Id))
                .map(p => (
                    {
                        label: p.Name,
                        link: routeLinks.project(p).root
                    }
                ));
        }).bind(this);

        const sectionsList: GettingStartedSection[] = [
            {
                index: 1,
                title: "Tell Octopus where to deploy your software",
                area: GettingStartedSectionArea.Infrastructure,
                complete: status.tasks.Infrastructure.IsComplete,
                path: routeLinks.infrastructure.root,
                help: <span>
                    With Octopus you can deploy your software anywhere you want, with built-in support for the most popular hosting environments. <ExternalLink href="OnboardingInfrastructureLearnMore">Learn more</ExternalLink>
                </span>,
                actions: [
                    new ActionLink({
                        text: "Create your first environment",
                        path: routeLinks.infrastructure.environments.root,
                        complete: status.tasks.Infrastructure.CreatedEnvironment.IsComplete,
                        enabled: status.tasks.Infrastructure.CreatedEnvironment.IsEnabled,
                    }),
                    [
                        new ActionLink({
                            text: "Create your first deployment target",
                            path: routeLinks.infrastructure.machines.root,
                            complete: status.tasks.Infrastructure.CreatedMachine.IsComplete,
                            enabled: status.tasks.Infrastructure.CreatedMachine.IsEnabled,
                        })
                    ]
                ],
            },
            {
                index: 2,
                title: "Package and upload your software",
                area: GettingStartedSectionArea.Package,
                complete: status.tasks.Package.IsComplete,
                path: routeLinks.library.builtInRepository.root,
                help: <span>
                    Your packaged software can be uploaded manually, automatically or via an external feed. <ExternalLink href="DocumentationPackaging">Learn more</ExternalLink>
                </span>,
                actions: [
                    [
                        new ActionLink({
                            text: "Upload your package",
                            path: routeLinks.library.builtInRepository.root,
                            complete: status.tasks.Package.UploadedPackage.IsComplete,
                            enabled: status.tasks.Package.UploadedPackage.IsEnabled,
                        }),
                        new ActionLink({
                            text: "Add an external feed",
                            path: routeLinks.library.feeds,
                            complete: status.tasks.Package.AddedFeed.IsComplete,
                            enabled: status.tasks.Package.AddedFeed.IsEnabled,
                        }),
                    ]
                ],
            },
            {
                index: 3,
                title: "Define your deployment process",
                area: GettingStartedSectionArea.Project,
                complete: status.tasks.Project.IsComplete,
                path: status.projects.length === 1 ? routeLinks.project(status.projects[0]).process.root : null,
                help: <span>
                    Your projects store the packages, scripts, deployment steps and configuration variables for your deployment. <ExternalLink href="OnboardingDeploymentProcessLearnMore">Learn more</ExternalLink>
                </span>,
                actions: [
                    new ActionLink({
                        text: "Create your first project",
                        path: routeLinks.projects.root,
                        complete: status.tasks.Project.CreatedProject.IsComplete,
                        enabled: status.tasks.Project.CreatedProject.IsEnabled,
                    }),
                    new ActionLink({
                        text: "Define its deployment process",
                        path: status.projects.length === 1 ? routeLinks.project(status.projects[0]).process.root : null,
                        complete: status.tasks.Project.AddedProjectStep.IsComplete,
                        enabled: status.tasks.Project.AddedProjectStep.IsEnabled,
                        items: status.projects.length > 1 ? status.projects.map(p => (
                            {
                                label: p.Name,
                                link: routeLinks.project(p).process.root
                            }
                        )) : null
                    })
                ],
            },
            {
                index: 4,
                title: "Deploy your release",
                area: GettingStartedSectionArea.Deploy,
                complete: status.tasks.Deploy.IsComplete,
                path: status.releases.length === 1 ? deployRelease() : null,
                help: <span>
                    Releases capture everything required to deploy a project. A release cannot be created until steps 1-3 are complete. <ExternalLink href="OnboardingReleasesLearnMore">Learn more</ExternalLink>
                </span>,
                actions: [
                    new ActionLink({
                        text: "Create a release",
                        path: status.projects.length === 1 ? routeLinks.project(status.projects[0]).releases : null,
                        complete: status.tasks.Deploy.CreatedRelease.IsComplete,
                        enabled: status.tasks.Deploy.CreatedRelease.IsEnabled,
                        items: status.projects.length > 1 ? status.projects.map(p => (
                            {
                                label: p.Name,
                                link: routeLinks.project(p).releases
                            }
                        )) : null
                    }),
                    new ActionLink({
                        text: "Deploy a release",
                        path: status.releases.length === 1 ? deployRelease() : null,
                        complete: status.tasks.Deploy.DeployedRelease.IsComplete,
                        enabled: status.tasks.Deploy.DeployedRelease.IsEnabled,
                        items: status.releases.length > 1 ? deployMultipleRelease() : null
                    })
                ],
            }
        ];

        this.setState({
            sections: {
                readonly: status.tasks.Deploy.DeployedRelease.IsComplete,
                items: sectionsList
            },
            next: status.next,
            projects: status.projects,
            releases: status.releases
        });
    }
}

export const GettingStartedDetails = withRouter<GettingStartedDetailsProps>(GettingStartedDetailsInternal);