import * as React from "react";
import { repository, session } from "clientInstance";
import { TaskResource, ResourceCollection, SpaceResource } from "client/resources";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { List } from "../../../../components/List/List";
import TaskDetails from "../../../../components/TaskDetails/TaskDetails";
import routeLinks from "../../../../routeLinks";
import { CalloutType, Callout } from "../../../../components/Callout/Callout";
import ExternalLink from "components/Navigation/ExternalLink";
import InternalLink from "components/Navigation/InternalLink";

interface TaskQueueProps {
    task: TaskResource<any>;
}

interface TaskQueueState {
    behind?: ResourceCollection<TaskResource<any>>;
    skipBehind: number;
    space?: SpaceResource;
}

class BehindTasksList extends List<TaskResource<any>> {
}

export default class TaskQueue extends DataBaseComponent<TaskQueueProps, TaskQueueState & DataBaseComponentState> {
    constructor(props: TaskQueueProps) {
        super(props);
        this.state = {
            behind: null,
            skipBehind: 0
        };
    }
    componentDidMount() {
        this.doBusyTask(() => this.startRefreshLoop(this.refresh, 5000));
    }

    refresh = async () => {
        if (this.props.task.HasBeenPickedUpByProcessor) {
            return {};
        }

        const behind = repository.Tasks.getQueuedBehind(this.props.task, { skip: this.state.skipBehind });
        const space = repository.Spaces.get(this.props.task.SpaceId);

        return {
            behind: await behind,
            space: await space
        };
    }

    render() {
        if (this.props.task.HasBeenPickedUpByProcessor) {
            return null;
        }

        if (this.props.task.HasPendingInterruptions) {
            return null;
        }

        if (!this.state.behind) {
            return <Callout type={CalloutType.Information}title={"Task has not yet started"} />;
        }

        const pendingTasks = this.state.behind.TotalResults;

        if (this.state.space && this.state.space.TaskQueueStopped) {
            return <Callout type={CalloutType.Information} title={"Task has not yet started"}>This task has not started as the task processor for Space <strong>{this.state.space.Name}</strong> has been stopped. {this.contactSpaceManagerMessage()}
                <ExternalLink href="SpacesTaskQueue"> Learn More</ExternalLink>
            </Callout>;
        }

        if (pendingTasks <= 0) {
            return <Callout type={CalloutType.Information} title={"Task has not yet started"}>This task has not started yet, but is next in the queue and will be executed as soon as possible.</Callout>;
        }

        return <div>
            <Callout type={CalloutType.Information} title={"Task has not yet started"}>
                This task has not started yet, and is queued behind {pendingTasks > 1 ? `these ${pendingTasks} other tasks` : " another task"}
            </Callout>
            <BehindTasksList initialData={this.state.behind}
                onRow={(thing: TaskResource<any>) => <TaskDetails task={thing} />}
                onRowRedirectUrl={(t: TaskResource<any>) => routeLinks.forSpace(t.SpaceId).task(t).root}
                showPagingInNumberedStyle={true} />
        </div>;
    }

    private contactSpaceManagerMessage(): JSX.Element {
        return this.isSpaceManager()
            ? <><InternalLink to={routeLinks.configuration.space(this.state.space.Id)}> Re-enable the task processor </InternalLink> to continue executing tasks.</>
            : <>Re-enable the task processor to continue executing tasks. Please contact your Space Manager for more information.</>;
    }

    private isSpaceManager(): boolean {
        return session.currentPermissions.isSpaceManager(this.state.space);
    }
}