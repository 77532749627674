import * as React from "react";
import MuiPopover from "@material-ui/core/Popover";
import { noOp } from "utils/noOp";

type Horizontal = "left" | "center" | "right";
type Vertical = "top" | "center" | "bottom";

interface Origin {
    horizontal: Horizontal;
    vertical: Vertical;
}

interface PopoverProps {
    anchorEl?: HTMLElement;
    anchorOrigin?: Origin;
    className?: string;
    onClose?: () => void;
    open: boolean;
    style?: React.CSSProperties;
    transformOrigin?: Origin;
    disableAutoFocus?: boolean;
    getUpdatePosition?: (callback: () => void) => void;
}

export default class Popover extends React.Component<PopoverProps, any> {
    render() {
        return (
            <MuiPopover
                action={actions => this.props.getUpdatePosition ? this.props.getUpdatePosition(actions.updatePosition) : noOp()}
                anchorEl={this.props.anchorEl}
                anchorOrigin={this.props.anchorOrigin}
                className={this.props.className}
                onClose={this.props.onClose}
                open={this.props.open}
                style={this.props.style}
                transformOrigin={this.props.transformOrigin}
                disableAutoFocus={this.props.disableAutoFocus}
                >
                    {this.props.children}
            </MuiPopover>
        );
    }
}