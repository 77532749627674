exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_container__pj8Kr {\n  min-height: 62.5rem;\n}\n.style_filterSearchBoxContainer__2em71 {\n  width: 320px;\n}\n.style_sectionDivider__LVFRy {\n  padding: 0.5rem 1.125rem;\n  margin-right: 1rem;\n  font-size: 1.0625rem;\n  border-radius: 0.25rem;\n}\n", ""]);

// exports
exports.locals = {
	"container": "style_container__pj8Kr",
	"filterSearchBoxContainer": "style_filterSearchBoxContainer__2em71",
	"sectionDivider": "style_sectionDivider__LVFRy"
};